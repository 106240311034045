import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as smoothscroll from 'smoothscroll-polyfill';

import * as ReactDOM from 'react-dom';
import App from './App';

import 'normalize.css';

smoothscroll.polyfill();

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
