import { Token } from 'entities/token';

export const getHeaders = async () => ({
  'Content-Type': 'application/json',
  'Access-Control-Allow-Credentials': true
});

export const getPurchaseHeaders = () => {
  const purchaseToken = Token.get('purchase');
  return { Authenticate: `Token ${purchaseToken}` };
};
