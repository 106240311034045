import {
  call,
  put,
  takeEvery
} from 'redux-saga/effects';

import { api } from 'api';
import { actionTypes } from 'store/actionTypes';
import { IRecommendedProduct } from 'interfaces';
import { Url } from 'api/url';

function* fetchRecommendedProducts() {
  try {
    const host = document.location.hostname
      === 'devonline.veles-management.ru' || document.location.hostname.indexOf('localhost') !== -1
      ? 'https://devlk.veles-management.ru/api/'
      : 'https://lk.veles-management.ru/api/';
    const params = {
      host,
      url: Url.recommendedProducts.get
    };
    const recommendedProducts: IRecommendedProduct[] = yield call(
      api.get,
      params
    );
    yield put({
      type: actionTypes.RECOMMENDED_PRODUCTS.GET.SUCCESS,
      payload: recommendedProducts
    });
  } catch (e) {
    yield put({
      type: actionTypes.RECOMMENDED_PRODUCTS.GET.ERROR,
      message: e.message
    });
  }
}
export function* getRecommendedProducts() {
  yield takeEvery(
    actionTypes.RECOMMENDED_PRODUCTS.GET.REQUEST,
    fetchRecommendedProducts
  );
}
