import { ScrollContainer } from '../scrollContainer';
import { ICustomScrollBoxProps } from './interfaces';

import S from './styles';

/* компонент используется, когда необходимо большой текст
 завернуть в контейенр с кастомным скроллом */
export const CustomScrollBox = ({ children }: ICustomScrollBoxProps) => (
  <S.Wrapper>
    <ScrollContainer renderView={() => <S.WrapperContent />}>
      {children}
    </ScrollContainer>
  </S.Wrapper>
)
