import { apiUrl } from './hosts';

export const env: string | undefined = process.env.NODE_ENV;

export const host: string = env === 'development' ? apiUrl.dev : apiUrl.production;

export const Url = {
  recommendedProducts: {
    get: 'product/get-product-list/online'
  },
  opifYield: {
    get: 'yield'
  },
  checkClient: 'purchase/check-rebuy',
  purchase: {
    sms: {
      sendCode: 'mfms-gateway/send-code',
      checkCode: 'mfms-gateway/check-code'
    },
    checkTerrorizm: 'cro-system/check-client',
    edo: {
      check: 'api-sales/edo/check',
      accept: 'api-sales/edo/accept'
    },
    files: 'purchase/step-two',
    passportSendData: 'trust-gate/send-data', // ЕСИА УПРИД
    passportCheckStatus: 'trust-gate/check-result', // ЕСИА УПРИД
    checkSmsEdo: 'mfms-gateway/check-code/edo',
    acquiring:
      'https://mdm-webapi-mdmpay-financial-staging.mdmbank.ru/web/v1/payment?request=',
    userDocs: { get: 'api-sales/templates/registration' },
    userRegistration: 'api-sales/registration-submit'
  },
  rebuy: 'rebuy'
};
