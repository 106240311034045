import {
  useState,
  useEffect,
  useRef
} from 'react';

import { IInputProps } from 'interfaces';

import { TextField } from '../textField';

import S from '../styles';

export const TextArea = ({
  placeholder,
  name,
  type,
  value,
  description,
  disabled,
  error,
  autoComplete,
  hint,
  likeTextField,
  hidden,
  styles,
  onBlur,
  onFocus,
  onChange,
  onKeyDown
}: IInputProps) => {
  const [hasError, setHasError] = useState(false);
  /* todo useEffect без зависимостей */
  useEffect(() => {
    if (error) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  });
  const id = `input-${placeholder}`;
  const descriptionText = description && (
    <S.Description data-test="input-description" style={styles}>
      {description}
    </S.Description>
  );

  const inputProps = {
    id,
    type,
    name,
    onKeyDown,
    autoComplete,
    disabled: !!(likeTextField || disabled),
    value: value || '',
    onChange: onChange || mockHandler,
    onBlur: onBlur || mockHandler,
    onFocus: onFocus || mockHandler
  };

  function mockHandler() {
    return undefined;
  }

  useEffect(() => {
    if (textareaRef && textareaRef.current && disabled) {
      resize(1, textareaRef.current);
    }
  }, [value]);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  return (
    <>
      {!likeTextField
        ? (
          <S.Input hasError={hasError} hidden={hidden} style={styles}>
            <S.TextArea
              {...inputProps}
              data-value={value || ''}
              ref={textareaRef}
            />
            <label htmlFor={id}>{placeholder}</label>
            <S.Info>
              {String(error).trim() ? null : descriptionText}
              <S.Error hasError={!!error}>{error}</S.Error>
              <S.Hint>{hint}</S.Hint>
            </S.Info>
          </S.Input>)
        : (
          <TextField
            value={value}
            placeholder={placeholder}
            styles={styles}
          />)}
    </>
  );
};

function getHeight(rows: number, el: Element) {
  const {
    borderBottomWidth,
    borderTopWidth,
    lineHeight,
    paddingBottom,
    paddingTop
  } = window.getComputedStyle(el);

  const rowHeight = rows == null
    ? 0
    : parseFloat(lineHeight || '') * rows
        + parseFloat(borderBottomWidth || '')
        + parseFloat(borderTopWidth || '')
        + parseFloat(paddingBottom || '')
        + parseFloat(paddingTop || '');

  const scrollHeight = el.scrollHeight
    + parseFloat(borderBottomWidth || '')
    + parseFloat(borderTopWidth || '');
  return Math.max(rowHeight, scrollHeight);
}

function resize(rows: number, el: HTMLTextAreaElement) {
  if (el) {
    el.style.height = '0';
    el.style.height = `${getHeight(rows, el)}px`;
  }
}
