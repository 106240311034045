import size from 'lodash/size';

import { WizardForms } from 'components/widgets';

export function isLastForm(
  forms: WizardForms,
  step: number,
  form: number
) {
  const countSteps = size(forms);
  if (countSteps) {
    const countForms = size(forms[countSteps - 1]);
    if (step + 1 === countSteps && form + 1 === countForms) {
      return true;
    }
  }
  return false;
}
