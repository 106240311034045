import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { IBeforeNext } from 'interfaces';
import { actionCreator } from 'helpers/actions/actionCreator';
import { actionTypes } from 'store/actionTypes';
import { useStore } from '../../../hooks/useStore';

import { UserError } from '../error/userError';
import { LoaderTooltip } from '../../tooltips/loader';
import { blankLoaderDescription } from '../passportCheck';
/**
 * Компонент, который выполняет проверку пользователя на терроризм
 */

export const Terrorizm = ({ isShow, onSubmit }: IBeforeNext) => {
  const dispatch = useDispatch()
  const { purchase: { terroristStatus } } = useStore()
  useEffect(() => {
    if (isShow) dispatch(actionCreator(actionTypes.PURCHASE.CHECK_TERRORIZM.REQUEST));
  }, [isShow]);
  useEffect(() => {
    if (terroristStatus && onSubmit) onSubmit();
  }, [terroristStatus]);

  return (
    <>
      {terroristStatus === false
        ? <UserError />
        : <LoaderTooltip isShow={isShow} description={blankLoaderDescription} />}
    </>
  ); // при инициализации стейта статус - null
};
