import { useRef, useState } from 'react';

import useOnClickOutside from 'use-onclickoutside';

// eslint-disable-next-line import/no-cycle
import { Popup } from '../popup';
import { IHintProps } from './interfaces';

export const Hint = ({
  wrapperClassName,
  title,
  content
}: IHintProps) => {
  const [isShow, setIsShow] = useState(false);
  const hintRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(hintRef, () => setIsShow(false));

  return (
    <div
      onMouseEnter={() => setIsShow(true)}
      onMouseLeave={() => setIsShow(false)}
      className={wrapperClassName}
      ref={hintRef}
    >
      {title}
      {isShow && <Popup>{content}</Popup>}
    </div>
  );
};
