import { useRef, useState, useEffect } from 'react';

import size from 'lodash/size';
import map from 'lodash/map';

import { getWidth } from 'helpers/refs';

import { IStepProps } from './interfaces';

import S from './styles';

export const Steps = ({ steps, currentStep }: IStepProps) => {
  const [stepWidth, setStepWidth] = useState(0);
  const stepRef = useRef<HTMLDivElement>(null);

  window.addEventListener('resize', () => setStepWidth(getWidth(stepRef)));

  useEffect(() => {
    setStepWidth(getWidth(stepRef));
  }, [stepRef]);
  const last = size(steps) - 1 === currentStep;
  return (
    <S.StepsWrapper currentStep={currentStep} stepWidth={stepWidth} last={last}>
      {map(steps, (step, index) => (
        <S.Step key={step} currentStep={currentStep} index={index} ref={stepRef}>
          <S.Step.Title success={index < currentStep}>Шаг {index + 1}</S.Step.Title>
          <S.Step.Description>{step}</S.Step.Description>
        </S.Step>
      ))}
      <S.Step.TrackLine step={currentStep} stepWidth={stepWidth} last={last} />
    </S.StepsWrapper>
  );
};
