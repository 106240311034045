import {
  useReducer,
  useEffect,
  useRef,
} from 'react';

import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { useDispatch } from 'react-redux';

import { IWizardForm } from 'interfaces/';
import { actionTypes } from 'store/actionTypes';
import { Styles } from 'components/shared';
import { staticContent } from 'staticContent';
import { scrollIntoRef } from 'helpers/refs';
import { isLastForm, getBlankUrl } from 'helpers/form';

import { Steps } from './step';
import { Form } from '../form';
import { RecommendedProducts } from '../recommendedProducts';
import { useNotRespond } from '../../hooks/useNotRespond';
// eslint-disable-next-line import/no-cycle
import { multiStepReducer } from './reducer/reducer';
import { initialState } from './reducer/interfaces';
// eslint-disable-next-line import/no-cycle
import { Props } from './interfaces';

import S from './styles';

type WizardStep = IWizardForm[];
export type WizardForms = WizardStep[];

export const MultiStepWizard = ({
  title,
  titletype,
  forms,
  goto,
  steps
}: Props) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { resetNotRespond } = useNotRespond()
  const dispatch = useDispatch()

  const [state, dispatchState] = useReducer(multiStepReducer, {
    ...initialState,
    forms
  });
  const {
    step,
    form,
    isInitialValid
  } = state;
  const { productId, productType } = useParams();
  const lastForm = isLastForm(forms, step, form);

  useEffect(() => {
    const params = { form, step };
    updateUrl(params);
  }, [state]);

  useEffect(() => {
    if (goto) {
      dispatchState({
        type: 'GOTO',
        payload: goto
      });
    }
  }, [goto]);
  window.onpopstate = () => {
    dispatchState({
      type: 'BACK'
    });
    resetNotRespond();
  }
  useEffect(() => () => {
    dispatch({ type: actionTypes.PURCHASE.RESET_STATE })
  }, [])

  const updateUrl = (nextParams: { step: number; form: number }) => {
    const url = getBlankUrl(nextParams, productType, productId);

    if (pathname !== url) navigate(url);
  };

  const handleSubmit = () => {
    // если форма была последней, то перекидываем на лк
    if (lastForm) {
      window.location.replace(staticContent.lk);
    } else {
      // кидаем экшн для обновления номера шага и номера формы
      dispatchState({
        type: 'NEXT',
        payload: {
          ...state,
          isInitialValid: false
        }
      });
      scrollIntoRef(titleRef);
    }
  };

  const formProps = forms[step][form];
  const { className, title: formTitle } = formProps;

  return (
    <>
      <Styles.H1 ref={titleRef}>
        <span>{titletype}</span>
        <span>{title}</span>
      </Styles.H1>
      <S.MultiStepWizard
        data-test="multi-step-wizard-component"
        className={className}
      >
        <Steps steps={steps} currentStep={step} />
        <Styles.Panel>
          <S.FormTitle>{formTitle}</S.FormTitle>
          <Form
            {...formProps}
            onSubmit={handleSubmit}
            isInitialValid={isInitialValid}
            isLastForm={lastForm}
          />
        </Styles.Panel>
      </S.MultiStepWizard>
      {lastForm && (
        <RecommendedProducts
          exclude={productId}
          title={
            <S.ReccommendedProductsTitle>
              Рекомендуем обратить внимание на другие продукты
            </S.ReccommendedProductsTitle>
          }
        />
      )}
    </>
  );
};
