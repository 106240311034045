import axios from 'axios';
import pickBy from 'lodash/pickBy';

import { IAddress, IRequisites } from 'interfaces';

// eslint-disable-next-line import/no-cycle
import { IDadataRequest, IDadataResponse } from './interfaces';

const addressGranulars = {
  region: 'region',
  area: 'area',
  city: 'city',
  settlement: 'settlement',
  street: 'street',
  house: 'house',
  district: 'city_district',
  flat: 'flat'
};
const fioGranulars = {
  surname: 'SURNAME',
  name: 'NAME',
  patronymic: 'PATRONYMIC'
};

export const granulars = {
  address: addressGranulars,
  fio: fioGranulars
};

export const types = {
  fio: 'fio',
  email: 'email',
  address: 'address',
  fms_unit: 'fms_unit',
  bank: 'bank'
};

export interface ISuggestion {
  value: string;
  data: {
    [key: string]: any;
  };
  unrestricted_value?: string;
}
/* todo сделать типизацию */
// interface IFirstStep {
//   gender: string;
//   name: null | string;
//   patronymic: null | string;
//   qc: string | null;
//   source: null | string;
//   surname: string;
//   domain?: null | string;
//   local?: null | string;
//   type: null | string;
// }

// interface IBirthPlaceAndPassportAndRegistration {
//   [key: string]: string | null;
// area: null
// area_fias_id: null
// area_kladr_id: null
// area_type: null
// area_type_full: null
// area_with_type: null
// beltway_distance: null
// beltway_hit: null
// block: null
// block_type: null
// block_type_full: null
// capital_marker: '2'
// city: 'Архангельск'
// city_area: null
// city_district: null
// city_district_fias_id: null
// city_district_kladr_id: null
// city_district_type: null
// city_district_type_full: null
// city_district_with_type: null
// city_fias_id: '06814fb6-0dc3-4bec-ba20-11f894a0faf5'
// city_kladr_id: '2900000100000'
// city_type: 'г'
// city_type_full: 'город'
// city_with_type: 'г Архангельск'
// country: 'Россия'
// country_iso_code: 'RU'
// divisions: null
// entrance: null
// federal_district: null
// fias_actuality_state: '0'
// fias_code: null
// fias_id: '06814fb6-0dc3-4bec-ba20-11f894a0faf5'
// fias_level: '4'
// flat: null
// flat_area: null
// flat_cadnum: null
// flat_fias_id: null
// flat_price: null
// flat_type: null
// flat_type_full: null
// floor: null
// geo_lat: '64.539393'
// geo_lon: '40.516939'
// geoname_id: '581049'
// history_values: null
// house: null
// house_cadnum: null
// house_fias_id: null
// house_kladr_id: null
// house_type: null
// house_type_full: null
// kladr_id: '2900000100000'
// metro: null
// okato: '11401000000'
// oktmo: '11701000001'
// postal_box: null
// postal_code: '163000'
// qc: null
// qc_complete: null
// qc_geo: '4'
// qc_house: null
// region: 'Архангельская'
// region_fias_id: '294277aa-e25d-428c-95ad-46719c4ddb44'
// region_iso_code: 'RU-ARK'
// region_kladr_id: '2900000000000'
// region_type: 'обл'
// region_type_full: 'область'
// region_with_type: 'Архангельская обл'
// settlement: null
// settlement_fias_id: null
// settlement_kladr_id: null
// settlement_type: null
// settlement_type_full: null
// settlement_with_type: null
// source: null
// square_meter_price: null
// stead: null
// stead_cadnum: null
// stead_fias_id: null
// stead_type: null
// stead_type_full: null
// street: null
// street_fias_id: null
// street_kladr_id: null
// street_type: null
// street_type_full: null
// street_with_type: null
// tax_office: '2901'
// tax_office_legal: '2901'
// timezone: null
// unparsed_parts: null
// }
// interface IMetro {
//   distance: number;
//   line: string;
//   name: string;
// }
// interface IBank {
//   address: {
//     data: {
//       area: string | null;
//       area_fias_id: string | null;
//       area_kladr_id: string | null;
//       area_type: string | null;
//       area_type_full: string | null;
//       area_with_type: string | null;
//       beltway_distance: string | null;
//       beltway_hit: string | null;
//       block: string | null;
//       block_type: string | null;
//       block_type_full: string | null;
//       capital_marker: string | null;
//       city: string | null;
//       city_area: string | null;
//       city_district: string | null;
//       city_district_fias_id: string | null;
//       city_district_kladr_id: string | null;
//       city_district_type: string | null;
//       city_district_type_full: string | null;
//       city_district_with_type: string | null;
//       city_fias_id: string | null;
//       city_kladr_id: string | null;
//       city_type: string | null;
//       city_type_full: string | null;
//       city_with_type: string | null;
//       country: string | null;
//       country_iso_code: string | null;
//       divisions: string | null;
//       entrance: string | null;
//       federal_district: string | null;
//       fias_actuality_state: string | null;
//       fias_code: string | null;
//       fias_id: string | null;
//       fias_level: string | null;
//       flat: string | null;
//       flat_area: string | null;
//       flat_cadnum: string | null;
//       flat_fias_id: string | null;
//       flat_price: string | null;
//       flat_type: string | null;
//       flat_type_full: string | null;
//       floor: string | null;
//       geo_lat: string | null;
//       geo_lon: string | null;
//       geoname_id: string | null;
//       history_values: string | null;
//       house: string | null;
//       house_cadnum: string | null;
//       house_fias_id: string | null;
//       house_kladr_id: string | null;
//       house_type: string | null;
//       house_type_full: string | null;
//       kladr_id: string | null;
//       metro: IMetro[];
//       okato: string | null;
//       oktmo: string | null;
//       postal_box: null;
//       postal_code:string | null;
//       qc: string | null;
//       qc_complete: string | null;
//       qc_geo: string | null;
//       qc_house: string | null;
//       region: string | null;
//       region_fias_id: string | null;
//       region_iso_code: string | null;
//       region_kladr_id: string | null;
//       region_type: string | null;
//       region_type_full: string | null;
//       region_with_type: string | null;
//       settlement: string | null;
//       settlement_fias_id: string | null;
//       settlement_kladr_id: string | null;
//       settlement_type: string | null;
//       settlement_type_full: string | null;
//       settlement_with_type: string | null;
//       source: string | null;
//       square_meter_price: string | null;
//       stead: string | null;
//       stead_cadnum: string | null;
//       stead_fias_id: string | null;
//       stead_type: string | null;
//       stead_type_full: string | null;
//       street: string | null;
//       street_fias_id: string | null;
//       street_kladr_id: string | null;
//       street_type: string | null;
//       street_type_full: string | null;
//       street_with_type: string | null;
//       tax_office: string | null;
//       tax_office_legal: string | null;
//       timezone: string | null;
//       unparsed_parts: string | null;
//     }
//     unrestricted_value: string;
//     value: string;
//   };
//   bic: string | null;
//   cbr: string | null;
//   correspondent_account: string | null;
//   inn: string | null;
//   kpp: string | null;
//   name: {
//     full: string | null;
//     payment: string | null;
//     short: string | null;
//   };
//   okpo: string | null;
//   opf: {
//     [key: string]: string | null
//   };
//   payment_city: string | null;
//   phones: string | null;
//   registration_number: string | null;
//   rkc: string | null;
//   state: {
//     [key: string]: string | null | number;
//   }
//   swift: string | null;
//   treasury_accounts: string | null;
// }

export interface IGetSuggestionsData {
  value?: string;
  parts?: string[];
}

const API_KEY = 'f2526f753c9fc70fefe96207b9ca84bf16659f44';

const baseUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/';

const options = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Token ${API_KEY}`
  }
};
export interface ISuggestionResponse {
  textSuggestion: string;
  payload?: any;
}

export function dadata(
  url: string,
  data?: IDadataRequest,
  type?: string
): Promise<ISuggestionResponse[]> {
  return axios
    .post(url, data, options)
    .then((response: IDadataResponse) => response.data.suggestions)

  // eslint-disable-next-line max-len
    .then((suggestions) => suggestions.map((suggestion: ISuggestion) => parseSuggestion(suggestion, data, type)));
}

function parseSuggestion(
  suggestion: ISuggestion,
  request?: IDadataRequest,
  type?: string
): ISuggestionResponse {
  const { value } = suggestion;
  let result: ISuggestionResponse = {
    textSuggestion: value
  };

  if (type === types.bank && suggestion.data.name.payment) {
    result = {
      textSuggestion: suggestion.data.name.payment
    };
  }

  if (request && request.parts && request.parts.length) {
    result.textSuggestion = suggestion.data[request.parts[0].toLowerCase()];
  }

  switch (type) {
    case types.address: {
      result = {
        ...result,
        payload: getAddressFromSuggestion(suggestion)
      };
      break;
    }
    case types.bank: {
      result = {
        ...result,
        payload: getBankParams(suggestion)
      };
      break;
    }
    case types.fms_unit: {
      result = {
        ...result,
        payload: getPassportSuggestion(suggestion)
      };
      break;
    }
    default:
      break;
  }

  return result;
}
export function dadataTestRequest() {
  dadata('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio');
}

export function getSuggestions(
  type: string,
  { value, parts }: IGetSuggestionsData
) {
  const url = `${baseUrl}${type}`;
  const data: IDadataRequest = {
    parts,
    query: value || '',
    count: 3
  };
  return dadata(url, data, type);
}

export function getGenderByName(name: string) {
  const url = `${baseUrl}${types.fio}`;
  const data = {
    query: name,
    count: 1
  };
  return axios
    .post(url, data, options)
    .then((response) => response.data.suggestions)
    .then((suggestions) => {
      const suggestion = suggestions[0];
      try {
        return suggestion.data.gender === 'UNKNOWN'
          ? 'MALE'
          : suggestion.data.gender;
      } catch (e) {
        return undefined;
      }
    });
}

function getAddressFromSuggestion(
  suggestion: ISuggestion
): { addressRegistration: IAddress } {
  const defaultStreetType = 'ул';

  const houseTypes = {
    block: 'к',
    building: 'стр'
  };

  const {
    city,
    street,
    street_type,
    street_with_type,
    postal_code,
    flat,
    house,
    block,
    block_type,
    area,
    settlement,
    city_district_with_type,
    region_with_type: region
  } = suggestion.data;

  const address = {
    region,
    addressString: suggestion.unrestricted_value,
    street: street_type === defaultStreetType ? street : street_with_type,
    city: settlement || city,
    postcode: postal_code,
    regionalDistrict: area,
    district: city_district_with_type,
    house: pickBy(
      {
        flat,
        block: block_type === houseTypes.block && block,
        building: block_type === houseTypes.building && block,
        number: house
      },
      (value) => value
    )
  };
  return {
    addressRegistration: pickBy(address, (value) => value)
  };
}

function getBankParams(suggestion: ISuggestion): { requisites: IRequisites } {
  const requisites: IRequisites = {
    bankName: suggestion.data.name.payment,
    bik: suggestion.data.bic,
    ks: suggestion.data.correspondent_account,
    rs: '',
    bankPlace: suggestion.data.address.data.settlement
      ? suggestion.data.address.data.settlement
      : suggestion.data.address.data.city
        ? suggestion.data.address.data.city
        :suggestion.data.address.data.region,
    bankPlaceType: suggestion.data.address.data.settlement_type_full
      ? suggestion.data.address.data.settlement_type_full
      : suggestion.data.address.data.city_type_full
        ? suggestion.data.address.data.city_type_full
        : suggestion.data.address.data.region_type_full
  };
  return { requisites };
}

function getPassportSuggestion(
  suggestion: ISuggestion
): { passport: { unitCode: string } } {
  const passport = {
    unitCode: suggestion.data.code ? suggestion.data.code.replace('-', '') : ''
  };
  return { passport };
}
/* todo зачем это? Удалить? */
// function setMaskUnitCode(unitCode: string): string {
//   const [partOne, partTwo] = unitCode.split('-');
//   return `${partOne} – ${partTwo}`;
// }

document.addEventListener('DOMContentLoaded', () => {
  getSuggestions('fio', { value: 'test' });
});
