import includes from 'lodash/includes';

import { useStore } from 'components/hooks/useStore';
import { duProductsId } from 'staticContent/duProductsIdAndName';

import { Item } from '../../../header/item';

import S from './styles';

export const OrderDetails = () => {
  const {
    purchase: {
      data: {
        order,
        order: {
          date,
          number
        } = { date: '', number: '' },
        productId
      }
    }
  } = useStore()
  const currentTitle = includes(duProductsId, productId) ? 'договора' : 'заявки'
  return (
    <>
      {order && (
        <S.OrderDetails>
          <Item title={`Дата ${currentTitle}`}>{date}</Item>
          <Item title={`Номер ${currentTitle}`}>№{number}</Item>
        </S.OrderDetails>
      )}
    </>
  );
};
