import styled, { css } from 'styled-components';

import { Styles } from 'components/shared';
// @ts-expect-error
import ArrowBack from 'assets/img/svg/arrow-success.svg';
import { media, ITheme } from 'assets/styles/theme';

interface IStepProps {
  theme: ITheme;
  currentStep: number;
  index: number;
}

interface IStepWrapper {
  currentStep: number;
  stepWidth: number;
  last: boolean;
}

interface ITrackLineProps {
  step: number;
  stepWidth: number;
  last: boolean;
}

const StepColor = css<IStepProps>`
  ${({ theme, currentStep, index }: IStepProps) => {
    const isCurrentStep = currentStep === index;
    if (isCurrentStep) return theme.colors.textPrimary;
    return theme.colors.description;
  }};
`;

const TrackLine = styled.div<ITrackLineProps>`
  height: 2px;
  background-color: ${({ theme }) => theme.colors.link};
  width: ${({ last, stepWidth, step }) => {
    const widthNumber = stepWidth * (Number(step) + 1);
    if (!last) {
      return `${widthNumber - 30}px`;
    }
    return `${widthNumber}px`;
  }};
  transition-property: width;
  transition-duration: 0.35s;
  border-radius: 5px;
  position: absolute;
  left: 0;
  bottom: -2px;
  ::after {
    position: absolute;
    bottom: -5px;
    right: -5px;
    content: "";
    box-shadow: 0 2px 4px 0 rgba(59, 66, 73, 0.4);
    width: 12px;
    height: 12px;
    border: 3px solid white;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.link};
    z-index: 1;
  }
  ${media.tablet} {
    top: -2px;
    left: 0;
    width: ${({ stepWidth, step, last }) => {
    const width = stepWidth * (Number(step) + 1) + 15;
    if (last) {
      return `calc(100vw - ${5}px)`;
    }
    return `${width}px`;
  }};
  }
  ${media.mobile} {
    width: ${({ stepWidth, step, last }) => {
    const width = stepWidth * (Number(step) + 1) + 15;
    if (last) {
      return `calc(100vw + ${width - 108}px)`;
    }
    return `${width}px`;
  }};
  }
`;

interface ITitleProps {
  success: boolean;
}
const Title = styled(Styles.H3)<ITitleProps>`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  ::after {
    content: "";
    margin-left: 10px;
    width: 16.41px;
    height: 11.71px;
    background: url(${ArrowBack});
    visibility: ${({ success }) => (success ? 'visible' : 'hidden')};
    transition-property: visibility;
    transition-duration: 0.35s;
  }
  ${media.tablet} {
    font-size: 14px;
    line-height: 18px;
  }
`;
const Description = styled(Styles.ExtraText)`
  ${media.tablet} {
    font-size: 12px;
    line-height: 15px;
  }
`;
/* todo зачем тут any? если удлатиь падает импорт */
const Step:any = styled.li<any>`
  list-style: none;
  cursor: default;
  height: 68px;
  width: 300px;
  ${Title} {
    margin-bottom: 6px;
  }

  ${media.mobile} {
    width: 177px;
    ${Description} {
      color: ${StepColor};
    }
    ${Title} {
      color: ${StepColor};
    }
  }
  position: relative;
`;

const StepsWrapper = styled.ol<IStepWrapper>`
  position: relative;
  padding: 0;
  display: flex;
  margin-bottom: 60px;
  border-bottom: 2px solid #e0dad5;
  border-radius: 2px;
  margin-top: 0;
  margin-left: 30px;
  ${Step} {
    margin-bottom: -2px;
  }

  ${media.tablet} {
    position: fixed;
    bottom: 0;
    z-index: 2;
    background: ${({ theme }) => theme.colors.white};
    margin: 0;
    padding: 14px 30px 0 30px;
    width: 100%;
    ${({ last }) => (last
    ? css`
      &:after {
        content: "";
        height: 1px;
        width: 100%;
      }
      `
    : '')}
  }
  ${media.mobile} {
    width: auto;
    left: ${({ stepWidth, currentStep }) => {
    const leftPosition = currentStep * stepWidth * -1;
    return `${leftPosition}px`;
  }};
    transition: left 0.35s;
  }
`;
Step.Description = Description;
Step.Title = Title;
Step.TrackLine = TrackLine;

export default { Step, StepsWrapper }
