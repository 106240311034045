import styled from 'styled-components';

import { IWizardForm } from 'interfaces';
import { FormField } from 'entities/formField';
import { Styles } from 'components/shared';
import { media, theme } from 'assets/styles/theme';
import { getAppeal } from 'helpers/form';
import { PHONE_LINK } from 'constants/phone';
import { useUserOrProductInfo } from 'components/hooks/useUserOrProductInfo';

import { Form } from '../../form';

const Title = styled(Styles.H3)`
  margin-top: -36px;
  text-align: center;
  margin-bottom: 8px;
  ${media.mobile} {
    text-align: left;
    font-size: 16px;
    line-height: 23px;
    white-space: pre-line;
    margin-top: -38px;
    margin-bottom: -15px;
  }
`;

const SuccessTitle = () => {
  const {
    firstName,
    lastName,
    patronymic,
    gender
  } = useUserOrProductInfo()
  const userName = `${firstName} ${patronymic || lastName}`;
  const apeal = getAppeal(gender, userName);
  return (
    <Title>
      {apeal} Благодарим Вас за&nbsp;выбор
      <br />
      ООО &laquo;УК ВЕЛЕС Менеджмент&raquo;!
    </Title>
  );
};

const Description = styled(Styles.ExtraText)`
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  text-align: center;
  margin-bottom: 28px;
  a {
    color: ${theme.colors.textPrimary};
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    ${media.mobile} {
      font-size: 17px;
      line-height: 23px;
    }
  }
  ${media.mobile} {
    text-align: left;
    margin-bottom: 0;
    font-size: 17px;
    line-height: 23px;
    white-space: pre-line;
  }
`;

const SuccessDescription = () => (
  <Description>
    Ваши документы находятся в обработке. При возникновении вопросов свяжитесь
    с консультантом по телефону {PHONE_LINK}.
  </Description>
);

const title = <SuccessTitle />;
const description = <SuccessDescription />;

const successField = new FormField(
  {
    name: 'success_field',
    placeholder: '',
    type: 'content',
    initialValue: ''
  },
  {
    groupTitle: title,
    groupDescription: description
  }
);

export const successAcquiring: IWizardForm = {
  title: '',
  url: '',
  fields: [successField],
  submitTitle: 'Вернуться на главную'
};

export const TestSuccess = () => (
  <Form fields={[successField]} url="" onSubmit={() => undefined} />
);
