import styled from 'styled-components';

import { Styles } from 'components/shared';
import { media } from 'assets/styles/theme';
import { FormWidth, widthWindow } from 'assets/styles/mixins';

import StylesEsia from '../esiaButton/styles';
import StylesForm from '../form/styles';

const { FormComponent } = StylesForm;

const MultiStepWizard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${FormWidth}
  ${media.notMobile} {
    &.max-width {
      ${Styles.Panel} {
        min-width: 570px; 
        ${FormComponent} {
          width: 450px;
        }
      }
    }
  }

  ${Styles.Panel} {
    display: block;
    height: auto;
    padding: 48px 100px 60px 100px;
    margin-bottom: 60px;
    justify-content: start;
    align-items: center;
    transition: height 10s;

    ${media.tablet} {
      padding: 38px 61px;
      ${StylesEsia.EsiaButton} {
        margin-top: -14px;
      }
    }
    ${media.mobile} {
      padding: 30px 15px;
      margin-bottom: 26px;
      ${StylesEsia.EsiaButton} {
        margin-top: 0;
      }
    }
    ${Styles.H2} {
      text-align: center;
      margin-bottom: 35px;

      ${media.tablet} {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 32px;
      }
    }
  }
  
  .loader-container {
    ${media.mobile} {
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${widthWindow}px;
      min-height: 100px;
    }
  }
`;

const FormTitle = styled.h1`
  text-align: center;
  font-size: 22px;
  line-height: 29px;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 40px;
  ${media.tablet} {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 48px;
  }
  ${media.mobile} {
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 26px;
    font-weight: normal;
  }
`;

const ReccommendedProductsTitle = styled(Styles.H2)`
  margin-bottom: 22px;
  ${media.tablet} {
    padding: 0 15px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
  }
`;

export default {
  MultiStepWizard,
  FormTitle,
  ReccommendedProductsTitle
};
