import { useSelector } from 'react-redux';

import { IState } from 'interfaces';

export const useStore = () => {
  const {
    recommendedProducts,
    purchase,
    view,
    opifYield
  } = useSelector((state: IState) => state)
  return {
    recommendedProducts,
    purchase,
    view,
    opifYield
  }
}
