import { Styles } from 'components/shared';
import { IWizardForm } from 'interfaces';
import { PHONE } from 'constants/phone';

import { editCheckedDataFields } from './fields';

import S from './styles';

const BeforeFields = () => (
  <S.BeforeFields style={{ display: 'block' }}>
    Пожалуйста, проверьте правильность введенных Вами данных. Если данные
    корректны, но вы снова видите это сообщение – воспользуйтесь{' '}
    <Styles.LinkText>учетной записью Госуслуги.ру</Styles.LinkText> или обратитесь к
    консультанту по телефону {PHONE}
  </S.BeforeFields>
);

const {
  lastName,
  firstName,
  patronymic,
  seriesAndNumber,
  inn
} = editCheckedDataFields;
export const editCheckedData: IWizardForm = {
  BeforeFields,
  title: 'Проверьте Ваши данные', // изменить на фио
  fields: [lastName, firstName, patronymic, seriesAndNumber, inn],
  url: 'trust-gate/send-data'
};
