import styled from 'styled-components';

const Popup = styled.div`
  z-index: 1;
  background: #ffffff;
  box-shadow: 0 5px 10px rgba(48, 45, 43, 0.25);
  border-radius: 10px;
  padding: 22px;
  position: absolute;
  right: 0;
`;

export default { Popup };
