import ScrollBar from 'react-custom-scrollbars';

import { IProps } from './interfaces';

import S from './styles';

export const ScrollContainer = ({ renderView, children }: IProps) => (
  <ScrollBar
    renderView={renderView}
    renderTrackVertical={() => <S.TrackElement />}
    renderThumbVertical={() => <S.Thumb />}
    thumbSize={50}
  >
    {children}
  </ScrollBar>
);
