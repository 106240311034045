export function getBlankUrl(
  newParams: { form: number; step: number },
  productType?: string,
  productId?: string
) {
  const actionPart = productType ? 'purchase/' : 'registration/';
  const productTypePart = productType ? `${productType}/` : '';
  const productIdPart = productId ? `${productId}/` : '';
  const nextUrl = `/${actionPart}${productTypePart}${productIdPart}${newParams.step +
    1}/${newParams.form + 1}`;
  return nextUrl;
}
