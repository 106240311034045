const size = {
  mobile: '768px',
  tablet: '1024px',
  desktopS: '1024px',
  desktopL: '1366px'
};

export const device = {
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  desktop: `(max-width: ${size.desktopS})`
};

export interface ITheme {
  colors: { [key: string]: string };
  borderRadius: string;
}

export const theme: ITheme = {
  colors: {
    link: '#0096B7',
    sidebar: '#53778A',
    background: '#F9F8F6',
    buttonPrimary: '#F4860A',
    textPrimary: '#424249',
    textExtra: '#B4B2A6',
    border: '#EDECE6',
    white: '#FFF',
    black: '#000',
    underline: 'rgba(0, 150, 183,0.25)',
    shadow: 'rgba(48, 45, 43, 0.25)',
    description: '#A8A099',
    disabledText: '#EDEBE8'
  },
  borderRadius: '5px'
};

export const gridTheme = {
  breakpoints: {
    xl: 1280,
    lg: 1024,
    md: 768,
    sm: 540
  },
  container: {
    padding: 0,
    maxWidth: {
      xl: 1228,
      lg: 1170,
      md: 970,
      sm: 750
    }
  }
};

export const media = {
  max(breakpoint: string) {
    return `@media (max-width: ${gridTheme.breakpoints[breakpoint] - 1}px)`;
  },
  min(breakpoint: string) {
    return `@media (min-width: ${gridTheme.breakpoints[breakpoint]}px)`;
  },
  minmax(breakpointMin: string, breakpointMax: string) {
    return `@media (min-width: ${
      gridTheme.breakpoints[breakpointMin]
    }px) and (max-width: ${gridTheme.breakpoints[breakpointMax] - 1}px)`;
  },
  get desktop() {
    return this.min('xl');
  },
  get tablet() {
    return this.max('xl');
  },
  get tabletOnly() {
    return this.minmax('sm', 'xl');
  },
  get mobile() {
    return this.max('sm');
  },
  get notMobile() {
    return this.min('md');
  }
};
