import {
  takeLatest,
  put,
  call
} from 'redux-saga/effects';

import { api } from 'api';
import { actionTypes } from 'store/actionTypes';
import { Token } from 'entities/token';
import { PuchaseRequestData } from 'entities/purchaseDataRequest';
import { getPurchaseHeaders } from 'api/options';
import { env, Url } from 'api/url';
import { apiUrl } from 'api/hosts';
import { IUserDocs } from 'interfaces/userDocs';

import { IResponse, ISendPurchaseData } from './interfaces';

export const host = env === 'development' ? apiUrl.dev : apiUrl.production;

const {
  OPIF,
  NOT_RESPOND,
  REDIRECT_ERROR
} = actionTypes.PURCHASE;

export function* sendPurchaseData({ url, payload, withoutFormatting }: ISendPurchaseData) {
  const { SUCCESS, ERROR } = OPIF;
  try {
    if (payload) {
      const { data: purchaseData, requestId } = payload;
      if (url) {
        const { requestData, files } = new PuchaseRequestData(purchaseData);
        const { token }:IResponse = yield call(
          // @ts-expect-error
          api.post,
          { url, host, data: withoutFormatting ? purchaseData : requestData },
          getPurchaseHeaders()
        );
        if (token) {
          Token.set('purchase', token);
        }

        if (files) {
          const filesUrl = Url.purchase.files;
          // @ts-expect-error
          yield call(api.post, { host, url: filesUrl, data: files });
        }
      }
      if (url === 'purchase/step-one') {
        const response: IUserDocs[] = yield call(
          api.get,
          { url: Url.purchase.userDocs.get },
          getPurchaseHeaders()
        );
        interface IReducedDocs {
          [key: string]: IUserDocs;
        }

        const docs = response.reduce((accumulator: IReducedDocs, doc) => {
          // eslint-disable-next-line default-case
          switch (doc.title) {
            case 'Единый файл': {
              accumulator.unified = doc;
              break;
            }
            case 'Заявление об открытии лицевого счета': {
              accumulator.openLc = doc;
              break;
            }
            case 'Заявлении о присоединении к Договору': {
              accumulator.connectDog = doc;
              break;
            }
            case 'Анкета зарегистрированного лица': {
              accumulator.profileRegistred = doc;
              break;
            }
            case 'Заявка на приобретение инвестиционных паев': {
              accumulator.applicationAcquisition = doc;
              break;
            }
            case 'Cогласие на обработку персональных данных': {
              accumulator.agreementPersonal = doc;
              break;
            }
            case 'Анкета физического лица': {
              accumulator.profilePhysical = doc;
              break;
            }
            case 'Реквизиты': {
              accumulator.transit = doc;
              break;
            }
          }
          return accumulator;
        }, {});

        yield put({
          type: actionTypes.PURCHASE.USER_DOCS.GET.SUCCESS,
          payload: docs
        });

        yield put({
          payload: { requestId, data: purchaseData },
          type: SUCCESS
        });
      } else {
        yield put({
          payload: { requestId, data: purchaseData },
          type: SUCCESS
        });
      }
    }
  } catch ({ code, payload }) {
    if (code === 500) {
      yield put({
        type: NOT_RESPOND
      });
    } else if (
      (code >= 301 && code <= 308) || code === 409
    ) {
      yield put({
        payload,
        type: REDIRECT_ERROR
      });
    } else {
      yield put({
        payload,
        type: ERROR
      });
    }
  }
}

export function* purchase() {
  yield takeLatest(OPIF.REQUEST, sendPurchaseData);
}
