import { useDispatch } from 'react-redux';

import { actionTypes } from 'store/actionTypes';

import { useStore } from './useStore';

export const useNotRespond = () => {
  const { purchase: { notRespond } } = useStore()
  const dispatch = useDispatch()
  const setNotRespond = () => dispatch({ type: actionTypes.PURCHASE.NOT_RESPOND });
  const resetNotRespond = () => dispatch({ type: actionTypes.PURCHASE.RESET_NOT_RESPOND })
  return {
    notRespond,
    setNotRespond,
    resetNotRespond
  }
}
