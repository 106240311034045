import { AxiosError, AxiosResponse } from 'axios';

import { IPurchaseErrors } from 'interfaces';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_MESSAGES } from '../../constants/codeMessages';

export function errorHandler({ response }: AxiosError): { code: number; payload: IPurchaseErrors } {
  const code = response ? response.status : 500;
  return {
    // @ts-expect-error
    payload: response && response.data || {},
    message: getErrorMessage(response),
    code: Number(code) || 501
  };
}

export function getErrorMessage(response?: AxiosResponse): string {
  if (response && response.status !== 504) {
    const { data, status } = response;

    if (status && DEFAULT_MESSAGES[status]) {
      return DEFAULT_MESSAGES[status];
    }

    if (data) {
      if (data.message) {
        return data.message;
      }

      if (data[0]) {
        return data[0];
      }
    }
  }

  return DEFAULT_ERROR_MESSAGE;
}
