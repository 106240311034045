import { Sms } from '../../sms/purshaseSms';
import { IProps } from './interfaces';
import { CommonTooltip } from '../../../shared';

import S from './styles';

export const SmsTooltip = ({
  tooltipProps,
  smsProps,
  nextPage
}: IProps) => (
  <CommonTooltip {...tooltipProps}>
    <S.Title>Ввод кода из СМС</S.Title>
    <Sms
      {...smsProps}
      nextPage={nextPage}
      showCancelButton
    />
  </CommonTooltip>
);
