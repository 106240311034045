import { env } from 'api/url';

import { getCookie } from '../cookie';

export function getEsiaLink(type?: string, productId?: string): string {
  const { protocol } = window.location;
  const { hostname } = window.location;
  let redirectUrl = `${hostname}${
    env !== 'development' ? '/api' : ''
  }/esia-bridge/auth`;
  if (type) {
    redirectUrl += '/invest';
  }
  if (productId) {
    redirectUrl += `/${productId}`;
  }

  return `https://esia-bridge.veles-management.ru/blitz/bridge/entrance?redirect_url=${protocol}//${redirectUrl}`;
}
/* todo удалить? */
export function getRegToken(): string | undefined {
  return getCookie('regToken');
}

export function isTrusted(data: any): boolean {
  return !!data.trusted;
}
