import { Icon } from 'assets/img/svg';
import { Styles } from 'components/shared';

import S from './styles';

export const PassportNotification = () => (
  <S.PassportNotification>
    <Icon.Passport />
    <Styles.ExtraText>
      Не убирайте, пожалуйста, паспорт. Паспортные данные пригодятся на
      следующих этапах.
    </Styles.ExtraText>
  </S.PassportNotification>
);
