import { useStore } from './useStore';

export const useUserOrProductInfo = () => {
  const {
    purchase: {
      data: {
        productId,
        productName,
        productDocs,
        productRequisites,
        firstName,
        lastName,
        gender,
        patronymic
      }
    }
  } = useStore()
  return {
    productId,
    productName,
    productDocs,
    productRequisites,
    firstName,
    lastName,
    gender,
    patronymic
  }
}
