import { getGenderByName } from 'helpers/dadata';
import { translate, capitilize } from 'helpers/string';

export async function getAdditionalNameParams(
  firstName?: string,
  lastName?: string
) {
  if (firstName && lastName) {
    const name = `${firstName} ${lastName}`;
    const latinName = translate(name);
    const gender = await getGenderByName(name);
    return { gender, latinName: capitilize(latinName) };
  }
  return {};
}
