import { SchemaOf } from 'yup';
import includes from 'lodash/includes';

import { IFormFields } from 'interfaces';
import { duProductsName } from 'staticContent/duProductsIdAndName';
import {
  getInitialValues,
  getValidationSchema,
  getValidationSchemaDU
} from 'helpers/form';

import {
  FormActionPayload,
  IFormAction,
  IFormState,
  IUpdateInitialValuesPayload
} from './intefaces';

export const formActions = {
  updateInitialValues: 'updateInitialValues',
  updateValidationSchema: 'updateValidationSchema',
  updateBoth: 'updateBoth',
  setDisableSend: 'setDisableSend',
  setFormSend: 'setFormSend',
  setShowBeforeNext: 'setShowBeforeNext',
  newForm: 'setNewForm'
};

export const initialFormState: IFormState = {
  showBeforeNext: false,
  isDisableSend: false,
  isFormSend: false,
  initialValues: {},
  validationSchema: null
};

export function formReducer(
  state: IFormState = initialFormState,
  { type, payload }: IFormAction
): IFormState {
  switch (type) {
    case formActions.updateInitialValues: {
      return {
        ...state,
        initialValues: updateInitialValues(payload)
      };
    }
    case formActions.updateValidationSchema: {
      return {
        ...state,
        validationSchema: updateValidationSchema(payload)
      };
    }
    case formActions.updateBoth: {
      const initialValues = updateInitialValues(payload);
      const validationSchema = updateValidationSchema(payload);
      return {
        ...state,
        initialValues,
        validationSchema
      };
    }
    case formActions.setDisableSend: {
      return {
        ...state,
        isDisableSend: payload as boolean
      };
    }
    case formActions.setFormSend: {
      return {
        ...state,
        isFormSend: payload as boolean
      };
    }
    case formActions.setShowBeforeNext: {
      return {
        ...state,
        showBeforeNext: payload as boolean
      };
    }
    case formActions.newForm: {
      const initialValues = updateInitialValues(payload);
      const validationSchema = updateValidationSchema(payload);
      return {
        ...initialFormState,
        initialValues,
        validationSchema
      };
    }
    default:
      return state;
  }
}

function updateInitialValues(payload: FormActionPayload): IFormFields {
  const { fields, stateData } = payload as IUpdateInitialValuesPayload;
  return getInitialValues(fields, stateData);
}

function updateValidationSchema(
  payload: FormActionPayload
): SchemaOf<any> | null {
  const {
    fields,
    stateData,
    stateData: { productName }
  } = payload as IUpdateInitialValuesPayload;

  if (includes(duProductsName, productName)) {
    return getValidationSchemaDU(fields, stateData) || null;
  }
  return getValidationSchema(fields) || null;
}
