import { IWizardForm } from 'interfaces';
import { FormField } from 'entities/formField';
import { formFields } from 'components/shared/formFields';
import { getFakeEvent } from 'helpers/field/getFakeEvent';
import { Change, Handle } from 'types/inputEvent';
import { AvailableMasks } from 'constants/masks/masks';
import { PopupHint } from 'components/shared/fieldHints/popup';
import {
  types,
  granulars,
  getGenderByName
} from 'helpers/dadata';

import { EsiaButton } from '../../esiaButton';

const {
  gender,
  name,
  contacts,
  checkBoxes
} = formFields;

const fioObject = {
  firstName: '',
  lastName: '',
  patronymic: ''
};

const changeGender = (change: Handle<Change>) => {
  if (fioObject.lastName && fioObject.firstName) {
    let nameString = `${fioObject.lastName} ${fioObject.firstName}`;

    if (patronymic) {
      nameString += ` ${patronymic}`;
    }

    getGenderByName(nameString).then((response) => {
      if (change && response) {
        change(getFakeEvent(response, 'gender') as Change);
      } else if (change && !response) {
        change(getFakeEvent('MALE', 'gender') as Change);
      }
    });
  }
};

const firstName = new FormField(name.firstName, {
  isRequired: true,
  disabled: true,
  withSuggestions: {
    type: types.fio,
    parts: granulars.fio.name
  },
  onChangeValue: (value, change) => {
    fioObject.firstName = value;

    if (change) {
      changeGender(change);
    }
  }
});

const lastName = new FormField(name.lastName, {
  isRequired: true,
  disabled: true,
  withSuggestions: {
    type: types.fio,
    parts: granulars.fio.surname
  },
  onChangeValue: (value, change) => {
    fioObject.lastName = value;

    if (change) {
      changeGender(change);
    }
  }
});

const patronymic = new FormField(name.patronymic, {
  isRequired: false,
  disabled: true,
  hint: (
    <PopupHint>
      <>
        Если у Вас нет Отчества, просто пропустите это поле.
        <br />Убедитесь, что информация в полях соответствует данным Вашего
        паспорта гражданина Российской Федерации, иначе договор может быть
        признан недействительным.
      </>
    </PopupHint>
  ),
  withSuggestions: {
    type: types.fio,
    parts: granulars.fio.patronymic
  },
  onChangeValue: (value, change) => {
    fioObject.patronymic = value;

    if (change) {
      changeGender(change);
    }
  }
});

const email = new FormField(contacts.email, {
  isRequired: true,
  disabled: true,
  withSuggestions: {
    type: types.email
  }
});

const phone = new FormField(contacts.phone, {
  isRequired: true,
  disabled: true,
  mask: AvailableMasks.phone
});

const agree = new FormField(checkBoxes.agree, { disabled: true, isRequired: true });

export const contactDetailsFields = {
  firstName,
  lastName,
  patronymic,
  email,
  phone,
  gender,
  agree
};

export const contactDetails: IWizardForm = {
  title: 'Контактные данные',
  BeforeFields: () => <EsiaButton />,
  fields: [lastName, firstName, patronymic, gender, email, phone, agree],
  url: 'registration/step-one'
};
