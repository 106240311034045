import {
  useReducer,
  useState,
  useEffect,
  FC
} from 'react';

import { Formik, Form as FormikForm } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import some from 'lodash/some';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router';

import { Styles } from 'components/shared';
import { useStore } from 'components/hooks/useStore';
import { actionTypes } from 'store/actionTypes';
import { purchaseAction, actionCreator } from 'helpers/actions/actionCreator';
import { getAdditionalNameParams } from 'helpers/actions/purchase/getAdditionalFields';
import { Modal } from 'types/modal';
import { PurchaseData, addresses } from 'entities/purchaseData';
import { FormField } from 'entities/formField';
import { removeLastComma } from 'helpers/string';
import { IBeforeFields } from 'interfaces/wizardForm';
import {
  IBeforeNext,
  IField,
  IPurchaseData,
  IFormFields,
  IAddress
} from 'interfaces';

import { FieldsContainer } from './FieldsContainer';
import { ForeignerError } from '../applicationForms/foreignerError';
import { ServiceError } from '../applicationForms/error/serviceError';
import { LoaderTooltip } from '../tooltips/loader';
import { UserError } from '../applicationForms/error/userError';
import { VerificationAddressTooltip } from '../tooltips/verificationAddress';
import { DEFAULT_ERROR_MESSAGE } from '../../../constants/codeMessages';

import {
  formActions,
  formReducer,
  initialFormState
} from './formReducer';

import S from './styles';

export const Form = ({
  BeforeNext,
  BeforeFields,
  AfterFields,
  AfterHiddenFields,
  onSubmit,
  submitTitle = 'Далее',
  fields,
  formSubmit,
  isModal,
  hiddenSubmit,
  disclaimer,
  modals,
  isInitialValid,
  url,
  title,
  isLastForm
}: IFormProps) => {
  const {
    purchase: {
      request: {
        loading,
        success,
        requestId
      },
      request,
      data,
      notRespond,
      redirectError,
      errors,
      sms: {
        check: {
          error: { message }
        }
      }
    }
  } = useStore()
  const reduxDispatch: Dispatch = useDispatch()
  const [formId] = useState(uuid());
  const [state, dispatch] = useReducer(formReducer, initialFormState);
  const {
    isFormSend,
    validationSchema,
    initialValues,
    isDisableSend
  } = state;

  const sendForm = (data:IPurchaseData, requestId:string, withoutFormatting:boolean) => {
    reduxDispatch(
      purchaseAction(actionTypes.PURCHASE.OPIF.REQUEST, url, {
        data,
        requestId
      }, withoutFormatting)
    );
  }

  const currentSetAddress = (data:IPurchaseData) => {
    reduxDispatch(actionCreator(actionTypes.PURCHASE.SET_ADDRESS, { data }));
  }

  useEffect(() => {
    dispatch({
      type: formActions.newForm,
      payload: {
        fields,
        stateData: data
      }
    });
  }, [fields]);

  useEffect(() => {
    dispatch({
      type: formActions.updateInitialValues,
      payload: {
        fields,
        stateData: data
      }
    });
  }, [data]);

  useEffect(() => {
    if (isCorrectRequest() && !isFormSend && loading) {
      dispatch({ type: formActions.setFormSend, payload: true });
    }
  }, [isFormSend, loading]);

  useEffect(() => {
    if (isCorrectRequest() && isFormSend && success) {
      handleNext();
    }
  }, [isFormSend, success]);
  /* todo много стейтов переделать на кастомный хук? */
  // state для модального окна с подтверждением адреса
  const [isShowVerification, setShowVerification] = useState(false);
  // state для адреса, который ввел пользователь
  const [currentAddress, setCurrentAddress] = useState<string | undefined>('');
  // state для адреса, который есть в совпадениях с сервисом dadata
  const [matchAddress, setMatchAddress] = useState('');
  // state для полей, которые в итоге будут отправлены
  const [currentFields, setCurrentFields] = useState();
  // есть ли адреса в dadata
  const [searchError, setSearchError] = useState(false);
  // открыты ли сейчас все поля для заполнения адреса
  const [hiddenStatus, setHiddenStatus] = useState(true);

  /* todo попробовать вынести в отдельную ф-ю */
  const strWithPrefix = (granular: string | undefined, prefix: string) => {
    if (granular) {
      return `${prefix}${granular},`;
    }
    return '';
  };
  const getAddressStringFromGranulars = (rawAddress: IAddress) => {
    const {
      postcode,
      regionalDistrict,
      region,
      city,
      street,
      house
    } = rawAddress;

    const {
      number,
      flat,
      building,
      block
    } = house || {
      flat: '',
      building: '',
      number: '',
      block: ''
    };

    const granularsString = `${postcode ? `${postcode}, ` : ''}${strWithPrefix(
      region === 'г Москва' ? '' : region,
      ''
    )}${strWithPrefix(city, 'г ')}${strWithPrefix(
      regionalDistrict,
      ' '
    )}${strWithPrefix(
      street,
      'ул '
    )}${strWithPrefix(
      number,
      'д '
    )}${strWithPrefix(building, 'строение ')}${strWithPrefix(
      block,
      'корпус '
    )}${strWithPrefix(flat, 'кв ')}`;

    return setCurrentAddress(
      hiddenStatus
        ? removeLastComma(granularsString.trim())
        : rawAddress.addressString
    );
  };

  const isCorrectRequest = () => formId === requestId;

  const handleSubmit = async (formFields: IFormFields) => {
    blurInputs();

    if (isModal) {
      modalSubmit(formFields);
    } else {
      const purchaseData = new PurchaseData(cloneDeep(formFields), hiddenStatus);
      if (formFields.addressRegistration) {
        getAddressStringFromGranulars(purchaseData.rawData.addressRegistration);
      }
      await setAddress(purchaseData);
      setSearchError(purchaseData.getSuggestionStatus());
      await setAdditionalNameParams(purchaseData);
      const submitFields = formSubmit
        ? formSubmit(purchaseData.get(), data)
        : purchaseData.get();
      if (formFields.addressRegistration
          && (title === 'Паспортные данные' || title === 'Заполните недостающие данные')) {
        setMatchAddress(submitFields.addressRegistration.addressString);
        // @ts-expect-error
        setCurrentFields(submitFields);
        setShowVerification(true);
      }

      if (title !== 'Паспортные данные'
          && title !== 'Заполните недостающие данные') {
        sendForm(submitFields, formId, !!formSubmit);
      }
    }
  };

  const sendAddress = () => {
    setShowVerification(false);
    // @ts-expect-error
    sendForm(currentFields, formId, !!formSubmit);
  };

  const blurInputs = () => forEach(document.querySelectorAll('input'), (input) => input.blur);

  const modalSubmit = (formFields: IFormFields) => onSubmit(formFields);

  const setAddress = async (purchaseData: PurchaseData) => {
    if (purchaseData.get().addressRegistration) {
      await purchaseData.setAddress(addresses.registration, data);
      currentSetAddress(purchaseData.get());
    }
    if (purchaseData.get().addressPost) {
      await purchaseData.setAddress(addresses.post, data);
    }
  };

  const setAdditionalNameParams = async (purchaseData: PurchaseData) => {
    const nameParams = purchaseData.get();
    const { firstName, lastName } = nameParams;
    const {
      firstName: propsFirstName,
      lastName: propsLastName
    } = data;
    const fieldsHasName = some(fields, (field) => field.name === 'firstName');

    if (fieldsHasName) {
      const additionalNameParams = await getAdditionalNameParams(
        firstName || propsFirstName,
        lastName || propsLastName
      );
      const { gender } = data;
      if (gender) {
        additionalNameParams.gender = gender;
      }

      purchaseData.set(additionalNameParams);
    }
  };

  const handleChangeFieldProps = (
    updatedFields: FormField[],
    changedField?: IField
  ) => {
    dispatch({
      type: formActions.updateValidationSchema,
      payload: {
        fields: updatedFields,
        stateData: data
      }
    });
    handleDisableSend(changedField);
  };

  const handleDisableSend = (changedField?: IField) => {
    if (changedField && changedField.name === 'foreigner') {
      dispatch({
        type: formActions.setDisableSend,
        payload: !isDisableSend
      });
    }
  };

  const handleNext = () => {
    if (BeforeNext) {
      dispatch({ type: formActions.setShowBeforeNext, payload: true });
    } else {
      onSubmit();
    }
    dispatch({ type: formActions.setFormSend, payload: false });
  };

  const getSubmitIsDisable = (isValid: boolean) => {
    if (validationSchema) {
      return !isValid || request.loading;
    }
    return request.loading;
  };
  const setShowBeforeNext = () => {
    dispatch({ type: formActions.setShowBeforeNext, payload: false });
  };
  /* стейты нужны для изменения статуса disabled кнопки на странице
   с подтверждением адреса и смс перед покупкой (чтобы была активна при закрытии popup) */
  const [wasOpenTooltip, setWasOpenTooltip] = useState(false)
  const [wasOpenSmsTooltip, setWasOpenSmsTooltip] = useState(false)
  const { step, form } = useParams()
  useEffect(() => {
    if (step !== '2' && form !== '2') {
      setWasOpenTooltip(false)
    }
  }, [step, form])
  return (
    <S.FormComponent data-test="form-component">
      {BeforeFields && <BeforeFields onSubmit={() => onSubmit()} />}
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        isInitialValid={isInitialValid}
      >
        {(formProps) => {
          const isErrorExist = isEmpty(formProps.errors) && (wasOpenTooltip || wasOpenSmsTooltip)
          return (
            <>
              { (title === 'Паспортные данные' || title === 'Заполните недостающие данные') &&
                <VerificationAddressTooltip
                  setWasOpenTooltip={setWasOpenTooltip}
                  searchError={searchError}
                  onSubmit={sendAddress}
                  matchAddress={matchAddress}
                  currentAddress={currentAddress}
                  isShow={isShowVerification}
                  onCancel={() => setShowVerification(false)}
                />}
              <FormikForm data-test="form-component">
                <FieldsContainer
                  setHiddenStatus={setHiddenStatus}
                  fields={fields}
                  formProps={formProps}
                  AfterFields={AfterFields}
                  AfterHiddenFields={AfterHiddenFields}
                  onChangeFieldProps={handleChangeFieldProps}
                  errors={errors}
                  request={request}
                />
                {!isDisableSend
                  ? (
                    <S.FieldContainer>
                      {!hiddenSubmit && (
                        <Styles.Button
                          type="submit"
                          disabled={getSubmitIsDisable(isLastForm
                              || formProps.isValid
                              || isErrorExist)}
                        >
                          {submitTitle}
                        </Styles.Button>
                      )}
                      {disclaimer && disclaimer}
                    </S.FieldContainer>
                  )
                  : <ForeignerError />}
              </FormikForm>
              {modals && size(modals)
                ? map(modals, (ComponentModal, index) => (
                  <ComponentModal
                    key={index}
                    onHide={() => undefined}
                    parentFormProps={formProps}
                  />
                ))
                : null}
            </>
          )
        }}
      </Formik>
      {loading && !notRespond && !redirectError
        ? <LoaderTooltip isShow description="Пожалуйста, подождите..." />
        : null}
      {BeforeNext
        ? (
          <BeforeNext
            isShow={state.showBeforeNext &&
            !notRespond &&
            !redirectError && message !== DEFAULT_ERROR_MESSAGE}
            onCancel={() => {
              setWasOpenSmsTooltip(true)
              setShowBeforeNext()
            }}
            onSubmit={() => {
              setShowBeforeNext();
              if (state.showBeforeNext) onSubmit();
            }}
          />
        )
        : null}
      {(notRespond || message === DEFAULT_ERROR_MESSAGE) && <ServiceError />}
      {redirectError && <UserError />}
    </S.FormComponent>
  );
}

export interface IFormProps {
  fields: FormField[];
  onSubmit: (formFields?: IFormFields) => void;
  title?: string;
  url: string;
  BeforeNext?: FC<IBeforeNext>;
  BeforeFields?: FC<IBeforeFields>;
  AfterFields?: FC<{}>;
  AfterHiddenFields?: FC<{}>;
  isInitialValid?: boolean;
  modals?: Modal[];
  submitTitle?: string;
  isModal?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  isLastForm?: boolean;
  disclaimer?: string | JSX.Element;
  hiddenSubmit?: boolean;
  formSubmit?: (formFields: IFormFields, formData: IPurchaseData) => IFormFields;
}
