import { combineReducers } from 'redux';

import { actionTypes } from 'store/actionTypes';
import { ISmsCheck, IAction, ISmsCheckError } from 'interfaces';

// eslint-disable-next-line import/no-cycle
import { requestInitialState } from './index';

const { CHECK } = actionTypes.PURCHASE.SMS;

const check = (
  state: ISmsCheck = { request: requestInitialState, error: { message: '' } },
  { type, payload }: IAction
): ISmsCheck => {
  const { SUCCESS, ERROR, REQUEST } = CHECK;

  switch (type) {
    case SUCCESS:
      return {
        ...state,
        request: {
          ...requestInitialState,
          success: true
        }
      };
    case actionTypes.PURCHASE.REDIRECT_ERROR:
      return {
        ...state,
        request: {
          ...requestInitialState,
          loading: false
        }
      };
    case ERROR:
      return {
        error: payload ? (payload as ISmsCheckError) : { message: '' },
        request: {
          ...requestInitialState,
          fail: true
        }
      };
    case REQUEST:
      return {
        ...state,
        request: {
          ...requestInitialState,
          loading: true
        }
      };
    case actionTypes.PURCHASE.OPIF.SUCCESS:
      return { request: requestInitialState, error: { message: '' } };
    default:
      return state;
  }
};

export const smsReducer = combineReducers({ check });
