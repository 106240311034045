import { Icon } from 'assets/img/svg';

import { ErrorGuest } from '../guest';
import { IEngineeringWorksErrorProps } from './interfaces';

import S from '../errorStyles';

export default ({ message }: IEngineeringWorksErrorProps) => {
  const {
    event,
    head,
    body,
    link
  } = message || {
    event: '',
    head: '',
    body: '',
    link: ''
  };

  return (
    <ErrorGuest data-test="engineering-works-guest-component">
      <S.Title>{event}</S.Title>
      <S.Title>{head}</S.Title>
      <S.Icon>
        <Icon.EngineeringWorks />
      </S.Icon>
      <S.Description>
        {body}
      </S.Description>
      <br />
      <br />
      {link && <a href={link} className="link" target="_blank" rel="noreferrer"> Подробнее </a>}
    </ErrorGuest>
  );
};
