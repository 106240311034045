import {
  takeLatest,
  put,
  call
} from 'redux-saga/effects';

import { api } from 'api';
import { actionTypes } from 'store/actionTypes';
import { Url } from 'api/url';
import { getPurchaseHeaders } from 'api/options';

import { host } from '../sendPurshaseData';
import { ICode, IResponse } from './interfaces';

const {
  NOT_RESPOND,
  REDIRECT_ERROR,
  SMS: { CHECK, SEND }
} = actionTypes.PURCHASE;

function* sendCode({ payload }: ICode) {
  const { ERROR, SUCCESS } = SEND;
  try {
    const { extraUrl } = payload;
    const params = {
      host,
      url: `${Url.purchase.sms.sendCode}/${extraUrl}`
    };
    yield call(api.post, params, getPurchaseHeaders());

    yield put({ type: SUCCESS });
  } catch ({ code, payload }) {
    if (code !== 500) {
      yield put({
        payload,
        type: ERROR
      });
    } else {
      yield put({
        type: NOT_RESPOND
      });
    }
  }
}

function* checkCode({ payload }: ICode) {
  const { SUCCESS, ERROR } = CHECK;
  try {
    const { code, extraUrl } = payload;
    const params = {
      host,
      url: `${Url.purchase.sms.checkCode}/${extraUrl}`,
      data: { code }
    };
    const result: IResponse = yield call(api.post, params, getPurchaseHeaders());

    if (extraUrl === 'edo') {
      yield call(api.get, { url: Url.purchase.edo.accept }, getPurchaseHeaders());
    }
    if (extraUrl === 'final') {
      const requisites = (result.files[6] === undefined ? result.files[5] : result.files[6]);
      yield put({
        type: actionTypes.CHECK_USER_EXIST.SUCCESS,
        payload: { order: { files: requisites } }
      });
    }

    yield put({ type: SUCCESS });
  } catch ({ code, message }) {
    if (code === 409) {
      yield put({
        type: REDIRECT_ERROR
      });
    } else if (code !== 500) {
      yield put({
        payload: { message },
        type: ERROR
      });
    } else {
      yield put({
        type: NOT_RESPOND
      });
    }
  }
}

export function* sms() {
  yield takeLatest(SEND.REQUEST, sendCode);
  yield takeLatest(CHECK.REQUEST, checkCode);
}
