import { useEffect, useState } from 'react';

import { INumberFieldProps } from './interfaces';

import S from './styles';

export const NumberField = ({
  value,
  type,
  colorize,
  hasSign
}: INumberFieldProps) => {
  const [formatValue, setFormatValue] = useState(value?.toString(type));
  /* todo useEffect без зависимостей */
  useEffect(() => {
    setFormatValue(value ? value.toString() : '');
  });
  return (
    <>
      {value
        ? (
          <S.NumberField
            type={type}
            value={value.get() || 0}
            data-test="number-field-component"
            colorize={colorize}
          >
            {hasSign && value.get() > 0 ? '+ ' : ''}
            {formatValue}
          </S.NumberField>)
        : ('')}
    </>
  );
};
