import { actionTypes } from 'store/actionTypes';
import { IOpifYieldState } from 'interfaces';

import { IActionOpifYield } from './interfaces';

export const opifYieldReducer = (
  state: IOpifYieldState = { isLoading: true, yields: [] },
  { type, payload }: IActionOpifYield
): IOpifYieldState => {
  switch (type) {
    case actionTypes.YIELD_INFO.SUCCESS: {
      return {
        isLoading: false,
        yields: [...payload]
      };
    }
    default:
      return state;
  }
};
