import styled from 'styled-components';

interface INumberFieldStyledProps {
  type: 'diff' | 'common' | undefined;
  value: number;
  colorize?: boolean;
}

const NumberField = styled.div<INumberFieldStyledProps>`
  font-size: 17px;
  color: ${({ colorize, value }) => {
    const depValue = value > 0 ? '#779C48' : '#424249'
    return colorize
      ? depValue
      : '#424249'
  }
}
`;

export default { NumberField };
