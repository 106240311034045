import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { IWizardForm } from 'interfaces';
import { FormField } from 'entities/formField';
import { useDidMount } from 'components/hooks/useDidMount';
import { actionTypes } from 'store/actionTypes';
import { useStore } from 'components/hooks/useStore';

// eslint-disable-next-line import/no-cycle
import { switchForEdo } from '../opif';
import { LoaderTooltip } from '../../tooltips/loader';
import { UserError } from '../error/userError';
import { ICheckComponentOwnProps } from './interfaces';

const checkExistField = new FormField({
  type: 'content',
  name: 'checkEdoExist',
  placeholder: '',
  initialValue: ''
}, {
  content: ({ nextForm }) => <CheckComponent nextForm={nextForm} />
});

export const checkEdoExistStatus: IWizardForm = {
  fields: [checkExistField],
  title: 'Поиск пользователя',
  url: '',
  hiddenSubmit: true
};

const CheckComponent = ({ nextForm }: ICheckComponentOwnProps) => {
  const dispatch = useDispatch()
  const checkingEdoExist = () => dispatch({ type: actionTypes.PURCHASE.EDO.CHECK_STATUS.REQUEST })
  useDidMount(checkingEdoExist);
  const {
    purchase: {
      checkEdo: {
        success,
        loading,
        error,
        edoExistStatus
      }
    }
  } = useStore()

  useEffect(() => {
    if (success && nextForm) {
      if (edoExistStatus) {
        switchForEdo();
      }
      nextForm();
    }
  }, [success, edoExistStatus]);
  return (
    <>
      {loading && <LoaderTooltip isShow description="Пожалуйста, подождите..." />}
      {error && <UserError />}
    </>);
};
