import { IWizardForm, IBeforeNext } from 'interfaces';
import { FormField } from 'entities/formField';
import { AvailableMasks } from 'constants/masks/masks';
import InputStyles from 'components/shared/input/styles';
import { formFields } from 'components/shared/formFields';

import { bankFields } from './bankFields';
import { EditUnverifiableData } from '../editUnverifiableData';
import { EditButton } from './editButton/editButton';
import { SmsBeforeNext } from '../SmsBeforeNext';
import { DescriptionCheckData } from './sms/description';

const { Description } = InputStyles;

const productName = new FormField(
  {
    name: 'productName',
    placeholder: 'Инвестиционный продукт',
    type: 'textarea',
    initialValue: ''
  },
  {
    getInitialValueFromState: true,
    likeTextField: true,
    invisible: true,
    groupTitle: 'Параметры инвестирования'
  }
);

const productId = new FormField(
  {
    name: 'purchase.productId',
    placeholder: '',
    type: 'text',
    initialValue: ''
  },
  {
    disabled: true,
    invisible: true,
    accumulateStateFields: ['productId']
  }
);

const investSum = new FormField(
  {
    name: 'purchase.price',
    placeholder: 'Сумма инвестирования',
    type: 'money',
    initialValue: '50000'
  },
  {
    groupTitle: 'Укажите сумму инвестирования'
  }
);

const PromoCodeDescription = (
  <Description style={{ marginLeft: 0, fontSize: '0.8em' }}>
    Если у Вас есть промокод, введите его в это поле
  </Description>
);

const promoCode = new FormField(
  {
    name: 'promoCode',
    placeholder: 'Промокод',
    type: 'text',
    initialValue: ''
  },
  {
    description: PromoCodeDescription
  }
);

const name = new FormField(
  {
    name: 'fio',
    placeholder: 'ФИО',
    initialValue: '',
    type: 'textarea'
  },
  {
    accumulateStateFields: ['lastName', 'firstName', 'patronymic'],
    likeTextField: true,
    groupTitle: 'Проверьте Ваши данные'
  }
);
const latinName = new FormField(
  {
    name: 'latinName',
    placeholder: '',
    initialValue: '',
    type: 'text'
  },
  {
    likeTextField: true,
    getInitialValueFromState: true
  }
);
const passport = new FormField(
  {
    name: 'passportSeriesNumber',
    placeholder: 'Паспорт',
    initialValue: '',
    type: 'text'
  },
  {
    accumulateStateFields: ['passport.series', 'passport.number'],
    mask: AvailableMasks.passport.mask,
    likeTextField: true
  }
);

const inn = new FormField(formFields.inn, {
  isRequired: false,
  likeTextField: true,
  getInitialValueFromState: true
});

const passportIssuesPlace = new FormField(
  {
    name: 'passport.issuesPlace',
    placeholder: 'Выдан',
    initialValue: '',
    type: 'textarea'
  },
  {
    getInitialValueFromState: true,
    likeTextField: true
  }
);

const passportIssuesDate = new FormField(formFields.passport.issuesDate, {
  getInitialValueFromState: true,
  likeTextField: true
});

const passportUnitCode = new FormField(formFields.passport.unitCode, {
  isRequired: false,
  getInitialValueFromState: true,
  likeTextField: true,
  mask: AvailableMasks.unitCode.mask
});

const dayBirth = new FormField(formFields.birthDetails.dayBirth, {
  likeTextField: true,
  getInitialValueFromState: true
});

const placeBirth = new FormField(formFields.birthDetails.placeBirth, {
  likeTextField: true,
  getInitialValueFromState: true
});

const address = new FormField(
  {
    ...formFields.address.addressString,
    type: 'textarea'
  },
  {
    likeTextField: true,
    getInitialValueFromState: true
  }
);

const checkboxPostAddress = new FormField({
  type: 'checkbox',
  name: 'postAddressCheck',
  initialValue: true,
  placeholder: 'Использовать адрес регистрации в качестве почтового'
});

const PostAddressDescription = (
  <Description style={{ marginLeft: 0, fontSize: '0.8em' }}>
    Например: Москва, Краснопресненская&nbsp;наб., д.&nbsp;12, кв.&nbsp;1
  </Description>
);

const postAddress = new FormField(
  {
    name: 'addressPost.addressString',
    type: 'textarea',
    placeholder: 'Почтовый адрес',
    initialValue: ''
  },
  {
    description: PostAddressDescription,
    withSuggestions: {
      type: 'address'
    },
    invisible: true,
    accumulateStateFields: ['addressRegistration.addressString'],
    onCheckboxToggle: (field) => {
      if (field.name === checkboxPostAddress.name) {
        postAddress.setModifier('invisible', !postAddress.get().invisible);
      }
    }
  }
);
/* todo нужно ли это? удалить? */
// const ScansDescription = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: flex-start;
// `;

// const passportScans = new FormField(
//   {
//     type: 'dropzone',
//     name: 'files',
//     placeholder: (
//       <ScansDescription>
//         <span>Перетащите или загрузите документы</span>
//         <br />
//         <span>JPG, PNG, PDF, не более 10 Мб</span>
//       </ScansDescription>
//     ),
//     initialValue: '2, 3 и 5-я страницы паспорта'
//   },
//   {
//     isRequired: true,
//     groupTitle: 'Загрузите сканы страниц паспорта',
//     groupDescription:
//       'Загрузите сканы или фото 2, 3 и 5-й страниц Вашего паспорта'
//   }
// );

const foreignerCheckbox = new FormField({
  name: 'foreigner',
  type: 'checkbox',
  placeholder: 'Являюсь иностранным налогоплательщиком',
  initialValue: false
});

const email = new FormField(formFields.contacts.email, {
  getInitialValueFromState: true,
  groupTitle: 'Контактные данные'
});

const phone = new FormField(formFields.contacts.phone, {
  isRequired: false,
  disabled: true,
  getInitialValueFromState: true,
  mask: AvailableMasks.phone
});
const edit = new FormField(
  {
    type: 'content',
    name: 'edit-button',
    initialValue: '',
    placeholder: ''
  },
  {
    content: () => <EditButton />
  }
);
const {
  bankName,
  bankBik,
  correspondentAccount,
  checkingAccount,
  bankPlace,
  bankPlaceType
} = bankFields;

export const SmsCheckData = (props: IBeforeNext) => (
  <SmsBeforeNext
    {...props}
    description={() => <DescriptionCheckData />}
    isAutoSend={false}
    type="final"
  />
);

export const checkData: IWizardForm = {
  title: 'Проверьте данные',
  modals: [(props) => <EditUnverifiableData {...props} />],
  fields: [
    productName,
    investSum,
    promoCode,
    name,
    latinName,
    dayBirth,
    placeBirth,
    passport,
    passportIssuesPlace,
    passportIssuesDate,
    passportUnitCode,
    bankPlace,
    bankPlaceType,
    inn,
    address,
    checkboxPostAddress,
    postAddress,
    edit,
    bankName,
    bankBik,
    correspondentAccount,
    checkingAccount,
    foreignerCheckbox,
    email,
    phone,
    productId
  ],
  url: 'purchase/step-one',
  BeforeNext: SmsCheckData
};
