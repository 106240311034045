import cloneDeep from 'lodash/cloneDeep';

import { setInitialValueFromState } from 'helpers/form/setInitialValueFromState';
import { FormField } from 'entities/formField';
import { formFields } from 'components/shared/formFields';
import passport from 'components/shared/formFields/passport';

import { contactDetailsFields } from '../contactDetails';

const name = cloneDeep(contactDetailsFields);

const {
  firstName,
  patronymic,
  lastName
} = name;

patronymic.setModifier('hint', undefined);
setInitialValueFromState(name);

const inn = new FormField(formFields.inn, {
  isRequired: true,
  mask: '999999999999',
  getInitialValueFromState: true
});

const seriesAndNumber = new FormField(passport.seriesAndNumber, {
  isRequired: true,
  mask: '9999 999999',
  accumulateStateFields: ['passport.series', 'passport.number']
});

export const editCheckedDataFields = {
  firstName,
  patronymic,
  lastName,
  inn,
  seriesAndNumber
};
