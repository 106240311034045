import { IMyOwnCron } from './interfaces';

export function myOwnCron({
  period,
  ticksCount,
  onTick,
  onStart,
  onFinish
}: IMyOwnCron) {
  let timerId = 0;
  let passedTicks = 0;

  function start() {
    timerId = window.setInterval(() => {
      tick();
    }, period);
    onStart(timerId);
  }
  function tick() {
    passedTicks += 1;
    if (passedTicks === ticksCount) {
      finish();
    } else {
      onTick(timerId, passedTicks);
    }
  }

  function finish() {
    clearInterval(timerId);
    onFinish();
  }

  start();
}
