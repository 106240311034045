import styled from 'styled-components';

import { media } from 'assets/styles/theme';
import { getAppeal } from 'helpers/form';
import { useUserOrProductInfo } from 'components/hooks/useUserOrProductInfo';
import { CommonTooltip, Styles } from 'components/shared';

import { BlankError } from '../../error';
import { IAcquiringErrorOwnProps } from './interfaces';

const Title = styled(Styles.H2)`
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  ${media.mobile} {
    margin-bottom: 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 29px;
    text-align: center;
  }
`;

const Text = styled(Styles.ExtraText)`
  margin-bottom: 44px;
  ${media.mobile} {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 40px;
  }
`;

export const AcquiringError = ({
  isShow,
  errorMessage,
  repeat
}: IAcquiringErrorOwnProps) => {
  const {
    firstName,
    lastName,
    patronymic,
    gender
  } = useUserOrProductInfo()
  const userName = `${firstName} ${patronymic || lastName}`;

  const appeal = getAppeal(gender, userName);

  const tooltipProps = {
    isShow,
    onClose: () => undefined
  };

  return (
    <CommonTooltip {...tooltipProps}>
      <BlankError>
        <Title>{appeal}</Title>
        <Text>{errorMessage}</Text>
        <Styles.ButtonLarge onClick={() => repeat()}>
          Попробовать еще раз
        </Styles.ButtonLarge>
      </BlankError>
    </CommonTooltip>
  );
};
