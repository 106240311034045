import { useState } from 'react';

import { Icon } from 'assets/img/svg';

import { animateScroll } from 'react-scroll';
import { DetailedInformation } from './detailedInformation';

import S from './styles';

export const Footer = () => {
  const [visibleDetail, setVisibleDetail] = useState(false);
  const { ArrowDown } = Icon;
  const year = (new Date()).getFullYear();
  return (
    <S.FooterStyle data-test="footer">
      <S.DataContainer>
        <S.TextContainer>
          <S.Text>
            123610, Россия, г.&nbsp;Москва, Краснопресненская набережная, д. 12, подъезд 7,
            помещение &#8544;аж, этаж 14, комнаты 40, 42, 43, 44
          </S.Text>
          <S.Text>© 2014-{year} ООО «УК ВЕЛЕС Менеджмент»</S.Text>
        </S.TextContainer>
        <S.TextContainer>
          <S.Wrapper>
            <S.Text x-ms-format-detection="none">
              +7 (495) 967-09-13, &nbsp;
            </S.Text>
            <S.Link
              href="mailto:online@veles-management.ru"
              data-test="header-email"
            >
              online@veles-management.ru
            </S.Link>
          </S.Wrapper>
          <S.Button onClick={() => {
            setVisibleDetail(!visibleDetail)
            animateScroll.scrollToBottom();
          }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <S.Link>
              Юридическая информация
            </S.Link>
            <ArrowDown />
          </S.Button>
        </S.TextContainer>
      </S.DataContainer>
      <DetailedInformation isShow={visibleDetail} />
    </S.FooterStyle>
  );
};
