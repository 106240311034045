import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';

import { getSuggestions } from 'helpers/dadata';
import { removeLastComma } from 'helpers/string';
import {
  IAddress,
  IFormFields,
  IPurchaseData
} from 'interfaces';

export enum addresses {
  registration = 'addressRegistration',
  post = 'addressPost'
}
export class PurchaseData {
  rawData: IFormFields;

  suggestionStatus: boolean;

  hiddenStatus?: boolean;

  // @ts-expect-error
  private data: IPurchaseData;

  constructor(fieldsData: IFormFields, hiddenStatus?: boolean) {
    this.rawData = fieldsData;
    this.set(fieldsData);
    this.suggestionStatus = false;
    this.hiddenStatus = hiddenStatus;
  }

  get(): IPurchaseData {
    return { ...this.data };
  }

  set(fieldsData: IFormFields): void {
    this.data = {
      ...this.data,
      ...fieldsData
    };
    this.setPassportFields();
  }

  getSuggestionStatus(): boolean {
    return this.suggestionStatus;
  }

  setSuggestionStatus(): boolean {
    return this.suggestionStatus = true;
  }

  async setAddress(changedAddress: addresses, stateData: IPurchaseData) {
    const rawAddress = this.rawData[changedAddress];
    const addressInState = stateData[changedAddress];

    // определяем, что поля такого адреса присутсвтуют в полях формы
    if (rawAddress) {
      // определяем, что этот адрес еще не находится в хранилище
      // if (addressInState && changedAddress !== addresses.post) {
      // Если адрес уже есть в сторе, то мерджим данные из стора с новыми данными,
      // с приоритетом новых данных
      const address: IAddress = this.mergeAddresses(addressInState, rawAddress);
      // }
      this.data[changedAddress] = await this.getFullAddress(address);
    }
    return this;
  }

  private async getFullAddress(rawAddress: IAddress): Promise<IAddress> {
    const address = await this.fillMissingAddressFields(rawAddress);

    return address || rawAddress;
  }

  private async fillMissingAddressFields(
    rawAddress: IAddress
  ): Promise<IAddress | undefined> {
    // получаем адрес из строки, если есть addressString,то из нее,
    // если нет, то из сформированной строки
    const value = !this.hiddenStatus
      ? rawAddress.addressString
      : this.getAddressStringFromGranulars(rawAddress);

    const addressSuggestions = await getSuggestions('address', { value });

    if (addressSuggestions[0]) {
      return addressSuggestions[0].payload.addressRegistration;
    }
    this.setSuggestionStatus();
    return undefined;
  }

  private getAddressStringFromGranulars(rawAddress: IAddress) {
    const {
      regionalDistrict,
      region,
      city,
      street,
      house
    } = rawAddress;
    const {
      number,
      flat,
      building,
      block
    } = house || {
      flat: '',
      building: '',
      number: '',
      block: ''
    };

    // tslint:disable-next-line:max-line-length
    const granularsString = `${strOrEmpty(region)} ${strOrEmpty(city)} ${strOrEmpty(regionalDistrict)} ${strOrEmpty(street)} ${strOrEmpty(number)} ${strOrEmpty(building)} ${strOrEmpty(block)} ${strOrEmpty(flat)}`;

    return removeLastComma(granularsString.trim());
  }

  private mergeAddresses(addressA: IAddress | undefined, addressB: IAddress): IAddress {
    return merge(addressA, addressB);
  }

  private setPassportFields(): void {
    if (this.rawData.passport) {
      if (this.rawData.passport.series_number) {
        const [series, number] = this.rawData.passport.series_number.split(' ');
        this.data = {
          ...this.data,
          passport: {
            ...this.data.passport,
            number,
            series
          }
        };
      }
    }
  }
}

const strOrEmpty = (data:string | undefined) => (isEmpty(data) ? '' : data);
