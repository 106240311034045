import { Component } from 'react';

import { getProperty } from 'dot-prop';
import size from 'lodash/size';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';

import { getFieldError } from 'helpers/form';
import { Styles } from 'components/shared';
import { Change } from 'types/inputEvent';
import { IField } from 'interfaces';

import { IComponentState, IProps } from './interfaces';

import S from '../styles';

export class Field extends Component<IProps, IComponentState> {
  state = {
    FieldComponent: this.props.field.getView(this.props.openHiddenFields),
    options: this.props.field.get()
  };

  componentDidUpdate() {
    this.updateOptions();
  }

  get value(): string {
    const { options: { name } } = this.state
    const { formProps: { values } } = this.props
    return getProperty(values, name);
  }

  get error(): string {
    const {
      formProps: { errors, touched },
      errors: purchaseErrors
    } = this.props
    const { options: { name } } = this.state
    return getFieldError(
      name,
      errors,
      touched,
      purchaseErrors
    );
  }

  updateOptions = () => {
    const { field, openHiddenFields } = this.props
    const options = field.get();
    const prevOptions = this.state.options;

    const diffOptions = getDiffOptions(options, prevOptions);
    if (size(diffOptions)) {
      const newOptions = reduce(diffOptions, (accumulate: IField, key) => {
        accumulate[key] = options[key];
        return accumulate;
      }, prevOptions);
      this.setState({
        options: newOptions,
        FieldComponent: field.getView(openHiddenFields)
      });
    }
  }

  handleChange = (event: Change) => {
    const {
      formProps: { handleChange },
      field,
      field: { type },
      onChangeCallback
    } = this.props
    if ((type === 'checkbox' || type === 'radio') && onChangeCallback) {
      onChangeCallback(field.get());
    }
    handleChange(event);
    const caret = event.target.selectionStart;
    const element = event.target;
    if (element.selectionStart && element.selectionEnd) {
      window.requestAnimationFrame(() => {
        element.selectionStart = caret;
        element.selectionEnd = caret;
      });
    }
  }

  render() {
    const {
      halfWidth,
      separatorAfter,
      disabled,
      groupTitle,
      groupDescription,
      invisible,
      styles
    } = this.state.options;
    const { FieldComponent } = this.state;
    const {
      formProps: {
        handleBlur,
        setFieldTouched,
        errors,
        submitForm
      },
      field: { name }
    } = this.props

    return (
      <>
        {!invisible && (
          <S.FieldContainer halfWidth={halfWidth}>
            {groupTitle &&
            <S.GroupTitle>
              {groupTitle}
            </S.GroupTitle>}
            {groupDescription &&
            <Styles.ExtraText>
              {groupDescription}
            </Styles.ExtraText>}
            <FieldComponent
              onChange={this.handleChange}
              onBlur={handleBlur}
              value={this.value}
              error={this.error}
              disabled={disabled}
              submitForm={submitForm}
              setFieldTouched={setFieldTouched}
              // @ts-expect-error
              fieldError={getProperty(
                errors,
                name
              )}
              styles={styles}
            />
            {separatorAfter && <S.Separator />}
          </S.FieldContainer>
        )}
      </>
    );
  }
}

function getDiffOptions(options: IField, prevOptions: IField): string[] {
  return reduce(keys(options), (accumulate: string[], key) => {
    if (options[key] !== prevOptions[key]) {
      accumulate.push(key);
    }
    return accumulate;
  }, []);
}
