import { useState } from 'react';

import { Icon } from 'assets/img/svg';
import { IInputProps } from 'interfaces';

import { TextInput } from '../text';
import { IInputPasswordProps } from './interfaces';

import S from './styles';

export const PasswordInput = (props: IInputProps & IInputPasswordProps) => {
  const [type, setType] = useState(true);
  const { onClickRestore } = props;

  return (
    <S.InputPassword className={onClickRestore ? 'with-restore' : ''}>
      <TextInput {...props} type={type ? 'password' : 'text'} />
      {onClickRestore
        ? <S.Restore onClick={() => onClickRestore()}>Забыли пароль?</S.Restore>
        : null}
      <S.Payload>
        <button onClick={() => setType(!type)} type="button" tabIndex={0}>
          <S.Icon>
            {type
              ? <Icon.EyeOpen />
              : <Icon.EyeClose />}
          </S.Icon>
        </button>
      </S.Payload>
    </S.InputPassword>
  );
};
