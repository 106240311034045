import { useNavigate } from 'react-router';

import { Icon } from 'assets/img/svg';

import { ErrorGuest } from '../guest';

import S from '../errorStyles';

export default () => {
  const navigate = useNavigate()
  const goBack = () => navigate(-1);

  return (
    <ErrorGuest>
      <S.Error data-test="not-found-component">
        <Icon.NotFound />
        <S.Title>Ошибка 404, страница не найдена</S.Title>
        <S.Description>
          Сообщение об ошибке уже отправлено администраторам.
        </S.Description>
        <S.Link>
          <button type="button" onClick={goBack}>Вернуться назад</button>
        </S.Link>
      </S.Error>
    </ErrorGuest>
  );
}
