import { ReactNode } from 'react';

import Axios, { AxiosResponse } from 'axios';

import { host } from './url';
import { getHeaders } from './options';
import { errorHandler } from '../helpers/api/errors';

const objToUri = (params: string | { [key: string]: string; }) => Object.keys(params)
  .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
  .join('&');

export interface IRequestParams {
  url: string;
  data?: {
    [key: string]: ReactNode | undefined
  };
  urlParams?: { [key: string]: string } | string;
  host?: string;
}

interface IRequestParamsWithMethod extends IRequestParams {
  method: 'post' | 'get' | 'patch';
}
interface IHeaders {
  [key: string]: boolean | string,
}

class Api {
  public get = (params: IRequestParams, headers?: IHeaders) => this.request({ ...params, method: 'get' }, headers)

  public post = (params: IRequestParams, headers?: IHeaders) => this.request({ ...params, method: 'post' }, headers)

  public patch = (params: IRequestParams) => this.request({ ...params, method: 'patch' })

  public request = async (params: IRequestParamsWithMethod, headers?: IHeaders) => {
    const urlParams = params.urlParams ? `${objToUri(params.urlParams)}` : '';
    try {
      const response: AxiosResponse = await Axios({
        ...params,
        headers: { ...(await getHeaders()), ...headers },
        url: `${params.host || host}${params.url}?${urlParams}`
      });
      return response.data;
    } catch (error) {
      throw errorHandler(error);
    }
  }
}

export const api = new Api();
