import styled from 'styled-components';

import StylesPopup from '../../popup/styles';

const { Popup } = StylesPopup;

const PatronymicHint = styled.div`
  width: 20px;
  height: 20px;
  ${Popup} {
    width: 262px;
    bottom: 35px;
    right: 0;
  }
`;
export default { PatronymicHint };
