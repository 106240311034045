import { IAction } from 'interfaces';
import { IPurchaseData } from 'interfaces/purchase';
import { PayloadTypes } from './interfaces';

export const actionCreator = (
  type: string,
  payload?: PayloadTypes
): IAction<PayloadTypes> => {
  const action: { type:string, payload?: string | PayloadTypes } = { type };
  if (payload) {
    action.payload = payload;
  }
  return action;
};

export const purchaseAction = (
  type: string,
  url: string,
  payload?: { data: IPurchaseData; requestId: string },
  withoutFormatting?:boolean
) => ({
  type,
  url,
  payload,
  withoutFormatting
});
