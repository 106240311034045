import styled from 'styled-components';

import { InputPayloadPosition } from 'assets/styles/mixins';
import { media } from 'assets/styles/theme';

import StylesDatepicker from '../../datepicker/styles';

const { Datepicker } = StylesDatepicker;

const CalendarButton = styled.button`
  ${InputPayloadPosition}
`;

const DateInput = styled.div`
  position: relative;
  ${Datepicker} {
    right: 0;
    top: calc(100% + 16px);

    ${media.mobile} {
      left: 0;
    }
  }
`;

export default { DateInput, CalendarButton };
