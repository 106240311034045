import size from 'lodash/size';

import { IAction } from 'interfaces';
// eslint-disable-next-line import/no-cycle
import { IMultiStepWizardState } from '../interfaces';
import { initialState, IStepForm } from './interfaces';

export const multiStepReducer = (
  state: IMultiStepWizardState = initialState,
  action: IAction<{ step: number; form: number; isInitialValid?: boolean }>
): IMultiStepWizardState => {
  const { forms, step, form } = state;
  const stepsCount = size(forms);
  const formsCount = size(forms[step]);
  switch (action.type) {
    case 'NEXT': {
      const params = getNextState({ ...state }, stepsCount, formsCount);
      const _maxParams = getMaxParams(state._maxParams, params);
      const isInitialValid = compareMaxParams(_maxParams, params);
      return {
        forms,
        _maxParams,
        isInitialValid,
        step: params.step,
        form: params.form
      };
    }
    case 'BACK': {
      const prevStep = form === 0 ? 0 : step;
      const prevFormsCount = step === 0 ? formsCount : size(forms[step - 1]);
      const prevForm = form === 0 ? prevFormsCount - 1 : form - 1;

      return {
        ...state,
        step: prevStep,
        form: prevForm,
        isInitialValid: true
      };
    }
    case 'SET_INITIAL_VALID': {
      if (action.payload && action.payload.isInitialValid) {
        return {
          ...state,
          isInitialValid: true
        };
      }
      return state;
    }
    case 'GOTO': {
      if (action.payload) {
        const params = { step: action.payload.step, form: action.payload.form };
        const _maxParams = getMaxParams(state._maxParams, params);
        const isInitialValid = compareMaxParams(_maxParams, params);
        return {
          ...params,
          forms,
          _maxParams,
          isInitialValid
        };
      }
      return state;
    }
    default:
      return state;
  }
};

const getNextState = (
  state: IMultiStepWizardState,
  stepsCount: number,
  formsCount: number
): IStepForm => {
  const { form, step } = state;
  if (isLastForm(state, stepsCount, formsCount)) {
    return state;
  }
  return form === formsCount - 1
    ? getNextStep(step)
    : getNextForm(state);
};

const isLastForm = (
  state: IMultiStepWizardState,
  stepsCount: number,
  formsCount: number
): boolean => {
  const { form: currentForm, step: currentStep } = state;
  return currentStep + 1 === stepsCount && currentForm + 1 === formsCount;
};

const getNextForm = ({
  form: currentForm,
  step: currentStep
}: IMultiStepWizardState): IStepForm => ({
  step: currentStep,
  form: currentForm + 1
});

const getNextStep = (step: number): IStepForm => ({
  step: step + 1,
  form: 0
});

function getMaxParams(maxParams: IStepForm, params: IStepForm): IStepForm {
  let newMaxParams = maxParams;
  if (maxParams.step === params.step) {
    if (params.form > maxParams.form) {
      newMaxParams.form = params.form;
    }
  }
  if (params.step > maxParams.step) {
    newMaxParams = params;
  }
  return newMaxParams;
}

function compareMaxParams(a: IStepForm, b: IStepForm): boolean {
  const { step: aStep, form: aForm } = a;
  const { step: bStep, form: bForm } = b;

  if (aStep > bStep) {
    return true;
  }
  if (aStep === bStep) {
    if (aForm > bForm) {
      return true;
    }
  }
  return false;
}
