import styled from 'styled-components';

const SidebarItem = styled('li')`
  list-style-type: none;
  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 51px;
    cursor: pointer;
    position: relative;
    margin-bottom: 6px;
    &:hover {
      &:before {
        background-color: ${({ theme }) => theme.colors.buttonPrimary};
      }
      svg {
        * {
          fill: ${({ theme }) => theme.colors.buttonPrimary};
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: "";
      position: absolute;
      left: -30px;
      height: 100%;
      width: 4px;
    }
    span {
      color: #ffffff;
      font-size: 17px;
      letter-spacing: 0.07px;
      line-height: 20px;
      margin-left: 13px;
    }
    &.active {
      cursor: default;
      svg {
        * {
          fill: ${({ theme }) => theme.colors.buttonPrimary};
        }
      }
      &:before {
        background-color: ${({ theme }) => theme.colors.buttonPrimary};
      }
    }
  }
`;

export default { SidebarItem };
