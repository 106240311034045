import map from 'lodash/map';

import { ISuggestionResponse } from 'helpers/dadata';
import { Styles } from 'components/shared';

import S from './styles';

interface IOwnProps {
  suggestions: ISuggestionResponse[];
  onClick: (suggestion: ISuggestionResponse) => void;
  active: number;
  setActive: (index: number) => void;
  isShow?: boolean;
}

export const Suggestions = ({
  suggestions,
  onClick,
  isShow,
  active,
  setActive
}: IOwnProps) => {
  const handleClick = (suggestion: ISuggestionResponse, index: number) => {
    onClick(suggestion);
    setActive(index);
  };
  return (
    <S.Suggestions isShow={isShow}>
      {map(suggestions, ((suggestion, index) => {
        const { textSuggestion } = suggestion;
        if (suggestion && textSuggestion) {
          return (
            <S.Suggestion
              key={`${suggestion} ${index}`}
              onClick={() => handleClick(suggestion, index)}
              onMouseEnter={() => setActive(index)}
              isActive={active === index}
            >
              <Styles.ExtraText>{textSuggestion}</Styles.ExtraText>
            </S.Suggestion>
          );
        }
        return null;
      }))}
    </S.Suggestions>
  );
};
