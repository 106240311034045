import styled, { css } from 'styled-components';

import { media } from 'assets/styles/theme';

import StylesSidebar from '../sidebar/styles';

const Phone = styled.a`
  font-size: 17px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textPrimary};
  text-decoration: none;
  cursor: pointer;
`;

const Back = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 12px;

    ${media.tablet} {
      display: none;
    }
  }
`;
interface IHeaderProps {
  isMenuActive?: boolean
}

const Header = styled.header<IHeaderProps>`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isMenuActive }) => (isMenuActive &&
    css`
      position: fixed;
      z-index: 3;
        ${media.desktop} {
          position: relative;
        }
    `
  )}

  ${media.desktop} {
    height: 100px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  .logo {
    ${media.tablet} {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-right: auto;
      overflow: hidden;

      svg {
        margin-left: -70px;
      }
    }
  }

  ${Phone} {
    margin-left: 80px;
    margin-right: 67px;

    ${media.tablet} {
      display: none;
    }
  }

  ${Back} {
    margin-left: 50px;

    ${media.tablet} {
      border: 1px solid ${({ theme }) => theme.colors.border};
      border-radius: ${({ theme }) => theme.borderRadius};
      background: ${({ theme }) => theme.colors.white};
      padding: 7px 11px;
      cursor: pointer;
      transition-property: background, color;
      transition-duration: 0.35s;

      :focus,
      :hover {
        background: #ff9a26;
      }

      a {
        color: ${({ theme }) => theme.colors.textPrimary};
        font-weight: 500;
        font-size: 12px;
        text-decoration: none;
      }
    }
  }

  ${StylesSidebar.Sidebar} {
    ${media.desktop} {
      display: none;
    }
  }
`;

const Container = styled.div`
  width: 1170px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;

  ${media.tablet} {
    padding: 8px 15px;

    & > .email {
      display: none;
    }
  }
`;

export default {
  Header,
  Back,
  Phone,
  Container
};
