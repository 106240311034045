import { FormField } from 'entities/formField';

const genderChoose = new FormField(
  {
    name: 'gender',
    type: 'radio',
    placeholder: 'Мужской',
    initialValue: ''
  },
  {
    separatorAfter: false,
    radioButtons: [
      {
        label: 'Мужской',
        value: 'MALE'
      },
      {
        label: 'Женский',
        value: 'FEMALE'
      }
    ],
    description: 'Укажите ваш пол:'
  }
);

export default genderChoose;
