import { useLayoutEffect } from 'react';

function useLockBodyScroll(isShow: boolean) {
  useLayoutEffect(() => {
    if (isShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isShow]);
}

export { useLockBodyScroll };
