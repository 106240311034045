import { useState, useEffect } from 'react';

import InputMask, { BeforeMaskedStateChangeStates, InputState } from 'react-input-mask';

import { IInputProps } from 'interfaces';
import { getFakeEvent } from 'helpers/field/getFakeEvent';
import { Change } from 'types/inputEvent';
import { IMask } from 'constants/masks/interfaces';

import { TextField } from '../textField';

import S from '../styles';

export const TextInput = (props: IInputProps) => {
  const {
    placeholder,
    name,
    type,
    value,
    disabled,
    description,
    error,
    autoComplete,
    hint,
    likeTextField,
    hidden,
    autoFocus,
    styles,
    mask,
    maskChar,
    onBlur,
    onFocus,
    onChange,
    onKeyDown
  } = props;

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (error) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [error]);

  const id = `input-${placeholder}`;
  const descriptionText = description
    ? <S.Description data-test="input-description">{description}</S.Description>
    : null;
  const maskProps = {
    maskPlaceholder: maskChar,
    mask: getMask(mask),
  };

  const inputProps = {
    id,
    type,
    name,
    onKeyDown,
    autoFocus,
    autoComplete,
    disabled: !!(likeTextField || disabled),
    value: value || '',
    onChange: onChange || mockHandler,
    onBlur: onBlur || mockHandler,
    onFocus: onFocus || mockHandler
  };

  function mockHandler() {
    return undefined;
  }
  function handleChange(event: Change) {
    if (onChange) {
      if (mask && typeof mask !== 'string') {
        const unmaskedValue = mask.getUnmaskedValue(event.target.value);
        onChange(getFakeEvent(unmaskedValue, name || '') as Change);
      } else {
        onChange(event);
      }
    }
  }

  const input = (
    // @ts-expect-error
    <InputMask
        {...inputProps}
        {...maskProps}
        onChange={handleChange}
        autoComplete="new-password"
    />
  );
  return (
    <>
      {!likeTextField
        ? (
          <S.Input hasError={hasError} hidden={hidden} style={styles}>
            {input}
            <label htmlFor={id}>{placeholder}</label>
            <S.Info>
              {String(error).trim() ? null : descriptionText}
              <S.Error hasError={!!error}>{error}</S.Error>
              <S.Hint>{hint}</S.Hint>
            </S.Info>
          </S.Input>)
        : <TextFieldMaskedInput {...props} styles={styles} autoComplete="new-password" />}
    </>
  );
};

const TextFieldMaskedInput = ({
  value,
  onChange,
  mask,
  placeholder
}: IInputProps) => {
  const [maskedValue, setMaskedValue] = useState('');
  function beforeMaskedValueChange(nextState: InputState) {
    const { value } = nextState;
    setMaskedValue(value);
    return nextState;
  }
  return (
    <>
      <InputMask
        value={value || ''}
        beforeMaskedStateChange={({ nextState }:BeforeMaskedStateChangeStates) => {
          return beforeMaskedValueChange(nextState)
        }}
        onChange={onChange}
        style={{ display: 'none' }}
        mask={getMask(mask)}
      />
      <TextField
        value={maskedValue || value}
        placeholder={placeholder}
      />
    </>
  );
};

function getMask(possibleMask?: string | IMask) {
  if (possibleMask) {
    if (typeof possibleMask === 'string') {
      return possibleMask;
    }
    return possibleMask.mask;
  }
  return '';
}
