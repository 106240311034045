import styled from 'styled-components';

import StylesInput from 'components/shared/input/styles';
import passwordStyles from 'components/shared/input/password/styles';
import { Styles } from 'components/shared';
import { media } from 'assets/styles/theme';

const { Input } = StylesInput;
const Form = styled.form``;
const LoginPanel = styled(Styles.Panel)`
  width: 100%;
  max-width: 570px;
  height: 520px;
  padding: 57px 0 67px 0;

  ${media.tablet} {
    max-width: 342px;
    height: auto;
    align-self: flex-start;
    padding: 29px 26px 37px;

    h1 {
      font-size: 18px;
      text-align: center;
    }
  }

  ${media.mobile} {
    max-width: none;
    padding: 30px 15px;

    h1 {
      font-size: 17px;
    }
  }

  ${Form} {

    margin-top: 52px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    ${media.tablet} {
      margin-top: 30px;
    }

    ${media.mobile} {
      max-width: none;
    }
  }

  ${Input} {
    margin-bottom: 50px;

    ${media.tablet} {
      margin-bottom: 68px;
    }

  }

  ${passwordStyles.InputPassword} {
    ${Input} {
      ${media.tablet} {
        margin-bottom: 27px;
      }
    }
  }
  button {
    margin: 0;

    ${media.tablet} {
      width: 100%;
    }
  }
`;

const RowButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.mobile} {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  ${Styles.Button} {
    height: 52px;
    width: 118px;
    padding: 0;
    justify-content: center;
    align-items: center;

    ${media.mobile} {
      width: 100%;
    }

    & + button {
      margin-left: 20px;

      ${media.mobile} {
        margin-left: 0;
        margin-bottom: 15px;
      }
    }
  }
`;

export default {
  LoginPanel,
  Form,
  RowButtons
};
