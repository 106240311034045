import { CommonTooltip } from 'components/shared';

import { IOwnProps } from './interfaces';

import S from './styles';

export const BlankError = ({ children }: IOwnProps) => (
  <S.ServiceError>
    <CommonTooltip isShow onClose={() => undefined}>
      <>
        <S.Content>{children}</S.Content>
      </>
    </CommonTooltip>
  </S.ServiceError>
);
