// eslint-disable-next-line import/no-cycle
import { Styles } from '..';
import { IPopupProps } from './interfaces';

import S from './styles';

export const Popup = ({ children }: IPopupProps) => (
  <S.Popup>
    <Styles.ExtraText>{children}</Styles.ExtraText>{' '}
  </S.Popup>
);
export const StylesPopup = S.Popup;
