import {
  call,
  takeLatest,
  put
} from 'redux-saga/effects';

import { actionTypes } from 'store/actionTypes';
import { api } from 'api';
import { getPurchaseHeaders } from 'api/options';
import { Url } from 'api/url';

const { USER_REGISTRATION } = actionTypes.PURCHASE;

function* userRegister() {
  const { SUCCESS, ERROR } = USER_REGISTRATION;
  try {
    yield call(
      api.get,
      { url: Url.purchase.userRegistration },
      getPurchaseHeaders()
    );

    yield put({ type: SUCCESS });
  } catch (error) {
    yield put({ payload: error.payload, type: ERROR });
  }
}

export function* userRegisterAction() {
  yield takeLatest(USER_REGISTRATION.REQUEST, userRegister);
}
