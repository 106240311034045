import map from 'lodash/map';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';

import { useStore } from 'components/hooks/useStore';
import { Styles } from 'components/shared/commonStyles';

import { media } from 'assets/styles/theme';
import { notesInfo } from './notesInfo';
import S from '../../pages/products/styles';

const { LinkText } = Styles

const BoldParagraph = styled(S.Paragraph)`
  font-weight: 500;
  font-size: 20px;

  ${media.mobile} {
    font-size: 16px;
  }
`
const Link = styled(LinkText)`
  text-decoration: none;
  cursor: pointer;
  font-size: 13px;
  
  :hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.underline};
  }
`

export const Notes = () => {
  const { opifYield: { isLoading } } = useStore()

  if (isLoading) return null;

  return (
    <>
      {map(notesInfo, (text) => <S.Paragraph key={uuid()}>{text}</S.Paragraph>)}
      <S.Paragraph>
        ООО «УК ВЕЛЕС Менеджмент» (Лицензия на осуществление деятельности по управлению
        инвестиционными фондами, паевыми инвестиционными фондами и негосударственными пенсионными
        фондами № 21-000-1-00656 от 15 сентября 2009 года выдана ФСФР России, без ограничения
        срока действия. Лицензия профессионального участника рынка ценных бумаг на осуществление
        деятельности по управлению ценными бумагами № 045-14068-001000 от 25 октября 2018 года
        выдана ЦБ РФ (Банк России), без ограничения срока действия.) получить подробную информацию,
        подлежащую раскрытию и предоставлению в соответствии с Федеральным законом от 29.11.2001
        N 156-ФЗ «Об инвестиционных фондах», можно по адресу: 123610, г. Москва,
        вн. тер. г. муниципальный округ Пресненский, Краснопресненская наб., д. 12, под. 7,
        пом. Iaж, эт. 14, ком. 40, 42, 43, 44, по телефону:
        + 7 (495) 967-09-13 или в сети Интернет по адресу: <Link href="https://veles-management.ru/" target="_blank">veles-management.ru.</Link>
      </S.Paragraph>
      <BoldParagraph>
        Стоимость инвестиционных паев может, как увеличиваться, так и уменьшаться.
        Результаты инвестирования в прошлом не определяют доходы в будущем.
        Государство не гарантирует доходность инвестиций в паевые инвестиционные фонды.
        Прежде чем приобрести инвестиционный пай, следует внимательно ознакомиться с
        правилами доверительного управления фондом.
      </BoldParagraph>
      <S.Paragraph>
        Закрытый паевой инвестиционный фонд рыночных финансовых инструментов «ВЕЛЕС – Системный».
        Правила доверительного управления фондом зарегистрированы ЦБ РФ (Банком России)
        25.07.2019 года за № 3782.
      </S.Paragraph>
    </>
  );
};
