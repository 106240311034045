import { combineReducers } from 'redux';

import { recommendedProductsReducer } from './recommendedProducts';
import { purchaseReducer } from './purchase';
import { viewReducer } from './view';
import { opifYieldReducer } from './opifYield';

const rootReducer = combineReducers({
  recommendedProducts: recommendedProductsReducer,
  purchase: purchaseReducer,
  view: viewReducer,
  opifYield: opifYieldReducer
});

export default rootReducer;
