import { getProperty } from 'dot-prop';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';

import { IWizardForm, IDotPropObject } from 'interfaces';
import { FormField } from 'entities/formField';
import { recursiveLoop } from 'helpers/recursive';

import { passportDetailsField } from '../passportDetails';
import { innAndPassportNumberFields } from '../innAndPassportNumber';
import { contactDetailsFields } from '../contactDetails';
import { Terrorizm } from '../terrorizm';

const {
  firstName,
  patronymic,
  lastName,
  email,
  phone,
  gender,
  agree
} = cloneDeep(contactDetailsFields);

const { inn, seriesAndNumber } = innAndPassportNumberFields;

const {
  placeBirth,
  dayBirth,
  unitCode,
  issuesDate,
  issuesPlace,
  addressString,
  country,
  postCode,
  region,
  regionalDistrict,
  city,
  street,
  number,
  building,
  block,
  flat
} = passportDetailsField;

firstName.setModifier('disabled', false)
lastName.setModifier('disabled', false)
patronymic.setModifier('disabled', false)
email.setModifier('disabled', false)
phone.setModifier('disabled', false)
agree.setModifier('disabled', false)
gender.setModifier('disabled', false)

const allFields = {
  gender,
  firstName,
  patronymic,
  lastName,
  email,
  phone,
  inn,
  dayBirth,
  placeBirth,
  passport: {
    issuesDate,
    issuesPlace,
    unitCode,
    seriesAndNumber
  },
  addressRegistration: {
    addressString,
    country,
    region,
    regionalDistrict,
    city,
    street,
    postcode: postCode,
    house: {
      number,
      building,
      block,
      flat
    }
  }
};

export function getEsiaForm(fields: IDotPropObject): IWizardForm {
  const nameEmptyFields = getNameEmptyFields(fields);

  const esiaFields: Set<FormField> = getFieldByName(nameEmptyFields);
  const valueGender = getProperty(fields, 'gender');

  if (!valueGender) {
    gender.initialValue = 'MALE';
  }

  agree.setModifier('itLastField', true);
  esiaFields.add(agree);

  return {
    title: 'Заполните недостающие данные',
    fields: Array.from(esiaFields),
    BeforeNext: (props) => <Terrorizm {...props} />,
    url: 'esia-bridge/dynamic-addition-data'
  };
}

function getNameEmptyFields(fields: IDotPropObject): string[] {
  const nameEmptyFields: string[] = [];

  recursiveLoop(fields, (fieldName) => {
    const value = getProperty(fields, fieldName);

    if (!value) {
      if (fieldName === 'gender' && nameEmptyFields.length >= 5) {
        nameEmptyFields.splice(5, 0, fieldName);
      } else {
        nameEmptyFields.push(fieldName);
      }
    }

    if (fieldName === 'addressRegistration.country') {
      nameEmptyFields.push(fieldName);
    }
  });
  return nameEmptyFields;
}

function getFieldByName(fieldNames: string[]): Set<FormField> {
  const emptyFields: Set<FormField> = new Set();

  forEach(fieldNames, (name) => {
    // @ts-expect-error
    const field = getProperty<FormField>(allFields, name);

    if (field) {
      emptyFields.add(field);
    }
    const seriesAndNumberField = getSeriesNumberField(name);
    if (seriesAndNumberField) {
      emptyFields.add(seriesAndNumberField);
    }
  });
  return emptyFields;
}

function getSeriesNumberField(fieldName: string): FormField | void {
  if (isFieldSeriesOrNumber(fieldName)) {
    return seriesAndNumber;
  }
}

function isFieldSeriesOrNumber(fieldName: string): boolean {
  const seriesName = 'passport.series';
  const numberName = 'passport.number';
  return fieldName === seriesName || fieldName === numberName;
}
