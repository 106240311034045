import { useEffect } from 'react';

import classNames from 'classnames';

import { Feedback } from './sections';
import { IProps } from './interfaces';

import S from './styles';

export const Sidebar = ({ isActive, isMobileCompact }: IProps) => {
  /* в зависимости от состояния sidebar меняем scroll */
  // @ts-expect-error
  useEffect(() => {
    if (isActive) {
      return document.body.style.overflow = 'hidden'
    }
    return document.body.style.overflow = 'visible'
  }, [isActive])
  return (
    <S.Sidebar
        collapse={false}
        className={classNames({
          active: isActive,
          mobileCompact: isMobileCompact
        })}
    >
      <Feedback />
    </S.Sidebar>
  )
}
