import { combineReducers } from 'redux';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

import { actionTypes } from 'store/actionTypes';
import { getProductLink, getProductRequisites } from 'staticContent/getProductLink';
import {
  IAction,
  IPassportCheck,
  IPurchaseData,
  IPurchaseErrors,
  IRequest,
  IEsiaAuth,
  QuestionnaireTypes,
  IAddress,
  ICheckUserExist,
  TBuyResponse
} from 'interfaces';

import { userRegistration } from './userRegistration';
// eslint-disable-next-line import/no-cycle
import { smsReducer } from './sms';

export interface IPurchaseAction {
  type: string;
  payload?: IPurchaseErrors | IPurchaseData;
  url: string;
  withoutFormatting?: boolean;
}

interface IEdoExistStatus {
  type: string;
  payload: boolean;
}

const {
  SUCCESS,
  ERROR,
  REQUEST
} = actionTypes.PURCHASE.OPIF;
const { PASSPORT_CHECK, RESET_STATE } = actionTypes.PURCHASE;

const initialAddress: IAddress = {
  addressString: '',
  country: 'Россия',
  postcode: '',
  region: '',
  regionalDistrict: '',
  city: '',
  street: '',
  house: {
    number: '',
    building: '',
    block: '',
    flat: ''
  }
};

const initialState: IPurchaseData = {
  firstName: '',
  productName: '',
  purchase: {
    price: 0,
    productId: ''
  },
  inn: '',
  lastName: '',
  latinName: '',
  email: '',
  phone: '',
  dayBirth: '',
  placeBirth: '',
  passport: {
    series: '',
    number: '',
    issuesPlace: '',
    issuesDate: '',
    unitCode: ''
  },
  addressRegistration: initialAddress,
  addressPost: initialAddress,
  addressActual: initialAddress,
  gender: '',
  requisites: {
    cardNumber: '',
    bankName: '',
    rs: '',
    ks: '',
    bik: '',
    bankPlace: 'not found',
    bankPlaceType: 'not found',
    cardholderName: 'not found'
  }
};
const data = (
  state: IPurchaseData = initialState,
  action: IAction
): IPurchaseData => {
  const { ESIA } = actionTypes;
  const { type, payload } = action;
  switch (type) {
    case ESIA.GET_USER.SUCCESS: {
      const { payload } = action as IAction<{ data: IPurchaseData }>;
      if (payload) {
        return payload.data;
      }
      return state;
    }
    case RESET_STATE: {
      return {
        ...initialState,
        productName: state.productName
      };
    }
    case PASSPORT_CHECK.SEND.SUCCESS:
    case actionTypes.PURCHASE.SET_ADDRESS:
    case actionTypes.PURCHASE.SET_PARAMS:
    case SUCCESS: {
      if (payload) {
        const { data } = payload as { data: IPurchaseData };
        const productName = data.productName ? data.productName.trim() : '';
        const productDocs = state.productDocs || getProductLink(productName);
        const productRequisites = state.productRequisites || getProductRequisites(productName);
        data.productDocs = productDocs || data.productDocs;
        data.productName = productName || data.productName;
        data.productRequisites = productRequisites || '';
        return merge(cloneDeep(state), data);
      }
      return state;
    }
    // @ts-expect-error
    case actionTypes.PURCHASE.USER_DOCS.GET.SUCCESS: {
      if (payload) {
        return merge(cloneDeep(state), { userDocs: payload });
      }
    }
    case actionTypes.CHECK_USER_EXIST.SUCCESS: {
      if (payload) {
        const { order, applicantId } = payload as {
          order: TBuyResponse;
          applicantId: string;
        };
        return merge(cloneDeep(state), { order, applicantId });
      }
      return state;
    }
    default:
      return state;
  }
};
/* Todo зачем этот редьюсер? */
const errors = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  state: IPurchaseErrors = {},
  action: IAction<IPurchaseErrors>
): IPurchaseErrors => {
  const { type, payload } = action;
  switch (type) {
    case ERROR: {
      return payload || {};
    }
    default:
      return {};
  }
};

export const requestInitialState: IRequest = {
  success: false,
  fail: false,
  loading: false
};
const request = (
  state: IRequest = requestInitialState,
  action: IAction<{ requestId: string }>
): IRequest => {
  const { type, payload } = action;

  const initialState = {
    ...requestInitialState,
    requestId: payload ? payload.requestId : ''
  };

  switch (type) {
    case SUCCESS:
      return {
        ...initialState,
        success: true
      };
    case REQUEST:
      return {
        ...initialState,
        loading: true
      };
    case ERROR:
      return {
        ...initialState,
        fail: true
      };
    default:
      return state;
  }
};

const passportCheck = (
  state: IPassportCheck = { state: '' },
  action: IAction<IPassportCheck>
): IPassportCheck => {
  const {
    SUCCESS,
    ERROR,
    REQUEST
  } = actionTypes.PURCHASE.PASSPORT_CHECK;
  switch (action.type) {
    case SUCCESS: {
      if (action.payload) {
        return action.payload;
      }
      return state;
    }
    case ERROR: {
      return { state: 'error' };
    }
    case REQUEST: {
      return { state: '' };
    }
    default:
      return state;
  }
};

/* Todo зачем этот редьюсер? */
const type = (
  state: QuestionnaireTypes = '',
  action: IAction<{ data: { type: QuestionnaireTypes } }>
): QuestionnaireTypes => {
  switch (action.type) {
    case actionTypes.PURCHASE.SET_PARAMS:
      return action.payload ? action.payload.data.type : state;
    default: {
      return state;
    }
  }
};

const esiaAuth = (
  state = null,
  action: IAction<{ esiaAuth: IEsiaAuth }>
): null | IEsiaAuth => {
  switch (action.type) {
    // @ts-expect-error
    case actionTypes.ESIA.GET_USER.SUCCESS: {
      if (action.payload) {
        return action.payload.esiaAuth;
      }
    }
    default:
      return state;
  }
};

const notRespond = (state = false, { type }: IAction): boolean => {
  if (type === actionTypes.PURCHASE.NOT_RESPOND) return true;
  if (type === actionTypes.PURCHASE.RESET_NOT_RESPOND) return false;
  return state;
};

const redirectError = (state = false, { type }: IAction): boolean => {
  if (type === actionTypes.PURCHASE.REDIRECT_ERROR) return true;
  return state;
};

const terroristStatus = (
  state: boolean | null = null,
  { type }: IAction
): boolean | null => {
  const { ERROR, SUCCESS, REQUEST } = actionTypes.PURCHASE.CHECK_TERRORIZM;
  switch (type) {
    case ERROR:
      return false;
    case SUCCESS:
      return true;
    case REQUEST:
      return null;
    default:
      return state;
  }
};
/* Todo зачем этот редьюсер, проверить есть ли проверка на существование юзера */
const checkInitialState: ICheckUserExist = {
  loading: false,
  error: null,
  success: false
};

function checkUserExist(state = checkInitialState, { type, payload }: IAction) {
  const { REQUEST, ERROR, SUCCESS } = actionTypes.CHECK_USER_EXIST;
  switch (type) {
    case REQUEST: {
      return {
        ...checkInitialState,
        loading: true
      };
    }
    case ERROR: {
      return {
        ...checkInitialState,
        error: payload
      };
    }
    case SUCCESS: {
      return {
        ...checkInitialState,
        success: true
      };
    }
    default: return state;
  }
}

const checkEdo = (
  store: boolean = false,
  { type, payload }: IEdoExistStatus
) => {
  const { REQUEST, ERROR, SUCCESS } = actionTypes.PURCHASE.EDO.CHECK_STATUS;
  switch (type) {
    case REQUEST: {
      return {
        ...checkInitialState,
        loading: true
      };
    }
    case SUCCESS: {
      return {
        ...checkInitialState,
        edoExistStatus: payload,
        success: true
      };
    }
    case ERROR: {
      return {
        ...checkInitialState,
        error: true
      };
    }
    default:
      return store;
  }
};

export const purchaseReducer = combineReducers({
  data,
  errors,
  request,
  type,
  terroristStatus,
  passportCheck,
  esiaAuth,
  notRespond,
  redirectError,
  userRegistration,
  checkUserExist,
  checkEdo,
  sms: smsReducer
});
