import { IWizardForm } from 'interfaces';
import { FormField } from 'entities/formField';
import { formFields } from 'components/shared/formFields';
import { InnHint } from 'components/shared/fieldHints/inn';
import { AvailableMasks } from 'constants/masks/masks';

import { PassportCheck } from '../passportCheck';
import { PassportNotification } from './passportNotification';

const { passport } = formFields;

const inn = new FormField(formFields.inn, {
  isRequired: true,
  mask: AvailableMasks.inn,
  hint: <InnHint />
});

const seriesAndNumber = new FormField(passport.seriesAndNumber, {
  isRequired: true,
  mask: AvailableMasks.passport
});

export const innAndPassportNumber: IWizardForm = {
  title: 'ИНН и номер паспорта',
  fields: [inn, seriesAndNumber],
  AfterFields: PassportNotification,
  BeforeNext: (props) => <PassportCheck {...props} />,
  url: 'registration/step-two'
};

export const innAndPassportNumberFields = {
  inn,
  seriesAndNumber
};
