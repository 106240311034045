import styled from 'styled-components';

const RadioButtonContainer = styled.div`
  display: flex;
  input:checked ~ label {
    cursor: default;
  }
`;

export default { RadioButtonContainer };
