import React from 'react';

import { Change } from 'types/inputEvent';
import { getFakeEvent } from 'helpers/field/getFakeEvent';

import { IRadioButtonProps } from './interfaces';

import S from './styles';

export const RadioButton = ({
  label,
  onChange,
  id,
  value,
  checked,
  styles,
  name
}: IRadioButtonProps) => {
  const [inputName] = name
    ? name.split('-')
    : [''];
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (name) {
      onChange(getFakeEvent(id, name) as Change);
    } else {
      onChange(event);
    }
  };

  return (
    <S.RadioButton data-test="radio-button-component" style={styles}>
      <input
        name={inputName}
        type="radio"
        value={value}
        id={id}
        checked={checked}
        aria-checked={checked}
        onChange={handleChange}
      />
      <label htmlFor={id}>{label}</label>
    </S.RadioButton>
  );
};
