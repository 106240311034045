import styled from 'styled-components';

import { media } from 'assets/styles/theme';

const ProductsPage = styled.div`
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.background};
`;
const TextWrapper = styled.div`
  border-top: 1px solid rgb(237, 236, 230);
  max-width: 1170px;
  padding: 30px 15px;
  margin: 30px auto 0;
  text-align: justify;
`;
const Paragraph = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 10px;
  
  ${media.mobile} {
    font-size: 13px;
  }
`;

export default {
  ProductsPage,
  TextWrapper,
  Paragraph
};
