import {
  useState,
  useEffect,
  useRef
} from 'react';

import map from 'lodash/map';
import some from 'lodash/some';
import size from 'lodash/size';
import keys from 'lodash/keys';
import forEach from 'lodash/forEach';
import { getProperty } from 'dot-prop';
import styled from 'styled-components';

import { FormField } from 'entities/formField';
import { FormProps } from 'types/formFieldTypes';
import { Styles } from 'components/shared';
import { media } from 'assets/styles/theme';
import { IField } from 'interfaces';

import StylesProductButton from '../../portfolioCharts/productButton/styles';
import { Field } from '../Field';
import {
  IProps,
  onCheckboxToggleType,
  onToggleFieldsType
} from './interfaces';

import S from '../styles';

const { ProductButton } = StylesProductButton;

const Wrapper = styled(Styles.ExtraText)`
    margin: 10px auto 36px;
    text-align: center;
    ${media.mobile} {
      margin-top: 0;
    }
  `;

export function FieldsContainer({
  fields,
  AfterFields,
  AfterHiddenFields,
  onChangeFieldProps,
  errors,
  request: { loading: requestLoading },
  setHiddenStatus,
  formProps
}: IProps) {
  // state для видимых полей
  const [Fields, setFields] = useState<JSX.Element[]>([]);
  // state для скрытых полей
  const [HiddenFields, setHiddenFields] = useState<JSX.Element[]>([]);

  const [showHidden, setShowHidden] = useState(false);
  const [loading, setLoading] = useState(false);

  const [hiddenHandlers, setHiddenHandlers] = useState<onToggleFieldsType[]>([]);

  const [toggleCheckboxHandlers, setToggleCheckboxHandlers] = useState<onCheckboxToggleType[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const hiddenFieldsNames = useRef<string[]>([]);

  useEffect(() => {
    setHiddenStatus(showHidden);
  }, [showHidden]);

  useEffect(() => {
    const hasError = some(hiddenFieldsNames.current, ((name) => getProperty(errors, name)));
    if (hasError) {
      handleToggleFields(true);
    }
  }, [errors]);

  useEffect(() => {
    updateHanlders();
    updateFileds();
  }, [fields]);

  useEffect(() => {
    updateFileds();
  }, [formProps]);

  const callHiddenHandlers = () => {
    forEach(hiddenHandlers, ((fn: (args: any) => void) => {
      fn(changeFieldValue());
    }));
  };
  const updateHanlders = () => {
    const newHiddenHandlers: onToggleFieldsType[] = [];
    const newCheckBoxHandlers: onCheckboxToggleType[] = [];

    forEach(fields, ((field) => {
      const objField = field.get();
      const { onToggleFields, onCheckboxToggle } = objField;
      if (onToggleFields) {
        newHiddenHandlers.push(onToggleFields);
      }
      if (onCheckboxToggle) {
        newCheckBoxHandlers.push(onCheckboxToggle);
      }
    }));
    setHiddenHandlers(newHiddenHandlers);
    setToggleCheckboxHandlers(newCheckBoxHandlers);
  };
  /* todo во многом дублируются ф-ции setFields и setHiddenFields */
  const updateFileds = () => {
    setFields(
      fields
        .filter(
          ({ modifiers }) => !modifiers.hidden && !modifiers.itLastField
        )
        .map((field, index) => getFieldComponent(
          field,
          formProps,
          `${field.name} ${index}`
        ))
    );

    setHiddenFields(
      fields
        .filter(({ modifiers }) => modifiers.hidden && !modifiers.itLastField)
        .map((field, index) => {
          const { name } = field
          hiddenFieldsNames.current.push(name);
          return getFieldComponent(
            field,
            formProps,
            `${name} ${index}`
          );
        })
    );

    if (loading !== requestLoading) {
      if (loading && size(keys(errors))) {
        scrollToErrors();
      }
      setLoading(requestLoading);
    }
  };

  function handleToggleFields(isSetShowHidden?: boolean) {
    setShowHidden(isSetShowHidden || !showHidden);
    callHiddenHandlers();
    updateFileds();
    if (onChangeFieldProps) {
      onChangeFieldProps(fields);
    }
  }

  const handleToggleCheckbox = (field: IField) => {
    forEach(toggleCheckboxHandlers, ((fn) => {
      fn(field, changeFieldValue());
    }));
    if (onChangeFieldProps) {
      onChangeFieldProps(fields, field);
    }
  };

  const changeFieldValue = () => (value: string | boolean, name: string) => {
    const event = {
      target: { value, name }
    };
    formProps.handleChange(event);
  };

  const scrollToErrors = () => {
    let firstErrorKey = keys(errors)[0];
    let errorsObject = errors;
    let errorFieldName = firstErrorKey;

    while (typeof errorsObject[firstErrorKey] !== 'string') {
      errorsObject = errorsObject[firstErrorKey];
      firstErrorKey = keys(errorsObject)[0];
      errorFieldName += `.${firstErrorKey}`;
    }

    const fieldElement: HTMLInputElement | null = containerRef
      && containerRef.current
      && containerRef.current.querySelector(`[name="${errorFieldName}"]`);

    if (fieldElement) {
      fieldElement.focus();
      window.scrollTo({
        left: 0,
        // @ts-expect-error
        top: fieldElement.parentNode.parentNode.parentNode.offsetTop - 30,
        behavior: 'smooth'
      });
    }
  };

  const getFieldComponent = (
    field: FormField,
    formProps: FormProps,
    key?: string
  ) => (
    <Field
        errors={errors}
        key={key}
        field={field}
        formProps={formProps}
        onChangeCallback={handleToggleCheckbox}
        openHiddenFields={() => handleToggleFields(true)}
    />
  );
  /* todo посмотреть chain для lodash */
  const lastField = fields
    .filter((field) => field.modifiers.itLastField)
    .map((field, index) => getFieldComponent(field, formProps, `${field.name} ${index}`))[0];
  const containHiddenFields = !!size(HiddenFields);

  let fieldAddress: JSX.Element | null = null;
  let isPostAddress: boolean = false;

  forEach(Fields, (field) => {
    const { props: { field: { name } } } = field
    if (name === 'addressRegistration.addressString') {
      fieldAddress = field;
    }

    if (name === 'addressPost.addressString') {
      isPostAddress = true;
    }
  });

  return (
    <S.FieldsContainer ref={containerRef}>
      {map(Fields, (field) => {
        const { props: { field: { name } } } = field
        if (name === 'addressRegistration.addressString') {
          return null;
        }

        return field;
      })}
      {containHiddenFields && !showHidden && (
        <>
          {fieldAddress}
          <ProductButton onClick={() => handleToggleFields()} type="button">
            Заполнить адрес вручную
          </ProductButton>
        </>
      )}
      {containHiddenFields && showHidden && (
        <Wrapper>
          Внимательно проверьте информацию.
          <br />
          В случае ошибки договор может быть признан недействительным.
        </Wrapper>
      )}
      <S.HiddenFields isShow={showHidden}>
        {
          fieldAddress && !isPostAddress
            ? (
              <S.buttonShowAddress role="button" onClick={() => handleToggleFields()}>
                Поиск адреса
              </S.buttonShowAddress>
            )
            : null
        }
        {map(HiddenFields, (hiddenField) => hiddenField)}
        {AfterHiddenFields && <AfterHiddenFields />}
      </S.HiddenFields>
      {lastField && lastField}
      {AfterFields && <AfterFields />}
    </S.FieldsContainer>
  );
}
