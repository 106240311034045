import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { Icon } from 'assets/img/svg';
import { Styles } from 'components/shared';
import { IPurchaseData } from 'interfaces/purchase';
import { getEsiaLink } from 'helpers/esia';
import { actionCreator } from 'helpers/actions/actionCreator';
import { actionTypes } from 'store/actionTypes';
import { useObserveCookie } from 'components/hooks/useObserveCookie';
import { useStore } from 'components/hooks/useStore';
// @ts-expect-error
import pngImage from 'assets/img/gosuslugi_logo.png';
import { isMicrosoftBrowser } from 'helpers/userAgent';

import S from './styles';

export const EsiaButton = () => {
  const [isShow, setIsShow] = useState(true);
  const {
    purchase: {
      data,
      type
    }
  } = useStore()
  const dispatch = useDispatch()
  const getUserData = (token:string, purchaseData:IPurchaseData) => dispatch(
    actionCreator(actionTypes.ESIA.GET_USER.REQUEST, {
      token,
      purchaseData
    })
  )
  useObserveCookie('regToken', (token: string) => getUserData(token, data));
  useObserveCookie('isRespond', (cookieValue: string) => {
    if (cookieValue === 'error') {
      setIsShow(false);
    }
  });

  const image = isMicrosoftBrowser()
    ? <img src={pngImage} alt="gosuuslugiImage" />
    : <Icon.EsiaLogo />;
  return (
    <>
      {isShow && (
      <S.EsiaButton
          href={getEsiaLink(type, data.productId)}
      >
        {image}
        <Styles.ExtraText>
          Заполнить форму автоматически с помощью Госуслуги.ру
        </Styles.ExtraText>
      </S.EsiaButton>
      )}
    </>
  );
};
