import { useState } from 'react';

import { IProps } from './interfaces';

import BurgerStyled from './styles';

export const Burger = ({ onClick }: IProps) => {
  const [isActive, setActive] = useState(false);

  return (
    <BurgerStyled
      className={isActive ? 'active' : ''}
      onClick={() => {
        setActive(!isActive);

        if (onClick) {
          onClick();
        }
      }}
    >
      <span />
      <span />
      <span />
    </BurgerStyled>
  );
};
