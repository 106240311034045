import {
  takeLatest,
  put,
  call
} from 'redux-saga/effects';

import { Url } from 'api/url';
import { actionTypes } from 'store/actionTypes';
import { getPurchaseHeaders } from 'api/options';
import { api } from 'api';
import { ITrustGateFields } from 'components/widgets/applicationForms/passportCheck/interfaces';

import { host } from '../sendPurshaseData';
import { IPassport, IResponse } from './intefaces';

const { PASSPORT_CHECK, NOT_RESPOND } = actionTypes.PURCHASE;

function* passportCheck({ payload }: IPassport<string>) {
  const { SUCCESS, ERROR } = PASSPORT_CHECK;
  try {
    const params = {
      host,
      url: Url.purchase.passportCheckStatus,
      urlParams: {
        requestId: payload
      }
    };

    const { state }:IResponse = yield call(api.get, params, getPurchaseHeaders());

    yield put({ type: SUCCESS, payload: { state } });
  } catch ({ code, payload }) {
    if (code !== 500) {
      yield put({
        payload,
        type: ERROR
      });
    } else {
      yield put({
        type: NOT_RESPOND
      });
    }
  }
}

function* passportSend({ payload }:IPassport<ITrustGateFields>) {
  const { SUCCESS, ERROR } = PASSPORT_CHECK.SEND;
  try {
    const params = {
      host,
      url: Url.purchase.passportSendData,
      data: payload
    };
    // @ts-expect-error
    const { requestId }:IResponse = yield call(api.post, params, getPurchaseHeaders());

    yield put({
      type: SUCCESS,
      payload: { data: { passportCheckId: requestId } }
    });
  } catch ({ code, payload }) {
    if (code !== 500) {
      yield put({
        payload,
        type: ERROR
      });
    } else {
      yield put({
        type: NOT_RESPOND
      });
    }
  }
}

export function* checkPassport() {
  yield takeLatest(PASSPORT_CHECK.SEND.REQUEST, passportSend);
  yield takeLatest(PASSPORT_CHECK.REQUEST, passportCheck);
}
