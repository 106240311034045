import React, {
  useState,
  useReducer,
  useRef
} from 'react';

import useOnClickOutside from 'use-onclickoutside';
import debounce from 'lodash/debounce';
import forEach from 'lodash/forEach';
import { getProperty } from 'dot-prop';

import { actionCreator } from 'helpers/actions/actionCreator';
import { ISuggestionResponse } from 'helpers/dadata';
import { getFakeEvent } from 'helpers/field/getFakeEvent';
import { Change } from 'types/inputEvent';
import {
  nextSuggestion,
  prevSuggestion,
  selectSuggestion
} from 'helpers/inputs/suggestions';

import { TextInput } from '../text';
import { TextArea } from '../textArea';
import { Suggestions } from './suggestions';
import { suggestionsReducer } from './reducer';
import {
  initialState,
  ISuggestionsInputProps,
  suggestionsActions
} from './interfaces';

import S from './styles';

export const SuggestionsInput = (props: ISuggestionsInputProps) => {
  const {
    onChange,
    name,
    openHiddenFields,
    setFieldTouched,
    autocompleateFields,
    getSuggestions,
    as
  } = props;

  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [state, dispatch] = useReducer(suggestionsReducer, initialState);
  const suggestionsWrapperRef = useRef(null);

  const setSuggestions = async (value: string) => {
    const newSuggestions = await getSuggestions(value);
    dispatch(actionCreator(suggestionsActions.setSuggestions, newSuggestions));
  };
  const debouncedSetSuggestions = debounce(setSuggestions, 250);

  const setEvent = (event: Change) => dispatch(actionCreator(suggestionsActions.setEvent, event));

  const showSuggestions = () => dispatch(actionCreator(suggestionsActions.showSuggestions));

  const hideSuggestions = () => dispatch(actionCreator(suggestionsActions.hideSuggestions));

  useOnClickOutside(suggestionsWrapperRef, hideSuggestions);

  const { suggestions } = state;

  const handleChange = async (event: Change) => {
    if (onChange) {
      onChange(event);
    }

    const { value } = event.currentTarget;
    debouncedSetSuggestions(value);
    setEvent(event);
    showSuggestions();
  };

  const handleSuggestionClick = (suggestion: ISuggestionResponse) => {
    if (onChange) {
      const event = getFakeEvent(
        suggestion.textSuggestion,
        name || ''
      ) as Change;

      onChange(event);

      setAutocompleateFields(suggestion);
      hideSuggestions();

      if (name && name.indexOf('addressString') !== -1) {
        openHiddenFields();
      }
    }
  };

  const setAutocompleateFields = (suggestion: ISuggestionResponse) => {
    if (autocompleateFields) {
      forEach(autocompleateFields, (fieldName) => {
        // @ts-expect-error
        const fieldValue:string = getProperty(suggestion.payload, fieldName);
        setFieldValue(fieldValue, fieldName);
      });
    }

    function setFieldValue(value: string, fieldName: string) {
      const valueField = value || '';

      if (onChange) {
        onChange(getFakeEvent(valueField, fieldName) as Change);
        setFieldTouched(fieldName, true, true);
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const { key } = event;
    // eslint-disable-next-line default-case
    switch (key) {
      case 'ArrowUp':
        setActiveSuggestion(prevSuggestion(activeSuggestion));
        break;
      case 'ArrowDown':
        setActiveSuggestion(
          nextSuggestion(activeSuggestion, suggestions)
        );
        break;
      case 'Escape':
      case 'Tab':
        hideSuggestions();
        break;
      case 'Enter':
        selectSuggestion(event, state, activeSuggestion, handleSuggestionClick);
        break;
    }
  };

  const inputProps = {
    ...props,
    onChange: handleChange,
    onFocus: () => showSuggestions(),
    autoComlete: 'off',
    onKeyDown: handleKeyDown
  };

  return (
    <S.SuggestionsWrapper ref={suggestionsWrapperRef}>
      {as === 'textarea'
        ? <TextArea {...inputProps} autoComplete="off" />
        : <TextInput {...inputProps} autoComplete="off" />}
      <Suggestions
        suggestions={suggestions}
        onClick={handleSuggestionClick}
        isShow={state.showSuggestions}
        active={activeSuggestion}
        setActive={setActiveSuggestion}
      />
    </S.SuggestionsWrapper>
  );
};
