import {
  call,
  put,
  takeEvery
} from 'redux-saga/effects';

import { api } from 'api';
import { actionTypes } from 'store/actionTypes';
import { IOpifYield } from 'interfaces/opifYields';
import { Url } from 'api/url';

function* checkOpifYield() {
  /* Todo что за хосты? */
  try {
    const host = document.location.hostname
        === 'devonline.veles-management.ru' || document.location.hostname.indexOf('localhost') !== -1
      ? 'https://devonline.veles-management.ru/api/'
      : 'https://online.veles-management.ru/api/';
    const params = {
      host,
      url: Url.opifYield.get
    };

    const opifYield: IOpifYield[] = yield call(
      api.get,
      params
    );
    yield put({
      type: actionTypes.YIELD_INFO.SUCCESS,
      payload: opifYield
    });
  } catch (e) {
    yield put({
      type: actionTypes.YIELD_INFO.ERROR,
      payload: e.message
    });
  }
}

export function* getOpifYield() {
  yield takeEvery(
    actionTypes.YIELD_INFO.REQUEST,
    checkOpifYield
  );
}
