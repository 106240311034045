import { removePhoneMask } from 'helpers/phone/phoneMask';
import { formatDate } from 'helpers/date';
import {
  IPurchaseData,
  IPassport,
  IAddress,
  IRequisites
} from 'interfaces';

export class PuchaseRequestData {
  readonly requestData: IPurchaseData;

  readonly files?: FormData;

  constructor(purchaseData: IPurchaseData) {
    this.requestData = {
      firstName: purchaseData.firstName,
      agree: purchaseData.agree,
      patronymic: purchaseData.patronymic,
      lastName: purchaseData.lastName,
      email: purchaseData.email,
      inn: purchaseData.inn,
      productId: purchaseData.productId,
      placeBirth: purchaseData.placeBirth,
      gender: purchaseData.gender,
      phone: purchaseData.phone,
      purchase: PuchaseRequestData.getPurchase(purchaseData),
      passport: PuchaseRequestData.getFormatPassportData(purchaseData.passport),
      dayBirth: PuchaseRequestData.formatDate(purchaseData.dayBirth),
      addressRegistration: PuchaseRequestData.getFormatAddressData(
        purchaseData.addressRegistration
      ),
      addressPost: purchaseData.addressPost
        ? PuchaseRequestData.getFormatAddressData(
          purchaseData.addressPost.addressString
            ? purchaseData.addressPost
            : purchaseData.addressRegistration
        )
        : undefined,
      requisites: PuchaseRequestData.getFormatRequisitesData(purchaseData.requisites),
      promoCode: purchaseData.promoCode
    };
    this.files = this.getFilesData(purchaseData.files);
  }

  private static getPurchase(purchaseData: IPurchaseData) {
    const { productName, purchase } = purchaseData;
    if (purchase) {
      const { price, productId } = purchase;
      return { productId, price, productName: productName || '' };
    }
    return undefined;
  }

  private static getFormatAddressData(address?: IAddress): IAddress | undefined {
    if (address) {
      if (!address.addressString) {
        const {
          country,
          region,
          regionalDistrict,
          city,
          house,
          street,
          postcode
        } = address;
        return {
          country,
          region,
          regionalDistrict,
          city,
          house,
          street,
          postcode
        };
      }
      return address;
    }
    return undefined;
  }

  private static formatDate(date?: string): string | undefined {
    const result = formatDate(date);
    if (result) return result;
    return undefined;
  }

  private static getFormatPassportData(passport?: IPassport): IPassport | undefined {
    if (passport) {
      let result = { ...passport };

      if (passport.unitCode) {
        result = {
          ...result,
          unitCode: passport.unitCode
        };
      }
      if (passport.issuesDate) {
        result = {
          ...result,
          issuesDate: PuchaseRequestData.formatDate(passport.issuesDate)
        };
      }
      // @ts-expect-error
      delete result.series_number;
      return result;
    }
    return undefined;
  }

  // @ts-expect-error
  private static removeUnitCodeMask(unitCode?: string): string | undefined {
    if (unitCode) {
      return unitCode.replace(/ – /g, '');
    }
    return undefined;
  }

  // @ts-expect-error
  private static removePhoneMask(phone?: string) {
    if (phone) {
      return removePhoneMask(phone);
    }
    return undefined;
  }

  private static getFormatRequisitesData(requisites?: IRequisites) {
    if (requisites) {
      const {
        cardNumber,
        cardholderName,
        bankName,
        bankPlace,
        bankPlaceType,
        bik,
        rs,
        ks
      } = requisites;
      if (cardNumber && !bankName) {
        return {
          cardholderName,
          cardNumber
        };
      }
      if (bankName && !cardNumber) {
        return {
          bankName,
          bankPlace,
          bankPlaceType,
          bik,
          rs,
          ks
        };
      }
      return {
        ...requisites
      };
    }
    return undefined;
  }

  // @ts-expect-error
  private static removeCardMask(cardNumber?: string): string | undefined {
    try {
      return cardNumber ? cardNumber.replace(/\-/g, '') : undefined;
    } catch (e) {
      return undefined;
    }
  }

  private getFilesData(files?: Blob[]): FormData | undefined {
    const formData = new FormData();
    if (files) {
      files.forEach((file) => formData.append(`file_${1}`, file));
      return formData;
    }
    return undefined;
  }
}
