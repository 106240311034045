import { FC } from 'react';

import { IInputProps } from 'interfaces';

import { TextInput } from './text';
import { DateInput } from './date';
import { MoneyInput } from './money';
import { PassportInput } from './passport';
import { PasswordInput } from './password';
import { TextArea } from './textArea';

export const Input = (props: IInputProps) => {
  const { type } = props;

  function getInputWithProps(
    Component: FC<IInputProps>,
    inputProps: IInputProps
  ) {
    return <Component {...inputProps} />;
  }
  switch (type) {
    case 'date':
      return getInputWithProps(DateInput, props);
    case 'money':
      return getInputWithProps(MoneyInput, props);
    case 'passport':
      return getInputWithProps(PassportInput, props);
    case 'password':
      return getInputWithProps(PasswordInput, props);
    case 'textarea':
      return getInputWithProps(TextArea, props);
    case 'number':
    case 'tel':
    case 'email':
    default:
      return <TextInput {...props} />;
  }
};
