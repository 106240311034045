import styled from 'styled-components';

import { Money } from 'entities/money';
import { IWizardForm } from 'interfaces';
import { useUserOrProductInfo } from 'components/hooks/useUserOrProductInfo';
import { useStore } from 'components/hooks/useStore';
import { Styles, NumberField } from 'components/shared';

import { PaymentFrame } from './PaymentFrame';
import { Disclaimer } from './disclaimer';
import { IPurchaseComponentOwnProps } from './interfaces';

export * from './successForm';

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${Styles.DescriptionText} {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  ${Styles.ExtraText} {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 20px;
  }
`;

const Purchase = ({ onSubmit }: IPurchaseComponentOwnProps) => {
  const { productId, productName } = useUserOrProductInfo()
  const { purchase: { data: { purchase: { price } = { price: NaN } } } } = useStore()
  const handleSubmit = () => {
    if (onSubmit) onSubmit();
  };
  return (
    <>
      <InfoWrapper>
        <Styles.DescriptionText>Вы приобретаете</Styles.DescriptionText>
        <Styles.ExtraText>{productName}</Styles.ExtraText>
        <Styles.DescriptionText>На сумму</Styles.DescriptionText>
        <Styles.ExtraText>
          <NumberField value={new Money(price)} />
        </Styles.ExtraText>
      </InfoWrapper>
      { productId && (
      <PaymentFrame productId={productId} onSuccess={handleSubmit} />
      )}
    </>
  );
};

export const acquiring: IWizardForm = {
  title: 'Оплата',
  fields: [],
  url: '',
  hiddenSubmit: true,
  disclaimer: <Disclaimer />,
  className: 'max-width',
  BeforeFields: ({ onSubmit }) => <Purchase onSubmit={onSubmit} />
};
