import { createGlobalStyle } from 'styled-components';
import { media, theme } from './theme';

export const GlobalStyles = createGlobalStyle`
 @import
  url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&subset=cyrillic');
  * {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
  }
  html {
    height: 100%;
  }
  h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 37px;
    margin: 0;
    color: ${theme.colors.textPrimary}
    span {
        display: block;
        font-size: 30px;
        font-weight: 500;
      ${media.tablet} {
        font-size: 25px;
      }
      ${media.mobile} {
        font-size: 20px;
      }
    }
    span.w770{
        width: 770px;
    }
  }
  h2 {
    font-size: 22px;
    line-height: 27px;
    font-weight: normal;
    margin: 0;
    color: ${theme.colors.textPrimary}

  }
  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: ${theme.colors.textPrimary}
  }
  a {
    color: ${theme.colors.link};
    font-size: 17px;
    letter-spacing: 0.07px;
    line-height: 20px;
    text-decoration: underline;
    text-decoration-color: ${theme.colors.underline};
    text-align: center;
    button {
      text-decoration: none;
    }
  }
  .center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;
    outline: none;
  }
  .no-scroll {
    overflow: hidden;
  }
  textarea,input{
    -webkit-appearance: none
  }
  #root {
    display: flex;
    flex-direction: column;
  }
`;
