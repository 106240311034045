import { IField, IFieldModifiers } from 'interfaces';
import { FormFieldTypes } from 'types/formFieldTypes';
import { getFieldComponent } from 'helpers/form/getFieldComponent';

export class FormField {
  readonly name: string;

  readonly type: FormFieldTypes;

  readonly placeholder: string | JSX.Element;

  initialValue: string | number | boolean;

  readonly modifiers: IFieldModifiers;

  constructor(mainParams: IField, modifiers?: IFieldModifiers) {
    this.name = mainParams.name;
    this.type = mainParams.type;
    this.placeholder = mainParams.placeholder;
    this.initialValue = mainParams.initialValue;

    this.modifiers = { ...modifiers };
  }

  get(): IField {
    const field: IField = {
      name: this.name,
      type: this.type,
      placeholder: this.placeholder,
      initialValue: this.initialValue,
      ...this.modifiers
    };

    return field;
  }

  getView(openHiddenFields: () => void) {
    return getFieldComponent(this.get(), openHiddenFields);
  }

  setModifier(modifiedName: string, value?: boolean) {
    this.modifiers[modifiedName] = value;
  }
}
