export interface IStepForm {
  step: number;
  form: number;
}
export const initialState = {
  step: 0,
  form: 0,
  forms: [[]],
  isInitialValid: false,
  _maxParams: {
    step: 0,
    form: 0
  }
};
