import styled from 'styled-components';

import { media } from 'assets/styles/theme';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  color: ${({ theme }) => theme.colors.textPrimary};
  padding: 0 30px;
  border-left: 1px solid #f9f8f6;

  &:first-child {
    border-left: none;
  }

  ${media.tablet} {
    width: 100%;
    max-width: 180px;
    margin-bottom: 15px;
    margin-right: 20px;
    border: none;
    padding: 0;

    &:nth-child(2) {
      order: 4;
    }
    &:nth-child(3) {
      order: 5;
    }
    &:last-child {
      order: 6;
    }
  }

  ${media.mobile} {
    max-width: none;
    margin-right: 0;

    .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.06px;
    line-height: 18px;
    color: #a8a099;
    margin-bottom: 14px;

    ${media.tablet} {
      margin-bottom: 4px;
    }

    ${media.mobile} {
      font-size: 11px;
      max-width: 40%;
    }
  }

  .value {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.41px;
    line-height: 22px;

    ${media.mobile} {
      font-size: 15px;
      max-width: 55%;
    }
  }
`;

export default { Item };
