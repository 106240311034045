import {
  SchemaOf,
  string,
  object,
  number,
  boolean,
  // ObjectSchemaDefinition,
  array
} from 'yup';
import { getProperty, setProperty } from 'dot-prop';
import size from 'lodash/size';

import { IField, IPurchaseData } from 'interfaces';
import { FormField } from 'entities/formField';

export const getValidationSchemaDU = (
  fields: FormField[],
  stateData: IPurchaseData
): SchemaOf<any> | undefined => {
  if (!size(fields)) {
    return undefined;
  }
  const schemas: any = fields.reduce((accumulator: any, field: FormField) => {
    setProperty(accumulator, field.name, getValidationSchemaByValueDU(field, stateData));
    return accumulator;
  }, {});
  return nestingValidationSchema(schemas, fields);
};

const getValidationSchemaByValueDU = (field: FormField, stateData: IPurchaseData) => {
  const { type } = field;
  const { order } = stateData;
  if (field.get().disabled) {
    return undefined;
  }

  switch (type) {
    case 'date': {
      const validator = getNumberFieldValidator(10, 8);
      return setRequired(validator, field);
    }
    case 'money':
      const rangeMin = order && order.number !== ''
        ? 10000
        : 50000;

      return number()
        .nullable(true)
        .required('Введите сумму')
        .min(
          rangeMin,
          `Сумма не может быть меньше ${rangeMin} руб. и больше 9 999 999,99 руб.`
        )
        .max(
          9999999.99,
          `Сумма не может быть меньше ${rangeMin} руб. и больше 9 999 999,99 руб.`
        );
    case 'textarea': {
      return getTextFieldValidator(field, 250);
    }
    case 'dropzone':
    case 'text': {
      return getTextFieldValidator(field);
    }
    case 'email': {
      return getEmailFieldValidator(field);
    }
    case 'phone': {
      return getPhoneFieldValidator(field);
    }
    case 'checkbox': {
      return getCheckboxFieldValidator(field);
    }
    default:
      return undefined;
  }
};

export const getValidationSchema = (
  fields: FormField[]
): SchemaOf<any> | undefined => {
  if (!fields.length) {
    return undefined;
  }
  const schemas: any = fields.reduce((accumulator: any, field: FormField) => {
    setProperty(accumulator, field.name, getValidationSchemaByValue(field));
    return accumulator;
  }, {});
  return nestingValidationSchema(schemas, fields);
};

const getValidationSchemaByValue = (field: FormField) => {
  const { type } = field;
  if (field.get().disabled) {
    return undefined;
  }

  switch (type) {
    case 'date': {
      const validator = getNumberFieldValidator(10, 8);
      return setRequired(validator, field);
    }
    case 'money':
      return number()
        .nullable(true)
        .required('Введите сумму')
        .min(
          100,
          'Сумма не может быть меньше 100 руб. и больше 9 999 999,99 руб.'
        )
        .max(
          9999999.99,
          'Сумма не может быть меньше 100 руб. и больше 9 999 999,99 руб.'
        );
    case 'textarea': {
      return getTextFieldValidator(field, 250);
    }
    case 'dropzone':
    case 'text': {
      return getTextFieldValidator(field);
    }
    case 'email': {
      return getEmailFieldValidator(field);
    }
    case 'phone': {
      return getPhoneFieldValidator(field);
    }
    case 'checkbox': {
      return getCheckboxFieldValidator(field);
    }
    default:
      return undefined;
  }
};

const requiredError = 'Поле обязательно для заполнения';

const getCyrillicFieldName = (field: IField) => {
  const { placeholder } = field;
  if (typeof placeholder === 'string') {
    return placeholder.toLocaleLowerCase();
  }
  return placeholder;
};

const setRequired = (validator: any, { modifiers: { isRequired } }: FormField) => (isRequired
  ? validator.required(requiredError)
  : validator);

const getTextFieldValidator = (field: FormField, max: number = 64) => {
  let validator;
  const { name } = field;
  switch (name) {
    case 'inn': {
      validator = getNumberFieldValidator(12);
      break;
    }
    case 'files': {
      validator = array()
        .min(1)
        .max(3, 'Не более трех файлов');
      break;
    }
    case 'bankAccount.bik': {
      validator = getNumberFieldValidator(9);
      break;
    }
    case 'cardNumber': {
      validator = getNumberFieldValidator(19, 16);
      break;
    }
    case 'bankAccount.checkingAccount':
    case 'bankAccount.correspondentAccount': {
      validator = getNumberFieldValidator(20);
      break;
    }
    case 'passport.series_number': {
      validator = getNumberFieldValidator(11, 10);
      break;
    }
    case 'passport.unitCode': {
      validator = getNumberFieldValidator(6);
      break;
    }
    case 'addressRegistration.addressString':
    case 'addressPost.addressString': {
      validator = string().max(250, 'Поле не может быть больше 250 символов');
      validator = setRequired(validator, field);
      break;
    }
    default:
      validator = string().max(max, 'Поле не может быть больше 64 символов');
      validator = setRequired(validator, field);
      break;
  }
  validator = setRequired(validator, field);
  validator.ensure();
  return validator;
};

const getEmailFieldValidator = (field: FormField) => {
  const name = getCyrillicFieldName(field);
  let validator = string().email(`Поле ${name} заполнено неверно`);
  validator = setRequired(validator, field);
  return validator;
};

const getPhoneFieldValidator = (field: FormField) => {
  const phoneError = 'Неверно введен номер телефона';
  const lengthWithMask = 10;
  let validator = string()
    .min(lengthWithMask, phoneError)
    .max(lengthWithMask, phoneError);
  validator = setRequired(validator, field);
  return validator;
};
const getCheckboxFieldValidator = (field: FormField) => {
  if (field.get().isRequired) {
    return boolean()
      .required(requiredError)
      .oneOf([true], requiredError);
  }
  return undefined;
};

const getNumberFieldValidator = (
  countSymbols: number,
  countWithoutMask?: number
) => {
  const rangeError = `Поле должно содержать ${countWithoutMask
    || countSymbols} символов`;
  const validator = string()
    .min(countSymbols, rangeError)
    .max(countSymbols, rangeError);
  return validator;
};

const nestingValidationSchema = (schemas: any, fields: IField[]) => {
  const nodes = getNodes(fields);
  let nestingSchema = { ...schemas };
  nodes.forEach((node) => {
    const schemaNode = getProperty(schemas, node);
    if (schemaNode) {
      nestingSchema = setProperty(
        schemas,
        node,
        object().shape(schemaNode as any)
      );
    }
  });
  return object().shape(nestingSchema);
};

const getNodes = (fields: IField[]) => {
  const nodes: string[] = [];
  fields.forEach(({ name }) => {
    const node = name.substr(0, name.lastIndexOf('.'));
    if (node && nodes.indexOf(node) < 0) {
      nodes.push(node);
    }
  });
  return nodes.reverse();
};
