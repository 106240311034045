import { getProperty } from 'dot-prop';

/* todo типизация для есиа, сделать лог на тест и типизировать */
export function recursiveLoop(
  object: { [key: string]: any },
  cb: (key: string, value: any) => void,
  prevKey?: string
) {
  Object.keys(object).forEach((key) => {
    const pk = prevKey ? `${prevKey}.${key}` : key;
    if (object[key] instanceof Object) {
      recursiveLoop(object[key], cb, pk);
    } else {
      cb(pk, getProperty(object, key));
    }
  });
}
