import { Styles } from 'components/shared';
import { PHONE, PHONE_STRING } from 'constants/phone';

import { BlankError } from '../index';
import { IProps } from './interfaces';

import S from '../styles';

const ServiceError = (props: IProps) => {
  const { topText } = props;

  return (
    <BlankError>
      <S.Text>
        <Styles.ExtraText>
          {topText || 'К сожалению, сервис недоступен. '}
          <Styles.LinkText href={document.location.href}>
            Повторите&nbsp;попытку
          </Styles.LinkText>{' '}
          позже или свяжитесь с консультантом по телефону{' '}
          <S.Phone href={`tel:${PHONE_STRING}`}>{PHONE}</S.Phone>
        </Styles.ExtraText>
      </S.Text>
    </BlankError>
  );
};

export { ServiceError };
