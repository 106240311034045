import styled from 'styled-components';

import { Styles } from 'components/shared';

const Feedback = styled.section`
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      font-size: 22px;
      line-height: 29px;
      margin-right: 13px;
    }
  }
  .manager {
    margin-top: 16px;
    span {
      font-size: 14px;
      line-height: 18px;
      display: block;
    }
    .title {
      opacity: 0.5;
      margin-bottom: 5px;
    }
    .name {
      font-weight: bold;
    }
    .phone {
      margin-top: 15px;
      line-height: 20px;
    }
  }
  .support {
    margin-top: 26px;
    span {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .email {
      text-decoration: underline;
      text-decoration-color: rgba(255, 255, 255, 0.25);
      margin-top: 15px;
    }
  }
  ${Styles.Button} {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    padding: 6px 11px;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    margin-top: 14px;
    border-radius: 4px;
    border-color: ${({ theme }) => theme.colors.white};
  }
`;

export default { Feedback };
