import { useState, useRef } from 'react';

import dayjs from 'dayjs';
import useClickOutSide from 'use-onclickoutside';

import { Icon } from 'assets/img/svg';
import { stringToDate, isDate } from 'helpers/date/';
import { Change } from 'types/inputEvent';
import { IInputProps } from 'interfaces';
import { InputTypes } from 'types/inputTypes';

import { Datepicker } from '../../datepicker';
import { IRange } from '../../datepicker/interfaces';
import { TextInput } from '../text';

import S from './styles';

export const DateInput = (props: IInputProps) => {
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [date, setDate] = useState(new Date());
  const datepickerRef = useRef<HTMLDivElement>(null);

  const {
    onChange,
    name,
    likeTextField
  } = props;

  useClickOutSide(datepickerRef, () => setShowDatepicker(false));

  const handleDateChange = (range?: IRange, day?: Date) => {
    if (day && onChange) {
      const formatDay = dayjs(day).format('DD.MM.YYYY');
      const event = {
        target: { name, value: formatDay }
      };
      // @ts-expect-error
      onChange(event);
      setDate(day);
      setShowDatepicker(false);
    }
  };

  const handleInputChange = (event: Change) => {
    if (onChange) {
      onChange(event);
    }

    const { value } = event.currentTarget;

    if (isDate(value)) {
      handleDateChange(undefined, stringToDate(value));
    }
  };

  const inputType: InputTypes = 'text';

  const inputProps = {
    ...props,
    type: inputType,
    handleChange: handleInputChange
  };
  return (
    <S.DateInput>
      <TextInput {...inputProps} />
      {likeTextField
        ? null
        : (
          <div ref={datepickerRef}>
            <S.CalendarButton
              type="button"
              onClick={() => setShowDatepicker(true)}
            >
              <Icon.Calendar />
            </S.CalendarButton>
            <Datepicker
              isShow={showDatepicker}
              range={{ to: date, from: date }}
              onChange={handleDateChange}
            />
          </div>
        )}
    </S.DateInput>
  );
};
