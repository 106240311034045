import { useState, useEffect } from 'react';

import {
  IEsiaAuth,
  IWizardForm,
  IPurchaseData,
  IDotPropObject
} from 'interfaces';

import { getEsiaForm } from '../widgets/applicationForms';
import { IStepForm } from '../widgets/multistepWizard/reducer/interfaces';

export function useEsiaAuth(
  esiaAuth: IEsiaAuth | undefined,
  forms: IWizardForm[][],
  fields: IPurchaseData,
  registration?: boolean
): {
    forms: IWizardForm[][];
    goto: IStepForm | undefined;
  } {
  const [goto, setGoto] = useState<IStepForm | undefined>(undefined);

  useEffect(() => {
    if (esiaAuth) {
      if (esiaAuth.isTrusted) {
        if (registration) {
          forms[2][0] = getEsiaForm(fields as IDotPropObject);
          setGoto({
            step: 2,
            form: 0
          });
        } else {
          forms[1][1] = getEsiaForm(fields as IDotPropObject);
          setGoto({
            step: 1,
            form: 1
          });
        }
      }
    }
  }, [esiaAuth]);
  return { forms, goto };
}
