export function getCookie(name: string): string | undefined {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
  return undefined;
}
/* todo setCookie и deleteCookie не используется */
// export function setCookie(name: string, value: string | number | boolean, props?: any) {
//   props = props || {};
//
//   let exp = props.expires;
//
//   if (typeof exp === 'number' && exp) {
//     const d = new Date();
//
//     d.setTime(d.getTime() + exp * 1000);
//
//     // eslint-disable-next-line no-multi-assign
//     exp = props.expires = d;
//   }
//
//   if (exp && exp.toUTCString) {
//     props.expires = exp.toUTCString();
//   }
//
//   value = encodeURIComponent(value);
//
//   let updatedCookie = name + '=' + value;
//
//   for (const propName in props) {
//     updatedCookie += '; ' + propName;
//
//     const propValue = props[propName];
//
//     if (propValue !== true) {
//       updatedCookie += '=' + propValue;
//     }
//   }
//
//   document.cookie = updatedCookie;
// }

// export function deleteCookie(name: string) {
//   setCookie(name, null, { expires: -1 });
// }
