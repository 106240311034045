import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import includes from 'lodash/includes';
import size from 'lodash/size';
import split from 'lodash/split';

import { purchaseSteps, opifPurchase } from 'components/widgets/applicationForms/opif';
import { useEsiaAuth } from 'components/hooks/useEsiaAuth';
import { actionCreator } from 'helpers/actions/actionCreator';
import { actionTypes } from 'store/actionTypes';
import { stringToDate } from 'helpers/date/';
import { useStore } from 'components/hooks/useStore';
import { duProductsId } from 'staticContent/duProductsIdAndName';
import {
  LoginHeader,
  Footer,
  MultiStepWizard
} from 'components/widgets';

import SuspenseWorks from '../errors/suspenseOfOperations';
import { LoaderTooltip } from '../../widgets/tooltips/loader';

import S from './styles';

export const Purchase = () => {
  const {
    recommendedProducts,
    recommendedProducts: {
      byId,
      ids
    },
    purchase: {
      esiaAuth,
      data,
      data: {
        productName = ''
      }
    }
  } = useStore()
  const productId = useParams().productId || '30'
  const productType = useParams().productType || 'invest'
  const navigate = useNavigate()
  const isRedirectUrl = size(split(window.location.pathname, '/')) < 6
  /* редирект если была логинизация через госуслуги */
  if (isRedirectUrl) navigate(`/purchase/${productType}/${productId}/2/2`)
  const dispatch = useDispatch()
  const setPurchaseData = (type: string, productId: string, productName: string) => {
    dispatch(
      actionCreator(actionTypes.PURCHASE.SET_PARAMS, {
        data: { productId, type, productName }
      })
    );
  }
  const [isMenuActive, setMenuActive] = useState(false);

  if (!isEmpty(ids) && !ids.includes(toNumber(productId))
        || (!isEmpty(byId) && (byId[productId].typeId === 4
        || byId[productId].typeId === 5))) { return <Navigate replace to="/" />; }

  useEffect(() => {
    if (!size(ids)) {
      dispatch(actionCreator(actionTypes.RECOMMENDED_PRODUCTS.GET.REQUEST));
    } else {
      const name = byId[productId]
        ? byId[productId].name
        : '';

      if (name) {
        setPurchaseData('opif', productId, name);
      }
    }
  }, [recommendedProducts]);

  const getHoldDate = () => {
    const product = byId[productId];
    if (product) {
      const holdsTime = product.holds;
      if (holdsTime) {
        const currentHold = holdsTime.purchase;
        if (currentHold) {
          const currentTo = stringToDate(currentHold.to, 'YYYY.MM.DD');
          return (
            currentTo
          );
        }
      }
    }
    return null;
  };

  const getMessage = () => {
    const product = byId[productId];
    if (product) {
      const { holds } = product;
      if (holds) {
        const currentHold = holds.purchase;
        const currentMessage = currentHold.message;
        return (
          currentMessage
        );
      }
    }
    return null;
  };

  const holdDate = getHoldDate();

  const { forms, goto } = useEsiaAuth(esiaAuth, opifPurchase, data);
  const isDUProduct = includes(duProductsId, productId);
  const titleType = isDUProduct
    ? 'Приобретение продуктов доверительного управления'
    : 'Приобретение инвестиционных паев ОПИФ РФИ';
  const title = isDUProduct
    ? productName.trim()
    : `«${productName.trim()}»`;

  return (
    holdDate
      ? (
        <SuspenseWorks
            dateOfCompletion={getHoldDate() || new Date()}
            // @ts-expect-error
            message={getMessage()}
        />
      )
      : (
        <S.Purchase isMenuActive={isMenuActive} data-test="purchase-page">
          <LoginHeader setMenuActive={setMenuActive} isMenuActive={isMenuActive} />
          {productName
            ? (
              <MultiStepWizard
                steps={purchaseSteps}
                forms={forms}
                titletype={titleType}
                title={title}
                goto={goto}
              />
            )
            : (
              <div className="loader-container">
                <LoaderTooltip isShow description="Пожалуйста, подождите..." />
              </div>
            )}
          <Footer />
        </S.Purchase>
      )
  );
};
