import { IAddress, IInputProps } from 'interfaces';
import { ISuggestionResponse } from 'helpers/dadata';
import { InputTypes } from 'types/inputTypes';

interface ISuggestionsInputProps extends IInputProps {
  getSuggestions: (value: string) => Promise<ISuggestionResponse[]>;
  setFieldTouched: (fieldName: string, arg1: boolean, arg2: boolean) => void;
  openHiddenFields: () => void;
  as?: InputTypes;
  autocompleateFields?: string[];
}

interface ISuggestionsInputState {
  suggestions: ISuggestionResponse[];
  event: ISuggestionResponse[];
  showSuggestions: boolean;
  addressPayload?: IAddress;
}

export const initialState: ISuggestionsInputState = {
  suggestions: [],
  event: [],
  showSuggestions: false
};
export const suggestionsActions = {
  setEvent: 'set-event',
  setSuggestions: 'set-suggestions',
  showSuggestions: 'showing-suggestions',
  hideSuggestions: 'hide-suggestions'
};
export type {
  ISuggestionsInputProps,
  ISuggestionsInputState,
};
