import { useRef } from 'react';

import useOnClickOutside from 'use-onclickoutside';

import { Icon } from 'assets/img/svg';

import { ITooltipProps } from './interfaces';

import S from './styles';

/* универсальный tooltip для всего приложения */
export const CommonTooltip = ({
  isShow = false,
  children,
  onClose: close,
  showCloseButton,
  notBlur
}: ITooltipProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleOutsideClick = () => isShow && close();
  useOnClickOutside(ref, () => {
    handleOutsideClick();
  });
  return (
    <S.Background notBlur={notBlur} isShow={isShow}>
      <S.Tooltip data-test="tooltip-component" ref={ref}>
        {showCloseButton && (
        <S.CloseButton onClick={close}>
          <Icon.X />
        </S.CloseButton>
        )}
        {children}
      </S.Tooltip>
    </S.Background>
  );
};
