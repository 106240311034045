import styled from 'styled-components';
import includes from 'lodash/includes';

import { media } from 'assets/styles/theme';
import { downloadFile } from 'helpers/downloadFile';
import { duProductsName } from 'staticContent/duProductsIdAndName';
import { useStore } from 'components/hooks/useStore';
import { CustomScrollBox } from 'components/shared/customScrollBox';
import {
  Styles,
  Hint,
  StylesPopup
} from 'components/shared';

import { getProductLink } from './getLink';

const SmsDescription = styled(Styles.ExtraText)`
  display: block;
  position: relative; 
  white-space: normal;
  text-align: justify;
  ${Styles.LinkText} {
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
  }
  .hint {
    display: inline;
    ${StylesPopup} {
      width: 390px;
      ${media.mobile} {
        width: 260px;
      }
      ${Styles.ExtraText} {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
`;

export const DescriptionCheckData = () => {
  const {
    purchase: {
      data: {
        productName = '',
        userDocs,
        productDocs = '',
        lastName,
        firstName,
        patronymic
      }
    }
  } = useStore()
  const fullUserName = `${lastName || ''} ${firstName || ''} ${patronymic || ''}`.trim() || 'пользователь';
  const defaultDoc = { title: '', downloadUri: '' };
  const {
    applicationAcquisition = defaultDoc,
    openLc = defaultDoc,
    connectDog = defaultDoc,
    profileRegistred = defaultDoc,
    profilePhysical = defaultDoc,
    agreementPersonal = defaultDoc
  } = userDocs || {};

  if (includes(duProductsName, productName)) {
    return (
      <CustomScrollBox>
        <SmsDescription>
          Нажимая кнопку «Подписать», я, {fullUserName}, подтверждаю, что{' '}
          <Hint
            title={<Styles.LinkText>действую исключительно к своей выгоде,</Styles.LinkText>}
            content="Я не действую к выгоде лица - выгодоприобретателя"
            wrapperClassName="hint"
          />{' '}
          не являюсь{' '}
          <Hint
            /* eslint-disable-next-line max-len */
            title={<Styles.LinkText>публичным должностным лицом, иностранным агентом, </Styles.LinkText>}
            content="Я не являюсь: иностранным публичным должностным лицом, должностным лицом публичных международных
            организаций, лицом, занимающим государственные должности Российской Федерации, должности членов Совета
            директоров Центрального банка Российской Федерации, должности федеральной государственной службы,
            назначение на которые и освобождение от которых осуществляется Президентом Российской Федерации
            или Правительства Российской Федерации, должности в Центральном Банке Российской Федерации.
            государственных корпорациях и иных организациях, созданных Российской Федерацией на
            основании федеральных законов, включенные в перечни должностей, определяемые Президентом
            Российской Федерации, а также родственником указанных лиц."
            wrapperClassName="hint"
          />{' '}
          имею единственное налоговое резидентство – Российская Федерация,{' '}
          <Styles.LinkText
            href="https://www.veles-management.ru/uploadedfiles/Lists-of-countries-territories-and-states.pdf"
            target="_blank"
            rel="nofollow noopener"
          >
            ознакомлен(а) с&nbsp;перечнем стран, территорий и&nbsp;государств,
            в&nbsp;отношении которых имеются международные и&nbsp;иные
            ограничения,
          </Styles.LinkText>{' '}
          указанный в заявке и документах номер мобильного телефона принадлежит мне,{' '}
          <Hint
            title={<Styles.LinkText>не имею гражданства США,</Styles.LinkText>}
            wrapperClassName="hint"
            content="Не имеет вида на жительство в США («GREEN CARD») и/или наличие признака налогового резидента США
            (т.е. пребывание на территории США более 183 дней за 3 предшествующих года, за исключением учителей,
            студентов и стажеров, временно присутствующих на территории США на основании виз типа «F», «J», «М» или «Q»)"
          />{' '}
          действую как физическое лицо, ставлю свою подпись на{' '}
          <ul>
            <li>
              <Styles.LinkText onClick={() => downloadFile(connectDog)}>
                Заявлении о присоединении к Договору доверительного
                управления ценными бумагами (стандартные стратегии управления)
              </Styles.LinkText>,{' '}
            </li>
            <li>
              <Styles.LinkText onClick={() => downloadFile(profilePhysical)}>
                Анкете физического лица
              </Styles.LinkText>{' '}
            </li>
            <li>
              <Styles.LinkText onClick={() => downloadFile(agreementPersonal)}>
                Согласии на обработку персональных данных
              </Styles.LinkText>{' '}
            </li>
          </ul>
          и подтверждаю, что с условиями {' '}
          <Styles.LinkText
            target="_blank"
            href="https://www.veles-management.ru/uploadedfiles/56.ВМ%20-%2027.10.2020%20-%20Договор%20ДУ%20ценными%20бумагами_CC_ИИС_03.11.2020.pdf"
          >
            Договора доверительного управления ценными бумагами (стандартные стратегии управления)
          </Styles.LinkText> и{' '}
          <Styles.LinkText
            target="_blank"
            href="https://www.veles-management.ru/uploadedfiles/56.ВМ%20-%2027.10.2020%20-%20Регламент%20ДУ_03.11.2020.pdf"
          >
            Регламента осуществления ООО «УК ВЕЛЕС Менеджмент» деятельности
            по доверительному управлению ценными бумагами
          </Styles.LinkText>,{' '}
          размещенным на странице Компании в сети Интернет по адресу:
          <Styles.LinkText
            target="_blank"
            href="https://www.veles-management.ru"
          >
          &nbsp;www.veles-management.ru
          </Styles.LinkText>{' '},
          ознакомлен(а) и согласен/(согласна), их содержание мне полностью понятно.
          Я подтверждаю достоверность предоставленных мной данных в подписываемых
          документах, уведомлен о рисках, связанных с осуществлением операций на
          рынке ценных бумаг, согласен/(согласна) с тем, что доверительное управление
          будет осуществляться в порядке, установленном Регламентом, ознакомлен(а) с условиями
          <Styles.LinkText
            target="_blank"
            href={getProductLink(productName)}
          >
          &nbsp;Стандартных стратегий управления.
          </Styles.LinkText>{' '}
        </SmsDescription>
      </CustomScrollBox>
    );
  }
  return (
    <CustomScrollBox>
      <SmsDescription>
        Нажимая кнопку подписать, я, {fullUserName}, подтверждаю, что{' '}
        <Hint
          title={<Styles.LinkText>действую исключительно к своей выгоде,</Styles.LinkText>}
          content="Я не действую к выгоде лица - выгодоприобретателя"
          wrapperClassName="hint"
        />{' '}
        не являюсь{' '}
        <Hint
          title={<Styles.LinkText>публичным должностным лицом,</Styles.LinkText>}
          content="Я не являюсь лицом или родственником иностранных публичных должностных лиц,
          должностных лиц публичных международных организаций, а также лиц, занимающих
          государственные должности Российской Федерации, должности членов Совета
          директоров Центрального банка Российской Федерации, должности федеральной
          государственной службы, назначение на которые и освобождение от которых
          осуществляются Президентом Российской Федерации или Правительством Российской
          Федерации, должности в Центральном банке Российской Федерации,государственных
          корпорациях и иных организациях, созданных Российской Федерацией на основании
          федеральных законов, включенные в перечни должностей, определяемые
          Президентом Российской Федерации."
          wrapperClassName="hint"
        />{' '}
        <Hint
          title={<Styles.LinkText>иностранным агентом,</Styles.LinkText>}
          wrapperClassName="hint"
          content="Я не действую от имени (в интересах), не являюсь руководителем или
          учредителем иностранных некоммерческих неправительственных организаций и их
          отделений, представительств и филиалов, осуществляющих свою деятельность
          на территории Российской Федерации"
        />{' '}
        имею единственное налоговое резидентство – Российская Федерация,{' '}
        <Styles.LinkText
          href="https://www.veles-management.ru/uploadedfiles/Lists-of-countries-territories-and-states.pdf"
          target="_blank"
          rel="nofollow noopener"
        >
          ознакомлен(а) с&nbsp;перечнем стран, территорий и&nbsp;государств,
          в&nbsp;отношении которых имеются международные и&nbsp;иные
          ограничения,
        </Styles.LinkText>{' '}
        указанный номер мобильного телефона принадлежит мне,{' '}
        <Hint
          title={<Styles.LinkText>не имею гражданства США,</Styles.LinkText>}
          wrapperClassName="hint"
          content="Не имеет вида на жительство в США («GREEN CARD») и/или наличие признака
          налогового резидента США (т.е. пребывание на территории США более 183 дней за 3
          предшествующих года, за исключением учителей, студентов и стажеров, временно
          присутствующих на территории США на основании виз типа «F», «J», «М» или «Q»)"
        />{' '}
        действую как физическое лицо, ставлю свою подпись на{' '}
        <Styles.LinkText onClick={() => downloadFile(applicationAcquisition)}>
          Заявке на приобретение инвестиционных паев ОПИФ РФИ{' '}
          &laquo;{productName}&raquo;{' '}
          (далее – Фонд)
        </Styles.LinkText>,{' '}
        <Styles.LinkText onClick={() => downloadFile(openLc)}>
          Заявлении об открытии лицевого счета
        </Styles.LinkText>,{' '}
        <Styles.LinkText onClick={() => downloadFile(profileRegistred)}>
          Анкете зарегистрированного физического лица в реестре владельцев инвестиционных паев
        </Styles.LinkText>,{' '}
        <Styles.LinkText onClick={() => downloadFile(profilePhysical)}>
          Анкете физического лица
        </Styles.LinkText>,{' '}
        <Styles.LinkText onClick={() => downloadFile(agreementPersonal)}>
          Согласии на обработку персональных данных
        </Styles.LinkText>{' '}
        и подтверждаю, что ознакомлен с{' '}
        <Styles.LinkText target="_blank" href={productDocs}>
          Правилами доверительного управления Фондом
        </Styles.LinkText>,{' '} а также понимаю,
        что Заявка на приобретение инвестиционных паев Фонда
        носит безотзывный характер. Я подтверждаю достоверность предоставленных мной данных в
        подписываемых документах и подтверждаю свое желание приобрести инвестиционные паи на
        условиях, указанных в{' '}
        <Styles.LinkText target="_blank" href={productDocs}>
          Правилах доверительного управления Фондом
        </Styles.LinkText>,{' '}
        осознаю и принимаю на себя риск убытков, связанных с изменением
        расчетной стоимости инвестиционного пая.
      </SmsDescription>
    </CustomScrollBox>
  );
}
