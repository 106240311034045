import { IInputProps } from 'interfaces';

import { TextInput } from '../text';

export const PassportInput = (props: IInputProps) => (
  <TextInput
    {...props}
    data-test="input-passport-component"
    mask="99 99 999 999"
    placeholder="Серия и номер паспорта"
  />
);
