import { Link } from 'react-router-dom';

import { Styles } from 'components/shared';
import { Icon } from 'assets/img/svg';
import { PHONE_LINK } from 'constants/phone';

import S from './styles';

export const Feedback = () => (
  <S.Feedback>
    <div className="title">
      <span>Обратная связь</span>
      <Icon.Feedback />
    </div>
    <section className="support">
      {PHONE_LINK}
      <a className="email" href="mailto:online@veles-management.ru">
        online@veles-management.ru
      </a>
      <Link to="/ask-a-question">
        <Styles.Button>
          Задать вопрос
        </Styles.Button>
      </Link>
    </section>
  </S.Feedback>
);
