import styled, { css } from 'styled-components';

import { Styles } from 'components/shared';
import { media } from 'assets/styles/theme';

import { ICancelButtonProps } from './interfaces';

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  ${Styles.Button} {
    margin-right: 20px;
    :last-child {
      margin-right: 0;
    }
  }
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  font-weight: 500;
  ${media.mobile} {
    font-size: 18px;
  }
`;

const Text = styled.div`
  max-width: 600px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
`;

const SendButton = styled(Styles.Button)`
  margin-top: 5px;
  padding: 14px 22px;
  margin-bottom: 0;
`;
const TextAddress = styled(Text)`
  font-weight: 500;
`;

const CancelButton = styled(Styles.InfoButton)<ICancelButtonProps>`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 47px;
  margin-top: 5px;
  margin-bottom: 0;
  ${({ searchError }) => searchError &&
    css`
    color: ${({ theme }) => theme.colors.white};
    background-color: #F4860A;
    border: none;
      :focus,
      :hover {
        background: #ff9a26;
      }
      :active {
        background: #c45827;
      }
   `
};
`;
export default {
  ButtonsWrapper,
  Title,
  Text,
  SendButton,
  TextAddress,
  CancelButton,
}
