import { IRequest } from 'interfaces';

interface ITypes {
  [type: string]: string;
}

export const requestInitialState: IRequest = {
  success: false,
  fail: false,
  loading: false
};

export type { ITypes };
