import styled from 'styled-components';

const ProductRate = styled.div`
  width: 48px;
  border-radius: 3px;
  border: 2px solid ${({ theme }) => theme.colors.border};
  display: flex;
  div {
    flex-shrink: 0;
    width: 7px;
    height: 15px;
    margin: 1px;
    background-color: ${({ theme }) => theme.colors.border};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 1px;
  }
  .active {
    background-color: ${({ theme }) => theme.colors.buttonPrimary};
    border: 1px solid ${({ theme }) => theme.colors.buttonPrimary};
  }
`;
const ProductRateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 15px;
    letter-spacing: 0.06px;
    line-height: 19px;
    margin-bottom: 13px;
  }
`;
export default { ProductRate, ProductRateWrapper };
