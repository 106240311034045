import { actionTypes } from 'store/actionTypes';
import {
  IAction,
  IUserRegistration,
  IUserRegistrationPayload
} from 'interfaces';

import { requestInitialState } from '../request/interfaces';
/* Todo узнать ли регистрация в анкете и сделать типизацию */
const userRegistration = (
  state: IUserRegistration = { request: requestInitialState, message: '' },
  { type, payload }: IAction
): IUserRegistration => {
  const { SUCCESS, ERROR, REQUEST } = actionTypes.PURCHASE.USER_REGISTRATION;

  switch (type) {
    case SUCCESS:
      return {
        ...state,
        message: payload ? (payload as IUserRegistrationPayload).message : '',
        request: {
          ...requestInitialState,
          success: true
        }
      };
    case ERROR:
      return {
        message: payload ? (payload as IUserRegistrationPayload).message : '',
        request: {
          ...requestInitialState,
          fail: true
        }
      };
    case REQUEST:
      return {
        ...state,
        request: {
          ...requestInitialState,
          loading: true
        }
      };
    default:
      return state;
  }
};

export { userRegistration };
