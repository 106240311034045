import { useDispatch } from 'react-redux';

import { actionTypes } from 'store/actionTypes';
import { actionCreator } from 'helpers/actions/actionCreator';
import { Styles, CommonTooltip } from 'components/shared';
import { IModal } from 'interfaces/wizardForm';
import { useStore } from 'components/hooks/useStore';

import { Form } from '../../form';
import { editUserDataFields } from './fields';

const {
  latinName,
  email,
  placeBirth,
  issuesDate,
  issuesPlace,
  unitCode,
  addressString
} = editUserDataFields;
/* todo компонент по изменению личных данных? */
export const EditUnverifiableData = ({ parentFormProps }: IModal) => {
  const { view: { editModal } } = useStore()
  const dispatch = useDispatch()
  const hideModal = () => dispatch(actionCreator(actionTypes.VIEW.EDIT_MODAL.HIDE))
  return (
    <CommonTooltip isShow={editModal} onClose={() => editModal && hideModal()}>
      <Form
        isModal
        fields={[
          latinName,
          email,
          placeBirth,
          issuesDate,
          issuesPlace,
          unitCode,
          addressString
        ]}
        BeforeFields={() => <Styles.H2>Изменение личных данных</Styles.H2>}
        isInitialValid
        onSubmit={(formFields: any) => {
          if (formFields) {
            parentFormProps.setValues({
              ...parentFormProps.values,
              ...formFields
            });
          }
          hideModal();
        }}
        url=""
      />
    </CommonTooltip>
  )
};
