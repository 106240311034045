import {
  takeLatest,
  put,
  call,
  select
} from 'redux-saga/effects';

import { api } from 'api';
import { actionTypes } from 'store/actionTypes';
import { isTrusted } from 'helpers/esia';
import { Token } from 'entities/token';
import { formatDate } from 'helpers/date';
import { getAdditionalNameParams } from 'helpers/actions/purchase/getAdditionalFields';

import { host } from './sendPurshaseData';

const {
  SUCCESS,
  ERROR,
  REQUEST
} = actionTypes.ESIA.GET_USER;
/* Todo сделать типизацию (сайт госуслуг) */
function* getUserData({ payload }: any) {
  try {
    const { token } = payload;
    // @ts-expect-error
    const response = yield call(
      api.get,
      {
        host,
        url: 'registration/get-all-data'
      },
      {
        Authenticate: `Token ${token}`,
        'Content-Type': 'application/json'
      }
    );

    Token.set('purchase', token);
    const trusted = isTrusted(response);
    const {
      purchase: { data }
    } = yield select();

    const dayBirth = response.dayBirth
      ? formatDate(response.dayBirth)
      : data.dayBirth;

    const passport = response.passport
      ? {
        ...response.passport,
        issuesDate: formatDate(
          response.passport.issuesDate,
          'DD.MM.YYYY',
          'YYYY-MM-DD'
        )
      }
      : data.passport;
    // @ts-expect-error
    const additionalNameParams = yield call(
      getAdditionalNameParams,
      response.firstName,
      response.lastName
    );
    const { latinName } = additionalNameParams;

    const actionPayload = {
      data: {
        ...data,
        ...response,
        dayBirth,
        passport,
        latinName
      },
      esiaAuth: {
        status: true,
        isTrusted: trusted
      }
    };
    yield put({
      type: SUCCESS,
      payload: actionPayload
    });
  } catch (error) {
    const { code } = error;
    if (code !== 500) {
      yield put({
        payload,
        type: ERROR
      });
    } else {
      yield put({
        type: actionTypes.PURCHASE.NOT_RESPOND
      });
    }
  }
}

export function* userData() {
  yield takeLatest(REQUEST, getUserData);
}
