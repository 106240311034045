import { DayPicker, DayModifiers } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import ru from 'date-fns/locale/ru';

import { IOwnProps } from './interfaces';

import S from './styles';

export const Datepicker = ({
  isShow,
  range: { from, to },
  onChange,
  limits,
  header: Header
}: IOwnProps) => {
  const handleDayClick = (
    day: Date | undefined,
    { disabled }: DayModifiers
  ) => {
    if (day && !disabled) {
      if (onChange) {
        onChange(undefined, day);
      }
    }
  };

  const getDisabledDays = () => {
    if (limits) {
      return {
        before: limits.from,
        after: limits.to
      };
    }
    return undefined;
  };

  const getSelectedDays = () => {
    if (from && to) {
      return [{ from, to }];
    }
    return undefined;
  };

  return (
    <S.Datepicker show={isShow} data-test="datepicker-component">
      {Header && Header}
      <DayPicker
          toDate={new Date()}
          locale={ru}
          showOutsideDays
          fixedWeeks
          disabled={getDisabledDays()}
          selected={getSelectedDays()}
          onDayClick={handleDayClick}
      />
    </S.Datepicker>
  );
};
