import styled from 'styled-components';

import { Styles } from 'components/shared';
import { useStore } from 'components/hooks/useStore';
import { PHONE } from 'constants/phone';
import { getAppeal } from 'helpers/form';
import { media } from 'assets/styles/theme';

import { BlankError } from '../index';

import S from '../styles';

const Title = styled(Styles.H2)`
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  
  ${media.mobile} {
    margin-bottom: 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 29px;
    text-align: center;
  }
`;

export const UserError = () => {
  const {
    purchase: {
      data: {
        lastName,
        firstName,
        patronymic,
        gender
      }
    }
  } = useStore()
  const userName = `${lastName || ''} ${firstName || ''} ${patronymic || ''}`.trim()
  const currentUserName = userName || 'пользователь';
  const currentGender = gender || undefined;

  function handleBackButtonClick() {
    window.location.href = 'https://lk.veles-management.ru';
  }
  return (
    <BlankError>
      <Title>
        {getAppeal(currentGender, currentUserName)}
      </Title>
      <Styles.ExtraText>
        Для продолжения оформления, пожалуйста, обратитесь в&nbsp;офис ООО
        &laquo;УК&nbsp;ВЕЛЕС&nbsp;Менеджмент&raquo;. При возникновении вопросов свяжитесь
        с&nbsp;нами по&nbsp;телефону: {PHONE}
      </Styles.ExtraText>
      <S.BackButton onClick={handleBackButtonClick}>
        Вернуться на главную
      </S.BackButton>
    </BlankError>
  );
}
