import styled from 'styled-components';

import { Styles } from 'components/shared';
import { FormWidth } from 'assets/styles/mixins';

const EditForm = styled.div`
  ${FormWidth}
`;
const Title = styled(Styles.H2)`
  margin-top: 0;
  margin-bottom: 0;
`;

const WrapperDescription = styled.span`
  display: block;
  max-width: 500px;
  text-align: center;
`

export default {
  EditForm,
  Title,
  WrapperDescription
};
