import styled from 'styled-components';
// import { Link } from 'react-router-dom';

import { media } from 'assets/styles/theme';

const H1 = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const H2 = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const H3 = styled.h3`
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const H4 = styled.h4`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const MainText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const ExtraText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const DescriptionText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.056px;
  color: ${({ theme }) => theme.colors.description};
`;

const LinkText = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.link};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.underline};
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 ${({ theme }) => theme.colors.shadow};
  padding: 55px;
  
  ${media.mobile} {
    box-shadow: none;
    border-radius: 0;
  }
`;

const Button = styled.button`
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.colors.buttonPrimary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  padding: 12px 22px;
  margin: 0 0 15px 0;
  display: inline-block;
  cursor: pointer;
  transition-property: background, color;
  transition-duration: 0.35s;

  &[disabled] {
    cursor: default;
  }
  :focus,
  :hover {
    background: ${({ theme }) => theme.colors.buttonHover};
    box-shadow: 1px 1px 5px rgba(244, 134, 10, 0.7);
  }
  :active {
    background: #c45827;
  }
  :disabled {
    background: #edebe8;
    color: #a8a099;
    box-shadow: none;
  }
`;

const InfoButton = styled(Button)`
  color: ${({ theme }) => theme.colors.link};
  box-shadow: none;
  border: 0.3px solid  ${({ theme }) => theme.colors.link};
  background-color: ${({ theme }) => theme.colors.white};

  :hover {
    background: rgba(0, 150, 183, 0.05);
    box-shadow: none;
  }
  :focus {
    background: rgba(0, 150, 183, 0.1);
  }
  :disabled {
    border-color: #edebe8;
    color: #edebe8;
  }
`;
interface IButtonLargeProps {
  isHidden?: boolean;
}

const ButtonLarge = styled(Button)<IButtonLargeProps>`
  ${({ isHidden }) => isHidden && 'display: none;'}
  padding: 16px 25px;
`;
export const Styles = {
  H1,
  H2,
  H3,
  H4,
  ButtonLarge,
  InfoButton,
  Button,
  Panel,
  LinkText,
  DescriptionText,
  ExtraText,
  MainText
}
