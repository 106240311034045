import forEach from 'lodash/forEach';

import { actionTypes } from 'store/actionTypes';
import { IRecommendedProduct, IterableEntity } from 'interfaces';

import { IRecommendedProductAction } from './interfaces';

export const recommendedProductsReducer = (
  state: IterableEntity<IRecommendedProduct> = {
    byId: {},
    ids: []
  },
  { type, payload }: IRecommendedProductAction
): IterableEntity<IRecommendedProduct> => {
  switch (type) {
    case actionTypes.RECOMMENDED_PRODUCTS.GET.SUCCESS: {
      const byId = {};
      const ids: string[] = [];
      forEach(payload, ((product:IRecommendedProduct) => {
        const {
          id,
          name,
          about,
          risk,
          typeId,
          holds: currentHolds,
        } = product;
        const holds = currentHolds
          ? {
            add: currentHolds.rebuy,
            purchase: currentHolds.buy,
            sale: currentHolds.redemption
          }
          :{};
        byId[id] = {
          name,
          about,
          risk,
          holds,
          typeId
        };
        ids.push(id);
      }));
      return {
        byId,
        ids
      };
    }
    default:
      return state;
  }
};
