import styled from 'styled-components';

import { FormWidth, NoScrollThumbs } from 'assets/styles/mixins';
import { media } from 'assets/styles/theme';
import { Styles } from 'components/shared';

const DocsManagement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .scrollbar-container {
    padding: 26px;
  }
  ${Styles.ExtraText} {
    margin-top: -5px;
    margin-bottom: 1px;
    ${media.mobile} {
      margin-top: 0;
    }
  }
  ${FormWidth}
  ${Styles.LinkText} {
    margin-bottom: 30px;
    display: flex;
    svg {
      flex-shrink: 0;
      margin-right: 10px;
      * {
        fill: ${({ theme }) => theme.colors.link};
      }
    }
    ${media.mobile} {
      text-align: left;
      margin-bottom: 10px;
    }
  }
  & > ${Styles.ExtraText} {
    text-align: center;
  }
`;
const Agreement = styled.div`
  height: 320px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 5px;
  p {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 20px;
    ${Styles.DescriptionText} {
      color: ${({ theme }) => theme.colors.textPrimary};
    }
    :last-child {
      margin-bottom: 0;
    }
  }
  margin-top: 34px;
  margin-bottom: 32px;
  ${media.mobile} {
    height: 400px;
    margin-bottom: 24px;
  }
`;
const WrapperContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: -12px;
  margin-bottom: -12px;
  position: absolute;
  padding: 26px;
  overflow: scroll;
  ${NoScrollThumbs}
`;
export default {
  DocsManagement,
  Agreement,
  WrapperContent
};
