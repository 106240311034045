import map from 'lodash/map';

export const duProductsId = ['30', '31', '32', '33']
export const duProductsIdNumber = map(duProductsId, (id) => Number(id))

export const duProductsName = [
  'Стандартная стратегия управления «Российские акции - баланс»',
  'Стандартная стратегия управления «Российские акции - риск»',
  'Стандартная стратегия управления «Американские акции - баланс»',
  'Стандартная стратегия управления «Американские акции - риск»'
];
