import {
  takeLatest,
  put,
  call
} from 'redux-saga/effects';

import { api } from 'api';
import { actionTypes } from 'store/actionTypes';
import { Url } from 'api/url';
import { getPurchaseHeaders } from 'api/options';

import { host } from '../sendPurshaseData';
import { IResponse } from './interfaces';

const {
  SUCCESS,
  REQUEST,
  ERROR
} = actionTypes.PURCHASE.CHECK_TERRORIZM;

function* checkStatus() {
  try {
    const params = {
      host,
      url: `${Url.purchase.checkTerrorizm}/`
    };
    const { agree }:IResponse = yield call(api.get, params, getPurchaseHeaders());
    if (agree) {
      yield put({ type: SUCCESS });
    } else {
      yield put({ type: ERROR });
    }
  } catch (error) {
    yield put({
      type: actionTypes.PURCHASE.NOT_RESPOND
    });
  }
}

export function* terrorizm() {
  yield takeLatest(REQUEST, checkStatus);
}
