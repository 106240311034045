import { Currency, FieldTypes } from 'enums';
import { getFormatNumberValue } from 'helpers/field';

export class Money {
  private value: number;

  private course: number;

  private currency: Currency;

  constructor(
    value: number,
    currency: Currency = Currency.rouble,
    course: number = 1
  ) {
    this.value = value;
    this.course = course;
    this.currency = currency;
    this.changeCurrency(currency, course);
  }

  get = () => this.value / this.course;

  getCurrency = () => this.currency;

  toString = (type: FieldTypes = FieldTypes.common) => `${getFormatNumberValue(this.get(), type)} ${this.currency}`

  changeCurrency = (newCurrency: Currency, course: number) => {
    this.currency = newCurrency;
    this.course = course;
  }
}
