import styled from 'styled-components';

import { media } from 'assets/styles/theme';

// @ts-expect-error
import Icon from 'assets/img/svg/arrow-calendar.svg';

interface IStylesProps {
  show: boolean;
}

const Datepicker = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 ${({ theme }) => theme.colors.shadow};
  
  position: absolute;
  padding: 20px;
  display: ${({ show }: IStylesProps) => (show ? 'inline-flex' : 'none')};
  z-index: 2;

  .rdp {
    --rdp-cell-size: 30px;
    --rdp-accent-color: rgb(0, 150, 183);

    ${media.mobile} {
      width: 100%;
    }
    &-button:hover:not(
    [aria-disabled='true'],
    [aria-pressed ='true'],
    [aria-label='Go to previous month'],
    [aria-label='Go to next month']) {
      background-color: #f0f8ff;
    }

    &-button {
      span {
        ${media.mobile} {
          font-size: 15px;
        }
      }
    }

    &-button:focus {
      background-color: #FFF;
    }
    &-head_cell {
      padding: 0.5em;
      padding-bottom: 18px;
      text-transform: capitalize;
      text-align: center;
      color: #cbd2d9;
      font-size: 13px;
      letter-spacing: 0.05px;
      line-height: 16px;
      font-weight: normal;

      ${media.mobile} {
        text-align: start;
      }
      
    }
    &-caption {
      position: relative;
    }
    
    &-caption_label {
      color: rgb(66, 66, 73);
      font-size: 13px;
      letter-spacing: 0.05px;
      line-height: 16px;
      font-weight: normal;
      left: 30%;
      margin-bottom: 20px;
      text-transform: capitalize;
      
      ${media.mobile} {
        font-size: 18px;
      }
    }

    &-month {
      position: relative;

      ${media.mobile} {
        width: 100%;
      }
    }
    &-table {
      ${media.mobile} {
        min-width: 100%;
      }
    }
     
    &-day {
      color: ${({ theme }) => theme.colors.textPrimary};
      font-size: 13px;
      letter-spacing: 0.05px;
      line-height: 16px;
      height: 30px;
      width: 30px;
      border-radius: 5px;
      padding: 4px;
    

      ${media.mobile} {
        margin-right: 0;
        font-size: 16px;
        padding: 12px 4px;
      }
      
      &_start {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &_end {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      &_outside {
        background: none;
        color: ${({ theme }) => theme.colors.description};
      }
      
      &_today:not(&-day_outside) {
        font-weight: normal;
      } 
      
      &_disabled {
        color: ${({ theme }) => theme.colors.description};
        outline: none;
      }
    }

    &-button:focus {
      border: none;
      color: ${({ theme }) => theme.colors.link};
    }
    

    &-nav {
      position: absolute;
      top: 2px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &-nav_button {
      &_next,
      &_previous {
        position: static;
        color: ${({ theme }) => theme.colors.link};
        background: url(${Icon}) no-repeat center;
        width:16px;
        height: 16px;
        svg {
          display: none;
        }
      }
    }
    &-nav_button {
      &_previous {
        transform: rotate(-180deg);   
    }
    }
  }
`;

export default { Datepicker };
