export function isMicrosoftBrowser(): boolean {
  const { navigator } = window;

  if (
    /MSIE 9/i.test(navigator.userAgent) ||
    /rv:11.0/i.test(navigator.userAgent)
  ) {
    // This is internet explorer 9 or 11
    return true;
  }

  if (/Edge\/\d./i.test(navigator.userAgent)) {
    // This is Microsoft Edge
    return true;
  }
  return false;
}
