export function getAppeal(
  gender: string | undefined,
  userName: string
): string {
  let apeal = 'Уважаемый клиент!';

  // eslint-disable-next-line default-case
  switch (gender) {
    case 'FEMALE': {
      apeal = `Уважаемая ${userName}!`;
      break;
    }
    case 'MALE': {
      apeal = `Уважаемый ${userName}!`;
      break;
    }
  }
  return apeal;
}
