import styled from 'styled-components';

import { Styles } from 'components/shared';
import { media } from 'assets/styles/theme';

const EsiaButton = styled.a`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 22px;
  text-decoration: none;

  svg,
  img {
    width: 54px;
    height: 60px;
    flex-shrink: 0;
    margin-right: 22px;
  }
  ${Styles.ExtraText} {
    text-align: left;
    ${media.mobile} {
      font-size: 14px;
      line-height: 20px;
    }
  }
  ${media.mobile} {
    padding: 20px;
  }
`;
export default { EsiaButton };
