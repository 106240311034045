import { FC } from 'react';

import isUndefined from 'lodash/isUndefined';
import size from 'lodash/size';

import { SuggestionsInput } from 'components/shared/input/suggestions';
import { Change } from 'types/inputEvent';
import { InputTypes } from 'types/inputTypes';
import {
  IField,
  IFormFieldProps,
  IInputProps
} from 'interfaces';
import {
  Input,
  CheckBox,
  RadioButtonContainer
} from 'components/shared';

import { getFakeEvent } from '../field/getFakeEvent';
import {
  getSuggestions,
  IGetSuggestionsData,
  ISuggestionResponse
} from '../dadata';

export function getFieldComponent(
  field: IField,
  openHiddenFields: () => void
): FC<IFormFieldProps> {
  const { type } = field;
  switch (type) {
    case 'radio':
      return getRadioButton(field);
    case 'checkbox':
      return getCheckbox(field);
    case 'content':
      return getContentField(field);
    default:
      return getInput(field, openHiddenFields);
  }
}
function getInput(
  field: IField,
  openHiddenFields: () => void
): FC<IFormFieldProps> {
  const {
    placeholder,
    name,
    mask,
    hint,
    description,
    likeTextField,
    styles,
    withSuggestions
  } = field;
  const type: InputTypes = field.type as InputTypes;
  return ({
    onChange,
    onBlur,
    error,
    value,
    disabled,
    setFieldTouched
  }: IFormFieldProps) => {
    const inputProps: IInputProps = {
      type,
      placeholder,
      name,
      mask,
      hint,
      description,
      likeTextField,
      onChange,
      onBlur,
      error,
      value,
      disabled,
      styles
    };

    if (withSuggestions) {
      const {
        parts,
        autocompleateFields,
        type: suggestionType,
        combineSame
      } = withSuggestions;
      const fetchSuggestions = async (inputValue: string) => {
        const data: IGetSuggestionsData = {
          value: inputValue,
          parts: parts ? [parts] : undefined
        };
        const suggestions = await getSuggestions(suggestionType, data);
        const uniqueSuggestions: { [key: string]: boolean } = {};

        return combineSame
          ? suggestions.reduce((result:ISuggestionResponse[], item) => {
            const { textSuggestion } = item;
            const hasSuggestion = uniqueSuggestions[textSuggestion];

            if (!hasSuggestion) {
              uniqueSuggestions[textSuggestion] = true;

              result.push(item);
            }
            return result;
          }, [])
          : suggestions;
      };

      const onChangeValue = (event: Change) => {
        onChange(event);
        if (field.onChangeValue) {
          field.onChangeValue(event.currentTarget.value, inputProps.onChange);
        }
      };

      return (
        <SuggestionsInput
          {...inputProps}
          onChange={onChangeValue}
          getSuggestions={fetchSuggestions}
          as={type}
          setFieldTouched={setFieldTouched}
          autocompleateFields={autocompleateFields}
          openHiddenFields={openHiddenFields}
        />
      );
    }
    return <Input
      {...inputProps}
      autoFocus={field.name === 'purchase.price'}
      onFocus={() => {
        if (field.name === 'purchase.price') {
          onChange(getFakeEvent(null, field.name) as Change);
        }
      }}
      onBlur={(event) => {
        if (inputProps.onBlur) {
          inputProps.onBlur(event);
        }

        if (field.name === 'purchase.price' && !event.currentTarget.value) {
          onChange(getFakeEvent(null, field.name) as Change);
        } else if (field.name === 'purchase.price' && event.currentTarget.value) {
          const fixedValue = Number(event.currentTarget.value).toFixed(2);
          onChange(getFakeEvent(fixedValue, field.name) as Change);
        }
      }}
    />;
  };
}

function getRadioButton({
  radioButtons,
  description,
  name
}: IField): FC<IFormFieldProps> {
  return ({ value, onChange }: IFormFieldProps) => {
    if (radioButtons && size(radioButtons)) {
      return (
        <RadioButtonContainer
          options={radioButtons}
          selectedOption={value}
          description={description || undefined}
          onChange={(newValue) => onChange(getFakeEvent(newValue, name) as Change)}
        />
      );
    }
    return null;
  };
}

function getCheckbox(field: IField): FC<IFormFieldProps> {
  const {
    name,
    placeholder,
    initialValue,
    disabled,
    onChangeValue,
    styles
  } = field;
  return ({
    onChange,
    error,
    value
  }: IFormFieldProps) => {
    const checkBoxValue = isUndefined(value)
      ? initialValue
      : value;
    return (
      <CheckBox
        placeholder={placeholder}
        checked={Boolean(checkBoxValue)}
        onChange={(event: Change) => {
          onChange(event);
          if (onChangeValue) {
            onChangeValue(!checkBoxValue);
          }
        }}
        name={name}
        error={error}
        disabled={disabled}
        styles={styles}
      />
    );
  };
}

const getContentField = ({ content: Content }: IField) => (
  { submitForm }: IFormFieldProps
) => <>{Content && <Content nextForm={submitForm} />}</>;
