import { IField } from 'interfaces';

const email: IField = {
  name: 'email',
  type: 'text',
  placeholder: 'Эл. почта',
  initialValue: ''
};

const phone: IField = {
  name: 'phone',
  type: 'phone',
  placeholder: 'Номер телефона',
  initialValue: ''
};

const contacts = { email, phone };

export default contacts;
