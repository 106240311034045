import { IItemProps } from './interfaces';

import S from './styles';

export const Item = ({ title, children }: IItemProps) => (
  <S.Item data-test="header-item">
    <div className="content">
      <div data-test="header-item-title" className="title">
        {title}
      </div>
      <div data-test="header-item-value" className="value">
        {children}
      </div>
    </div>
  </S.Item>
);
