import { IField } from 'interfaces';

const agree: IField = {
  name: 'agree',
  type: 'checkbox',
  placeholder: (
    <span>
      Я согласен(-а) на{' '}
      <a
        href="https://www.veles-management.ru/uploadedfiles/ConsentProcessingPersonaData.pdf"
        target="_blank"
        rel="noopener noreferrer"
        style={{ fontSize: '14px' }}
      >
        обработку персональных данных
      </a>
    </span>
  ),
  initialValue: false
};

const checkboxes = { agree };

export default checkboxes;
