import { removePhoneMask } from 'helpers/phone/phoneMask';

import { IMaskObject } from './interfaces';

export const AvailableMasks: IMaskObject = {
  phone: {
    mask: '+7 999 999 99 99',
    getUnmaskedValue: (maskedValue) => removePhoneMask(maskedValue)
  },
  cardNumber: {
    mask: '9999-9999-9999-9999 99',
    getUnmaskedValue: (maskedValue) => maskedValue.replace(/\-/g, '').replace(/\ /g, '')
  },
  passport: {
    mask: '99 99 999999',
    getUnmaskedValue: (maskedValue) => {
      if (maskedValue.length && !maskedValue.includes('_')) {
        const newValue = maskedValue.replace(/(\D|_)/g, '')
        return newValue.slice(0, 4) + ' ' + newValue.slice(4, 10)
      }
      return maskedValue.replace(/(\D|_)/g, '')
    }
  },
  unitCode: {
    mask: '999 – 999',
    getUnmaskedValue: (maskedValue) => {
      if (maskedValue.length && !maskedValue.includes('_')) {
        return maskedValue.replace(/ – /g, '')
      }
      return maskedValue.replace(/\D/g, '')
    }
  },
  date: {
    mask: '99.99.9999',
    getUnmaskedValue: (maskedValue) => {
      if (maskedValue.length && !maskedValue.includes('_')) {
        return maskedValue
      }
      return maskedValue.replace(/(\D|_)/g, '')
    }
  },
  inn: {
    mask: '999999999999',
    getUnmaskedValue: (maskedValue) => maskedValue.replace(/(\D|_)/g, '')
    ,
  },
  bankBik: {
    mask: '999999999',
    getUnmaskedValue: (maskedValue) => maskedValue.replace(/(\D|_)/g, '')
    ,
  },
  correspondentAccount: {
    mask: '99999999999999999999',
    getUnmaskedValue: (maskedValue) => maskedValue.replace(/(\D|_)/g, '')
    ,
  },
  checkingAccount: {
    mask: '99999999999999999999',
    getUnmaskedValue: (maskedValue) => maskedValue.replace(/(\D|_)/g, '')
    ,
  },
};
