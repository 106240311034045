import cloneDeep from 'lodash/cloneDeep';

import { FormField } from 'entities/formField';
import { setInitialValueFromState } from 'helpers/form/setInitialValueFromState';

import { contactDetailsFields } from '../contactDetails';
import { passportDetailsField } from '../passportDetails';

const clonePassport = cloneDeep(passportDetailsField);
const cloneContact = cloneDeep(contactDetailsFields);

cloneContact.email.setModifier('getInitialValueFromState', true);

const {
  unitCode,
  issuesDate,
  issuesPlace,
  placeBirth,
  addressString
} = clonePassport;

setInitialValueFromState(clonePassport);

const latinName = new FormField(
  {
    name: 'latinName',
    type: 'text',
    placeholder: 'Имя и фамилия латиницей',
    initialValue: ''
  },
  { isRequired: true, getInitialValueFromState: true }
);
export const editUserDataFields = {
  latinName,
  unitCode,
  issuesPlace,
  issuesDate,
  placeBirth,
  addressString,
  email: cloneContact.email
};
