import {
  takeLatest,
  put,
  call,
  select
} from 'redux-saga/effects';

import { actionTypes } from 'store/actionTypes';
import { IState } from 'interfaces';
import { api } from 'api';
import { Url } from 'api/url';
import { getPurchaseHeaders } from 'api/options';
import { formatDate } from 'helpers/date';

import { host } from './sendPurshaseData';

const {
  REQUEST,
  SUCCESS,
  ERROR
} = actionTypes.CHECK_USER_EXIST;

export function* userExist() {
  yield takeLatest(REQUEST, checkUserExist);
}
/* Todo сага отвечает за проверку наличия такого продукта у клиента(свмого клиента?)?? */
function* checkUserExist() {
  try {
    const state:IState = yield select();
    const { productId } = state.purchase.data;
    // @ts-expect-error
    const response = yield call(
    // @ts-expect-error
      api.get,
      {
        host,
        url: Url.checkClient,
        urlParams: {
          product_id: productId
        }
      },
      getPurchaseHeaders()
    );
    const order = response
      ? {
        date: formatDate(response.date, 'DD.MM.YYYY', 'YYYY-MM-DD'),
        number: response.number,
        files: {
          niceName: response.files[0].niceName,
          file: response.files[0].file
        }
      }
      : null;
    const applicantId = response ? response.applicantId : '';

    yield put({ type: SUCCESS, payload: { order, applicantId } });
  } catch (error) {
    yield put({ type: ERROR, payload: error });
    yield put({ type: actionTypes.PURCHASE.REDIRECT_ERROR, payload: error });
  }
}
