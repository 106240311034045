import size from 'lodash/size';

// eslint-disable-next-line import/no-cycle
import {
  contactDetails,
  innAndPassportNumber,
  passportDetails,
  docsManagement,
  checkData,
  checkUserExist,
  checkEdoExistStatus
} from '.';
import { acquiring, successAcquiring } from './acquiring';
import { additionalPurchase } from './additionalPurchase';

const first = [contactDetails];
const second = [innAndPassportNumber, passportDetails, checkEdoExistStatus];
const third = [docsManagement, checkUserExist, checkData];
const fourth = [acquiring, successAcquiring];

export const opifPurchase = [first, second, third, fourth];

export const purchaseSteps = [
  'Контактные данные',
  'Паспортные данные',
  'Подписание документов',
  'Оплата'
];

export const switchToRebuy = () => {
  if (size(third) === 3) {
    third[2] = additionalPurchase;
  }
  if (size(third) === 2) {
    third[1] = additionalPurchase;
  }
};

export const switchForEdo = () => {
  third.splice(0, 1);
};
