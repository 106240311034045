import { IProductData } from './interfaces';

export function getProductLink(productId: number | string) {
  const linkVeles = 'https://www.veles-management.ru';
  const linkOpif = 'https://opif.veles-management.ru';
  const linkVelesExtend = `${linkVeles}/ru/AssetManagement`;
  if (!productId) {
    return '';
  }
  switch (productId) {
    case 16:
      return `${linkOpif}/global#global`;
    case 17:
      // return `${linkOpif}/system#system`;
      return `${linkVeles}/information_disclosure#b1023`;
    case 18:
      return `${linkOpif}/classic#classic`;
    case 30:
      return `${linkVelesExtend}/RossiyskieAktsiiBalans`;
    case 31:
      return `${linkVelesExtend}/RossiyskieAktsiiRisk`;
    case 32:
      return `${linkVelesExtend}/AmerikanskieAktsiiBalans`;
    case 33:
      return `${linkVelesExtend}/AmerikanskieAktsiiRisk`;
    case 34:
      return `${linkVeles}`;
    default:
      return '';
  }
}

export const getData = (id:number| string):IProductData => {
  switch (id) {
    case 16:
      return {
        subTitle: 'ОПИФ РФИ',
        rand: 6,
        rand_percent: 29.67,
        rand3_percent: -11.73,
        rand6_percent: -8.85,
        rand12_percent: -7.48,
        type: 'opif'
      };
    case 17:
      return {
        subTitle: 'ЗПИФ РФИ',
        rand: 3,
        rand_percent: -14.82,
        rand3_percent: -33.16,
        rand6_percent: -32.39,
        rand12_percent: -35.75,
        type: 'zpif'
      };
    case 18:
      return {
        subTitle: 'ОПИФ РФИ',
        rand: 1,
        rand_percent: 7.61,
        rand3_percent: -6.23,
        rand6_percent: -6.42,
        rand12_percent: -5.65,
        type: 'opif'
      };
    case 30:
      return {
        subTitle: 'Стратегия ДУ',
        rand: 4,
        rand_percent: 20,
        rand3_percent: -1.05,
        rand6_percent: -1.05,
        rand12_percent: -1.05,
        type: 'du',
        rand_prefix: 'до'
      };
    case 31:
      return {
        subTitle: 'Стратегия ДУ',
        rand: 6,
        rand_percent: 20,
        rand3_percent: -1.48,
        rand6_percent: -1.48,
        rand12_percent: -1.48,
        type: 'du',
        rand_prefix: 'от'
      };
    case 32:
      return {
        subTitle: 'Стратегия ДУ',
        rand: 4,
        rand_percent: 10,
        rand3_percent: -0.75,
        rand6_percent: -0.75,
        rand12_percent: -0.75,
        type: 'du',
        rand_prefix: 'до'
      };
    case 34:
    case 33:
      return {
        subTitle: 'Стратегия ДУ',
        rand: 6,
        rand_percent: 10,
        rand3_percent: -11.17,
        rand6_percent: -11.17,
        rand12_percent: -11.17,
        type: 'du',
        rand_prefix: 'от'
      };
    case 35:
      return {
        subTitle: 'ЗПИФ комбинированный',
        rand: 6,
        type: 'zpif'
      };
    default: return {
      subTitle: '',
      rand: 2,
      rand_prefix: '',
      rand_percent: Math.floor(Math.random() * (30 - 10) + 10),
      rand3_percent: 1.41,
      rand6_percent: 1.41,
      rand12_percent: 1.41,
      type: ''
    };
  }
};
export const getProductTitle = (id: string): string => {
  switch (id) {
    case '17':
      return 'Велес — Системный';
    case '18':
      return 'Велес — Классический';
    case '30':
      return 'Российские акции — баланс';
    case '31':
      return 'Российские акции — риск';
    case '32':
      return 'Американские акции — баланс';
    case '33':
      return 'Американские акции — риск';
    case '34':
      return 'Smart Equity';
    case '35':
      return 'Рентавед Столица';
    default:
      return '';
  }
};
