import styled from 'styled-components';

import StylesInput from 'components/shared/input/styles';
import StylesCheckbox from 'components/shared/checkbox/styles';
import { Styles } from 'components/shared';
import StylesRadio, { radioBefore } from 'components/shared/radioButton/styles';
import { FormWidth } from 'assets/styles/mixins';
import { media } from 'assets/styles/theme';

import StylesProductButton from '../portfolioCharts/productButton/styles';
import StylesEsiaButton from '../esiaButton/styles';
import StylesPassportNotification from '../applicationForms/innAndPassportNumber/passportNotification/styles';

interface IHiddenFields {
  isShow?: boolean;
}

const { ProductButton } = StylesProductButton;
const { Checkbox } = StylesCheckbox;
const { Input } = StylesInput;
const { EsiaButton } = StylesEsiaButton;
const { PassportNotification } = StylesPassportNotification;
const { RadioButton } = StylesRadio;

const GroupTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
  svg {
    margin-left: 10px;
    * {
      fill: ${({ theme }) => theme.colors.link};
    }
  }
  ${media.mobile} {
    margin-top: 30px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%; 
`;

const FieldContainer = styled.div<IFieldContainer>`
  position: relative;
  width: 100%;
  ${media.desktop} {
    width: ${({ halfWidth }) => (halfWidth ? '171px' : '100%')};
  }
  ${media.tablet} {
    width: ${({ halfWidth }) => (halfWidth ? '154px' : '100%')};
  }
  ${media.mobile} {
    width: 100%;
  }
  ${Input} {
    margin-bottom: 22px;
    ${media.tablet} {
      margin-bottom: 24px;
    }
  }
  ${Styles.Button} {
    text-align: center;
    padding: 16px 23px;
    &[type="submit"] {
      display: block; 
      margin: 0 auto; 
    }
    ${media.mobile} {
      width: 100%;
      height: 52px;
      justify-content: center;
    }
  }
`;

const buttonShowAddress = styled.a`
  font-size: 14px;
  position: absolute;
  right: 0;
  z-index: 1;
  top: -26px;
  text-decoration: none;
  cursor: pointer;
`;

const FormComponent = styled.div`
  ${EsiaButton} {
    margin-bottom: 48px;
    ${media.mobile} {
      margin-bottom: 26px;
    }
  }
  ${ProductButton} {
    margin-bottom: 36px;
    margin-top: 10px;
    ${media.tablet} {
      margin-bottom: 30px;
      margin-top: -10px;
    }
    ${media.mobile} {
      width: 100%;
      justify-content: center;
      margin-top: 0;
    }
  }
  ${PassportNotification} {
    margin-top: 12px;
    margin-bottom: 22px;
    ${media.mobile} {
      margin-bottom: 12px;
    }
  }
  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    ${FieldContainer}:first-child {
      ${GroupTitle} {
        margin-top: 0;
      }
    }
    ${RadioButton},
    ${Checkbox} {
      margin: 27px 0;
      ${media.mobile} {
        margin: 12px 0;
      }
    }
    ${RadioButton} {
      margin: 10px 0 30px;

      :not(:last-child) {
        margin-right: 30px;
      }

      label {
        color: ${({ theme }) => theme.colors.textPrimary};
        font-size: 14px;
        line-height: 20px;
        position: relative;
        :before {
          ${({ theme }) => radioBefore(theme, false)}
        }
      }
      input:checked ~ label {
        :before {
          ${({ theme }) => radioBefore(theme, true)}
        }
      }
    }

  }
  ${FormWidth}
  ${media.mobile} {
    border-radius: 0;
  }
`;

interface IFieldContainer {
  halfWidth?: boolean;
}

const Separator = styled.div`
  width: 1px;
  height: 30px;
  ${media.tablet} {
    height: 24px;
  }
  ${media.mobile} {
    height: 14px;
  }
`;

const HiddenFields = styled(FieldsContainer)<IHiddenFields>`
  ${({ isShow }) => (isShow ? '' : 'overflow: hidden')};
  max-height: ${({ isShow }) => (isShow ? '1000px' : '0')};
  transition-property: max-height;
  position: relative;
  transition-timing-function: ease-out;
  transition-duration: 0.5s;
  ${FieldContainer} {
    :first-child {
      margin-top: 5px;
    }
  }
`;

export default {
  Separator,
  FormComponent,
  FieldContainer,
  FieldsContainer,
  HiddenFields,
  GroupTitle,
  buttonShowAddress
};
