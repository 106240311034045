import { IAction } from 'interfaces';
import { ISuggestionResponse } from 'helpers/dadata';
import {
  initialState,
  ISuggestionsInputState,
  suggestionsActions
} from './interfaces';

export const suggestionsReducer = (
  state = initialState,
  action: IAction
): ISuggestionsInputState => {
  const { type, payload } = action;
  const {
    setSuggestions,
    setEvent,
    showSuggestions,
    hideSuggestions
  } = suggestionsActions;
  switch (type) {
    case setEvent: {
      if (payload) {
        return <ISuggestionsInputState>{
          ...state,
          event: payload
        };
      }
      return state;
    }
    case setSuggestions: {
      if (payload) {
        return {
          ...state,
          suggestions: payload as ISuggestionResponse[]
        };
      }
      return state;
    }
    case showSuggestions: {
      if (state.suggestions.length) {
        return {
          ...state,
          showSuggestions: true
        };
      }
      return {
        ...state,
        showSuggestions: false
      };
    }
    case hideSuggestions: {
      return {
        ...state,
        showSuggestions: false
      };
    }
    default:
      return state;
  }
};
