import styled from 'styled-components';

import HeaderStyles from 'components/widgets/loginHeader/styles';
import S from 'components/widgets/footer/styles';
import { Styles } from 'components/shared';
import { gridTheme, media } from 'assets/styles/theme';
import { widthWindow } from 'assets/styles/mixins';

const { Header } = HeaderStyles;

interface IPurchaseProps {
  isMenuActive?: boolean;
}

const Purchase = styled.div<IPurchaseProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};

  ${media.mobile} {
    min-height: 100%;
  }

  ${Header} {
    box-shadow: 0 5px 10px rgba(83, 119, 138, 0.05);
    width: 100%;
  }
  ${Styles.H1} {
    text-align: center;
    margin:  ${({ isMenuActive }) => (isMenuActive ? '114px' : '62px')} 0 53px;
    line-height: 36px;
    ${media.mobile} {
       margin-top:  ${({ isMenuActive }) => (isMenuActive ? '80px' : '28px')};
       margin-bottom: 34px;
    }
  }
  ${S.FooterStyle} {
    max-width: ${gridTheme.container.maxWidth.xl}px;
    margin: 0 auto 0;

    [data-name="container"] {
      max-width: none;

      ${media.desktop} {
        padding: 30px 0;
      }

      ${media.tabletOnly} {
        padding: 30px 15px 0;
      }
    }
    ${media.tablet} {
      padding-bottom: 90px;
    }
    ${media.mobile} {
      margin-top: 0;
    }
  }

  ${S.DetailedInformationStyle} {
    padding: 0;

    ${media.tablet} {
      padding: 0 15px;
    }
  }

  .loader-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: ${widthWindow}px;
  }
`;

export default { Purchase };
