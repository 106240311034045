import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import { store } from './store/configureStore';
import { GlobalStyles } from './assets/styles/globalStyles';
import { theme, gridTheme } from './assets/styles/theme';
import { Styles } from './components/shared';
import { TestSuccess } from './components/widgets/applicationForms/acquiring';
import {
  Purchase,
  Products,
  NotFound
} from './components/pages';

const App = () => (
  <BrowserRouter>
    <Provider store={store}>
      <BaseCSS />
      <GlobalStyles />
      <GridThemeProvider gridTheme={gridTheme}>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<Products />} />
            <Route
              path="purchase/:productType/:productId/:step/:form"
              element={<Purchase />}
            />
            {/* данный роут нужен для редиректа после логинизации через госсулуги */}
            <Route
              path="purchase/:productType/:productId"
              element={<Purchase />}
            />
            <Route path="/payment-success" element={<TestSuccess />} />
            <Route
              path="payment-notification"
              element={<Styles.ExtraText>Что-то пошло не так</Styles.ExtraText>}
            />
            <Route
              path="payment-error"
              element={<Styles.ExtraText>Кажется, все пропало</Styles.ExtraText>}
            />
            <Route
              path="*"
              element={<NotFound />}
            />
          </Routes>
        </ThemeProvider>
      </GridThemeProvider>
    </Provider>
  </BrowserRouter>
)
export default App;
