import styled, { css } from 'styled-components';

import {
  InputPlaceholderTop,
  InputHidden,
  InputDisabled
} from 'assets/styles/mixins';
import { ITheme, theme } from 'assets/styles/theme';

interface IStylesInputProps {
  theme: ITheme;
  hasError?: boolean;
}

const Hint = styled.div`
  position: absolute;
  right: 17px;
  top: 15px;
  overflow: visible;
  cursor: pointer;
  * {
    text-decoration: none;
  }
  transition: opacity 0.35s;
`;
const TextArea = styled.textarea`
  resize: none;
  height: 100px;
  width: 100%;
  font-size: 16px;
  border: 1px solid;
  border-radius: ${theme.borderRadius};
  padding: 14px 16px;
  color: ${theme.colors.textPrimary};
  letter-spacing: 0.06px;
  background: ${theme.colors.background};
  outline: none;
  line-height: 20px;
  :focus {
    background: transparent;
  }
  transition-duration: 0.35s;
  transition-property: border-color;

  :not(:focus):not([data-value=""]),
  :focus {
    ~ label {
      ${InputPlaceholderTop}
    }
  }
  :focus :not([data-value=""]):not(:focus) {
    background-color: ${theme.colors.background};
  }
  :not([data-value=""]) {
    background-color: ${theme.colors.white};
  }
  :disabled {
    height: auto;
  }
  ${InputDisabled}
`;

const Input = styled.div<IStylesInputProps>`
  position: relative;
  width: 100%;
  input:not([value=""]) ~ ${Hint} {
    opacity: 0;
    height: 0;
  }
  input {
    height: 50px;
    width: 100%;
    border: 1px solid;

    border-color: ${({ hasError }) => (hasError ? theme.colors.buttonPrimary : theme.colors.background)};

    border-radius: ${theme.borderRadius};

    padding: 14px 16px;

    color: ${theme.colors.textPrimary};

    background: ${theme.colors.background};

    letter-spacing: 0.06px;
    font-size: 16px;

    outline: none;
    transition-duration: 0.35s;
    transition-property: border-color;

    :focus {
      background: transparent;
      border-color: ${({ hasError }) => (hasError ? theme.colors.buttonPrimary : '#edece6')};
    }
    transition-duration: 0.35s;
    transition-property: border-color;
    :not(:focus):not([value=""]),
    :focus {
      ~ label {
        ${InputPlaceholderTop}
      }
    }
    :focus :not([value=""]):not(:focus) {
      background-color: ${theme.colors.background};
    }
    :not([value=""]) {
      background-color: ${theme.colors.white};
      border-color: ${({ hasError }) => (hasError ? theme.colors.buttonPrimary : theme.colors.border)};
    }
    &[name="purchase.price"] {
      height: 75px;
      font-size: 30px;
      line-height: 37px;

      & + label {
        top: 50%;
        transform: translateY(-50%);
      }

      &[value=""] + label {
        ${InputPlaceholderTop}
      }

      :focus {
        ~ label {
          ${InputPlaceholderTop}
        }
      }
    }
    &[type="password"]::-ms-reveal,
    &[type="password"]::-ms-clear {
      display: none;
    }
    &::-webkit-validation-bubble {
      display: none;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::-ms-clear {
      display: none;
    }
    &[type="number"] {
      -moz-appearance: textfield;
      margin: 0;
    }
    ${InputDisabled}
  }
  ${TextArea} {
    border-color: ${({ hasError }) => (hasError ? theme.colors.buttonPrimary : theme.colors.background)};
    :focus {
      border-color: ${({ hasError }) => (hasError ? theme.colors.buttonPrimary : '#edece6')};
    }
    :not([data-value=""]) {
      border-color: ${({ hasError }) => (hasError ? theme.colors.buttonPrimary : theme.colors.border)};
    }
  }
  label {
    font-size: 17px;
    color: ${({ hasError }) => (hasError ? theme.colors.buttonPrimary : theme.colors.textExtra)};
    position: absolute;
    top: 15px;
    left: 16px;
    display: block;
    transition-duration: 0.3s;
    transition-property: top, left, font-size, padding;
    cursor: text;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: ${theme.colors.textPrimary};
    & + label {
      ${InputPlaceholderTop}
    }
  }
  ${({ hidden }) => (hidden ? InputHidden : '')}
`;
const Description = styled.span`
  display: block;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.06px;
  color: ${theme.colors.description};
  margin-top: 5px;
  margin-left: 16px;
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
`;
const Error = styled(Description)<IStylesInputProps>`
  color: ${theme.colors.buttonPrimary} !important;
  width: 100%;
  max-height: ${({ hasError }) => (hasError ? '36px' : '0px')};
  transition-property: max-height;
  transition-duration: 0.3s;
  margin-top: ${({ hasError }) => (hasError ? '5px' : '0px')};
  margin-left: 16px;
  overflow: hidden;
`;

interface ITextField {
  hasPlaceholder?: boolean;
}

const TextField = styled.div<ITextField>`
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  ${(hasPlaceholder) => (hasPlaceholder
    ? ''
    : css`
          margin-top: -26px;
        `)}
  .placeholder {
    color: ${theme.colors.description};
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.056px;
    margin-bottom: 10px;
  }
  .value {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: ${theme.colors.textPrimary};
    white-space: pre-line;
    & ~ .value {
      margin-top: 10px;
    }
  }
`;
export default {
  Input,
  Error,
  Description,
  Info,
  Hint,
  TextArea,
  TextField
};
