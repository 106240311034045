import styled, { css } from 'styled-components';

import { Styles } from 'components/shared';
import { media } from 'assets/styles/theme';
// @ts-expect-error
import Thumbnail16 from 'assets/img/thumbnails/16.jpg';
// @ts-expect-error
import Thumbnail17 from 'assets/img/thumbnails/17.jpg';
// @ts-expect-error
import Thumbnail18 from 'assets/img/thumbnails/18.jpg';
// @ts-expect-error
import Thumbnail30 from 'assets/img/thumbnails/30.jpg';
// @ts-expect-error
import Thumbnail31 from 'assets/img/thumbnails/31.jpg';
// @ts-expect-error
import Thumbnail32 from 'assets/img/thumbnails/32.jpg';
// @ts-expect-error
import Thumbnail33 from 'assets/img/thumbnails/33.jpg';

const SubTitle = styled.span`
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 30px;
`;

const Title = styled.span`
  width: 120%;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  min-height: 30px;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.colors.textPrimary};
  ${media.mobile} {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
  }
`;

const InfoWrapper = styled.div`
  width: 75%;
`;

interface IProfitProps {
  isRisk?: boolean;
}
const Profit = styled.div<IProfitProps>`
  font-weight: 400;
  font-size: 12px;
  padding: 7px 0;
    ${({ isRisk }) => isRisk && css`
      padding-bottom: 0`}
      text-align: center;
`;

interface IProfitPercent {
  upOrDown: string;
}

const ProfitPercent = styled.div<IProfitPercent>`
  color: ${({ upOrDown }) => (upOrDown === 'up'
    ? '#058e16'
    : '#cc0019')
};
  font-size: 26px;
  padding-top: 3px;
  padding-bottom: 20px;
  margin-top: 0;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
`;

const ProfitDynamicWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const PeriodItem = styled.div`
  font-size: 12px;
  text-align: center;
  margin-bottom: 5px;
`;

const PeriodInfoWrapper = styled.div`
`;

const PercentItem = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const RiskIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
    ${media.mobile} {
      height: 30px;
    }
`;

const WrapperRisk = styled.div`
  border: solid 1px #ffffff;
  background: #fdfdfd;
  padding: 0;
  line-height: 0;
`;

const RiskItem = styled.span`
  display: inline-block;
  height: 14px;
  width: 7px;
  background: #d4d4d4;
  margin: 1px;
`;

const LinkMore = styled.a`
  text-decoration: underline;
  color: black;
  display: block;
    ${media.mobile} {
      font-size: 13px;
      line-height: 20px;
  }
`;

const ButtonForRules = styled.button`
`;
const BuyButton = styled(Styles.Button)`
  border: 0;
    ${media.mobile} {
      padding: 10px 15px;
      font-size: 15px;
      line-height: 19px;
  }
`;

const ProductContainer = styled.div`
   margin: 15px;
`;
const ProductWrapper = styled(Styles.Panel)`
  justify-content: space-between;
  height: 397px;
  padding: 40px 30px;
  width: 370px;
  
  &.thumbnail16 {
    background-image: url(${Thumbnail16});
    background-size: cover;
  }
  
  &.thumbnail17 {
    background-image: url(${Thumbnail17});
    background-size: cover;
  }
    
  &.thumbnail18 {
    background-image: url(${Thumbnail18});
    background-size: cover;
  }
  
  &.thumbnail30 {
    background-image: url(${Thumbnail30});
    background-size: cover;
  }  
  
  &.thumbnail31 {
    background-image: url(${Thumbnail31});
    background-size: cover;
  }  
  
  &.thumbnail32 {
    background-image: url(${Thumbnail32});
    background-size: cover;
  }
      
  &.thumbnail33 {
    background-image: url(${Thumbnail33});
    background-size: cover;
  }  
  
  &.thumbnail34 {
    background-image: url(${Thumbnail16});
    background-size: cover;
  }  
  
  &.thumbnail35 {
    background-image: url(${Thumbnail31});
    background-size: cover;
  }
  
  .profit-progress-1 > div span:nth-child(n+0):nth-child(-n+1),
  .profit-progress-2 > div span:nth-child(n+0):nth-child(-n+2),
  .profit-progress-3 > div span:nth-child(n+0):nth-child(-n+3),
  .profit-progress-4 > div span:nth-child(n+0):nth-child(-n+4),
  .profit-progress-5 > div span:nth-child(n+0):nth-child(-n+5),
  .profit-progress-6 > div span:nth-child(n+0):nth-child(-n+6)
  {
    background: orange;
  }
  
    ${media.mobile} {
      width: 340px;
      height: 345px;
      border-radius: 10px;
      padding: 25px 16px;
      box-shadow: 0 5px 10px 0 ${({ theme }) => theme.colors.shadow};
    }
`;

export default {
  BuyButton,
  ProductWrapper,
  SubTitle,
  Title,
  InfoWrapper,
  Profit,
  ProfitPercent,
  ProfitDynamicWrapper,
  PeriodItem,
  PeriodInfoWrapper,
  PercentItem,
  WrapperRisk,
  RiskItem,
  LinkMore,
  RiskIndicator,
  ButtonForRules,
  ProductContainer
};
