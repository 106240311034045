import styled, { css } from 'styled-components';

import { ITheme } from 'assets/styles/theme';

const RadioButton = styled.div`
  display: flex;
  input {
    height: 0;
    width: 0;
    opacity: 0;
    position: absolute;
  }
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  input:checked ~ label {
    cursor: default;
  }
`;

export const radioBefore = (theme: ITheme, checked: boolean) => css`
  content: "";
  box-sizing: content-box;
  display: block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: background 0.1s ease, border-color 0.1s ease;
  border-width: 7px;
  border-style: solid;
  border-color: ${checked ? theme.colors.link : theme.colors.background};
  background: ${checked ? theme.colors.white : theme.colors.background};
  margin-right: 13px;
  flex-shrink: 0;
`;

export default { RadioButton };
