import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { actionCreator } from 'helpers/actions/actionCreator';
import { actionTypes } from 'store/actionTypes';

import StylesProductButton from '../../../portfolioCharts/productButton/styles';

const { ProductButton } = StylesProductButton;

const StylesEditButton = styled(ProductButton)`
  margin-left: 0px !important;
`;

export const EditButton = () => {
  const dispatch = useDispatch()
  return (
    <StylesEditButton type="button" onClick={() => dispatch(actionCreator(actionTypes.VIEW.EDIT_MODAL.SHOW))}>
      Изменить
    </StylesEditButton>
  )
}
