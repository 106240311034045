import { actionTypes } from 'store/actionTypes';
import { IAction } from 'interfaces';

const { SHOW, HIDE } = actionTypes.VIEW.EDIT_MODAL;
/* Todo выснить зачем этот редьюсер */
export const editModalReducer = (state: boolean = false, { type }: IAction) => {
  switch (type) {
    case SHOW:
      return true;
    case HIDE:
      return false;
    default:
      return state;
  }
};
