import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { IWizardForm } from 'interfaces';
import { FormField } from 'entities/formField';
import { widthWindow } from 'assets/styles/mixins';
import { useDidMount } from 'components/hooks/useDidMount';
import { useStore } from 'components/hooks/useStore';
import { actionTypes } from 'store/actionTypes';

// eslint-disable-next-line import/no-cycle
import { switchToRebuy } from '../opif';
import { LoaderTooltip } from '../../tooltips/loader';
import { ICheckComponentOwnProps } from './interfaces';

const checkExistField = new FormField({
  type: 'content',
  name: 'checkUserExist',
  placeholder: '',
  initialValue: ''
}, {
  content: ({ nextForm }) => <CheckComponent nextForm={nextForm} />
});

export const checkUserExist: IWizardForm = {
  fields: [checkExistField],
  title: 'Поиск пользователя',
  url: '',
  hiddenSubmit: true
};

const CheckComponent =({ nextForm }: ICheckComponentOwnProps) => {
  const {
    purchase: {
      checkUserExist: { loading, success },
      data: { order }
    }
  } = useStore()
  const dispatch = useDispatch();
  const checkingUserExist = () => dispatch({ type: actionTypes.CHECK_USER_EXIST.REQUEST })
  useDidMount(checkingUserExist);

  const hasOrder = !!order
  useEffect(() => {
    if (success && nextForm) {
      if (hasOrder) {
        switchToRebuy();
      }
      nextForm();
    }
  }, [success, hasOrder]);
  return (
    <>
      {loading && (
      <div
        className="loader-container"
        style={{ display: 'flex', height: `${widthWindow}px` }}
      >
        <LoaderTooltip isShow description="Пожалуйста, подождите..." />
      </div>
      )}
    </>);
};
