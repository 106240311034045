import styled from 'styled-components';

import { Styles } from 'components/shared';
import { media } from 'assets/styles/theme';
import S from 'components/widgets/footer/styles';
import HeaderStyles from 'components/widgets/loginHeader/styles';

const { Header } = HeaderStyles;

const ErrorPanel = styled(Styles.Panel)`
  width: 570px;
  margin: 60px 100px;
  align-self: center;
  h3 {
    text-align: center;
  }
  
  ${media.tablet} {
    width: 535px;
  }
  ${media.mobile} {
    margin: 0;
    padding: 30px 15px;
    width: 100%;
  }
`;
const LoginPage = styled.main`
  ${Header} {
    box-shadow: 0 5px 10px rgba(83, 119, 138, 0.05);
    width: 100%;
  }
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.background};

  ${S.FooterStyle} {
    margin-top: 40px;
  }
`;
export default { ErrorPanel, LoginPage }
