export const getProductLink = (productName:string):string => {
  switch (productName) {
    case 'Стандартная стратегия управления «Российские акции - баланс»':
      return 'https://www.veles-management.ru/uploadedfiles/56.%D0%92%D0%9C%20-%2027.10.2020%20-%20%D0%A1%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%D0%BD%D1%8B%D0%B5%20%D1%81%D1%82%D1%80%D0%B0%D1%82%D0%B5%D0%B3%D0%B8%D0%B8%20%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F%20-%20%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B5%20%D0%B0%D0%BA%D1%86%D0%B8%D0%B8%20-%20%D0%B1%D0%B0%D0%BB%D0%B0%D0%BD%D1%81.pdf'
    case 'Стандартная стратегия управления «Российские акции - риск»':
      return 'https://www.veles-management.ru/uploadedfiles/56.%D0%92%D0%9C%20-%2027.10.2020%20-%20%D0%A1%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%D0%BD%D1%8B%D0%B5%20%D1%81%D1%82%D1%80%D0%B0%D1%82%D0%B5%D0%B3%D0%B8%D0%B8%20%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F%20-%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B5%20%D0%B0%D0%BA%D1%86%D0%B8%D0%B8%20-%20%D1%80%D0%B8%D1%81%D0%BA.pdf'
    case 'Стандартная стратегия управления «Американские акции - баланс»':
      return 'https://www.veles-management.ru/uploadedfiles/56.%D0%92%D0%9C%20-%2027.10.2020%20-%20%D0%A1%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%D0%BD%D1%8B%D0%B5%20%D1%81%D1%82%D1%80%D0%B0%D1%82%D0%B5%D0%B3%D0%B8%D0%B8%20%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F%20-%D0%90%D0%BC%D0%B5%D1%80%D0%B8%D0%BA%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B5%20%D0%B0%D0%BA%D1%86%D0%B8%D0%B8%20-%20%D0%B1%D0%B0%D0%BB%D0%B0%D0%BD%D1%81.pdf'
    case 'Стандартная стратегия управления «Американские акции - риск»':
      return 'https://www.veles-management.ru/uploadedfiles/56.%D0%92%D0%9C%20-%2027.10.2020%20-%20%D0%A1%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%D0%BD%D1%8B%D0%B5%20%D1%81%D1%82%D1%80%D0%B0%D1%82%D0%B5%D0%B3%D0%B8%D0%B8%20%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F%20-%20%D0%90%D0%BC%D0%B5%D1%80%D0%B8%D0%BA%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B5%20%D0%B0%D0%BA%D1%86%D0%B8%D0%B8%20-%20%D1%80%D0%B8%D1%81%D0%BA.pdf'
    default:
      return ''
  }
}
