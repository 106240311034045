import {
  takeEvery,
  put,
  call
} from 'redux-saga/effects';

import { api } from 'api';
import { actionTypes } from 'store/actionTypes';
import { Url } from 'api/url';
import { getPurchaseHeaders } from 'api/options';
import { IAgreementSigned } from './interfaces';

const { REQUEST, SUCCESS, ERROR } = actionTypes.PURCHASE.EDO.CHECK_STATUS;

function* fetchEdoExistStatus() {
  try {
    const params = {
      url: Url.purchase.edo.check
    };

    const edoExistStatus: IAgreementSigned = yield call(
      api.get,
      params,
      getPurchaseHeaders()
    );
    yield put({
      type: SUCCESS,
      payload: edoExistStatus.edoExistStatus
    });
  } catch (e) {
    yield put({
      type: ERROR,
      message: e.message
    });
  }
}
export function* getEdoExistStatus() {
  yield takeEvery(
    REQUEST,
    fetchEdoExistStatus
  );
}
