import styled from 'styled-components';

import { ITransitionHeight } from './interfaces';

const Wrapper = styled.div<ITransitionHeight>`
  height: ${({ height }) => height};
  overflow: hidden;
  transition: height 0.35s ease-in-out;
`;
export default { Wrapper }
