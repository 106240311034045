export const notesInfo = [
  `* Допустимый риск. В соответствии со стандартным инвестиционным
   профилем соответствующей стратегии управления для стратегии
   «Российские акции - баланс» – повышенный. Уровень
   допустимого риска – это уровень возможных убытков учредителя
   управления, связанных с доверительным управлением ценными
   бумагами и денежными средствами, при этом указанная
   величина допустимого риска не является гарантией
   ООО «УК ВЕЛЕС Менеджмент», что реальные потери
   учредителя управления не превысят указанного значения.`,

  `** Ожидаемая доходность. В соответствии со стандартным
  инвестиционным профилем соответствующей стратегии
  управления. Ожидаемая доходность не накладывает на
  ООО «УК ВЕЛЕС Менеджмент» обязанности по ее достижению
  и не является гарантией получения такой доходности
  для учредителя управления.`
];
