import { useState, useEffect } from 'react';

import axios, { AxiosResponse } from 'axios';

import { useDidMount } from 'components/hooks/useDidMount';
import { useNotRespond } from 'components/hooks/useNotRespond';
import { host } from 'api/url';
import { getPurchaseHeaders } from 'api/options';

import { AcquiringError } from '../acquiringError';
import { ERRORS_BY_CODE } from '../errors';
import { IGetStatusResponse, IPaymentFrameProps } from './interfaces';

export const PaymentFrame = ({ productId, onSuccess }: IPaymentFrameProps) => {
  const [paymentUrl, setPaymentUrl] = useState<string>('');
  const [orderId, setOrderId] = useState<string>('');
  const [orderStatus, setOrderStatus] = useState<number>(0);
  const [errorCode, setErrorCode] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showError, setShowError] = useState(false);
  const [tryCount, setTryCount] = useState(0);
  /* todo что за ширина */
  const [, setWidth] = useState(0);

  const { setNotRespond } = useNotRespond()

  useDidMount(() => {
    setState();
    setWidth(
      document.body.clientWidth < 450
        ? document.body.clientWidth
        : 450
    );
  });

  useEffect(() => {
    handleStatusesUpdate();
  }, [orderStatus, errorCode]);

  async function setState() {
    if (tryCount < 5) {
      try {
        const { formUrl, orderId: oi } = await initRequest(tryCount);
        setPaymentUrl(formUrl);
        setOrderId(oi);
        setTryCount(tryCount + 1);
      } catch (e) {
        setErrorMessage('При проведении оплаты произошла ошибка');
        handleError();
      }
    } else {
      setNotRespond();
    }
  }

  function handleStatusesUpdate() {
    if (orderStatus === 2) {
      handleSuccess();
    } else if (errorCode !== 0) {
      handleError();
    }
  }

  function handleSuccess() {
    onSuccess();
  }

  function handleError() {
    setShowError(true);
  }

  function handleOnLoad() {
    if (productId && orderId) {
      getStatus(productId, orderId).then((res) => {
        setOrderStatus(res.orderStatus);
        setErrorCode(res.errorCode);
        const err = ERRORS_BY_CODE[res.errorCode];
        if (err) {
          setErrorMessage(err);
        } else {
          setErrorMessage(res.errorMessage);
        }
      });
    }
  }

  async function repeat() {
    try {
      setState();
    } catch (e) {
      setNotRespond();
    }
    setShowError(false);
    setErrorCode(0);
    setOrderStatus(0);
  }

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
          src={paymentUrl}
          height={800}
          style={{ border: 'none', width: 'calc(100% + 30px)', marginLeft: '-15px' }}
          onLoad={() => handleOnLoad()}
      />
      <AcquiringError
          isShow={showError}
          errorMessage={errorMessage}
          repeat={repeat}
      />
    </div>
  );
};

async function initRequest(
  tryCount: number
): Promise<{ orderId: string; formUrl: string }> {
  try {
    const response = await axios.get(`${host}api-acquire/get-url-form`, {
      headers: { ...getPurchaseHeaders() },
      params: {
        try: tryCount
      }
    });
    return response.data;
  } catch (e) {
    throw e;
  }
}

async function getStatus(
  productId: string,
  orderId: string
): Promise<IGetStatusResponse> {
  const res: AxiosResponse<IGetStatusResponse> = await axios.get(
    `${host}api-acquire/get-status/${productId}`,
    {
      params: { orderId },
      headers: getPurchaseHeaders()
    }
  );

  return res.data;
}
