import replace from 'lodash/replace';

import { useLockBodyScroll } from 'components/hooks/useLockBodyScroll';

import { CommonTooltip } from '../../../shared';
import { IProps } from './interfaces';

import S from './styles'

export const VerificationAddressTooltip = ({
  searchError,
  isShow,
  onSubmit,
  onCancel,
  currentAddress,
  matchAddress,
  setWasOpenTooltip
}: IProps) => {
  // проверяем совпадает ли введенный адрес с найденным в dadata
  const isSameAddress = (currentAddress && replace(currentAddress, /[^a-zа-яё0-9]/gi, '')) ===
        replace(matchAddress, /[^a-zа-яё0-9]/gi, '');
  useLockBodyScroll(isShow)
  const getTooltipInformation = () => {
    switch (true) {
      case searchError:
        return (
          <S.Text>
            К сожалению, мы не смогли найти указанный адрес. Пожалуйста,
            проверьте реквизиты и попробуйте еще раз.
          </S.Text>);
      case isSameAddress:
        return (
          <>
            <S.Title>Подтверждаете, что адрес полностью совпадает с Вашим?</S.Title>
            <S.Text>{currentAddress}</S.Text>
          </>);
      default:
        return (
          <>
            <S.Text>
              Вы ввели:
              <S.TextAddress>
                {currentAddress}
              </S.TextAddress>
            </S.Text>
            <S.Text>Возможно, Вы имели ввиду:
              <S.TextAddress>
                {matchAddress}?
              </S.TextAddress>
            </S.Text>
          </>
        );
    }
  };
  return (
    <CommonTooltip
        data-test="verification-tooltip"
        isShow={isShow}
        onClose={onCancel}
    >
      <>
        {getTooltipInformation()}
        <S.ButtonsWrapper>
          <S.CancelButton
            onClick={() => {
              setWasOpenTooltip(true)
              onCancel()
            }}
            data-test="verification-tooltip-cancel-button"
            searchError={searchError}
          >
            {searchError ? 'Вернуться' : 'Нет'}
          </S.CancelButton>
          {!searchError &&
            <S.SendButton
            onClick={() => onSubmit()}
            data-test="verification-tooltip-logout-button"
            >
              Да
            </S.SendButton>}
        </S.ButtonsWrapper>
      </>
    </CommonTooltip>
  );
};
