import { formFields } from 'components/shared/formFields';
import InputStyles from 'components/shared/input/styles';
import { PostCodeHint } from 'components/shared/fieldHints/postCode';
import { IWizardForm } from 'interfaces';
import { FormField } from 'entities/formField';
import { types, granulars } from 'helpers/dadata';
import { AvailableMasks } from 'constants/masks/masks';

import { Terrorizm } from '../terrorizm';

const { Description } = InputStyles;

const { birthDetails, passport, address } = formFields;
const { house } = address;

const dayBirth = new FormField(birthDetails.dayBirth, {
  isRequired: true,
  mask: AvailableMasks.date
});

const placeBirth = new FormField(birthDetails.placeBirth, {
  isRequired: true,
  // withSuggestions: {
  //   type: types.address
  // }
});

const issuesDate = new FormField(passport.issuesDate, {
  mask: AvailableMasks.date,
  isRequired: true
});
const unitCode = new FormField(passport.unitCode, {
  isRequired: true,
  mask: AvailableMasks.unitCode
});
const issuesPlace = new FormField(passport.issuesPlace, {
  withSuggestions: {
    type: 'fms_unit',
    autocompleateFields: [unitCode.name]
  }
});
const AddressDescription = (
  <Description style={{ marginLeft: 0, fontSize: '0.8em' }}>
    Например: Москва, Краснопресненская&nbsp;наб., д.&nbsp;12, кв.&nbsp;1
  </Description>
);

const country: FormField = new FormField(address.country, {
  isRequired: false,
  disabled: true,
  hidden: true,
  onToggleFields: () => {
    const { isRequired } = country.modifiers;
    country.setModifier('isRequired', !isRequired);
  }
});

const postCode: FormField = new FormField(address.postCode, {
  isRequired: false,
  mask: '999999',
  hint: <PostCodeHint />,
  hidden: true,
  onToggleFields: () => {
    const { isRequired } = postCode.modifiers;
    postCode.setModifier('isRequired', !isRequired);
  }
});

const street: FormField = new FormField(address.street, {
  isRequired: false,
  withSuggestions: {
    type: types.address,
    parts: granulars.address.street,
    combineSame: true
  },
  hidden: true
});

const city: FormField = new FormField(address.city, {
  isRequired: false,
  withSuggestions: {
    type: types.address,
    parts: granulars.address.city,
    combineSame: true
  },
  hidden: true,
  onToggleFields: () => {
    const { isRequired } = city.modifiers;
    city.setModifier('isRequired', !isRequired);
  }
});

const district = new FormField(address.district, {
  isRequired: false,
  hidden: true
});

const region = new FormField(address.region, {
  isRequired: false,
  withSuggestions: {
    type: types.address,
    parts: granulars.address.region,
    combineSame: true
  },
  hidden: true
});

const regionalDistrict = new FormField(address.regionalDistrict, {
  isRequired: false,
  withSuggestions: {
    type: types.address,
    parts: granulars.address.area,
    combineSame: true
  },
  hidden: true
});

const houseModifiers = { isRequired: false, halfWidth: true, hidden: true };

const flat = new FormField(house.flat, {
  halfWidth: true,
  hidden: true,
  withSuggestions: {
    type: 'address',
    parts: 'flat'
  }
});

const building = new FormField(house.building, houseModifiers);

const block = new FormField(house.block, houseModifiers);

const number: FormField = new FormField(house.number, {
  isRequired: false,
  halfWidth: true,
  hidden: true
});

const addressString = new FormField(address.addressString, {
  isRequired: true,
  description: AddressDescription,
  withSuggestions: {
    type: 'address',
    autocompleateFields: [
      postCode.name,
      city.name,
      street.name,
      number.name,
      region.name,
      regionalDistrict.name,
      flat.name,
      building.name,
      block.name
    ]
  },
  onToggleFields: () => {
    const required = addressString.modifiers.isRequired;
    addressString.setModifier('isRequired', !required);
  }
});

export const passportDetailsField = {
  placeBirth,
  dayBirth,
  issuesPlace,
  issuesDate,
  unitCode,
  country,
  postCode,
  region,
  regionalDistrict,
  city,
  street,
  number,
  building,
  block,
  flat,
  addressString
};
export const passportDetails: IWizardForm = {
  title: 'Паспортные данные',
  fields: [
    dayBirth,
    placeBirth,
    issuesDate,
    issuesPlace,
    unitCode,
    addressString,
    country,
    postCode,
    region,
    regionalDistrict,
    city,
    district,
    street,
    number,
    building,
    block,
    flat
  ],
  BeforeNext: (props) => <Terrorizm {...props} />,
  url: 'registration/step-three'
};
