import { ITextFieldProps } from './interfaces';

import S from './styles';

export const TextField = ({
  value,
  placeholder,
  styles
}: ITextFieldProps) => (
  <S.TextField hasPlaceholder={!!placeholder} style={styles}>
    <span className="placeholder">{placeholder && placeholder}</span>
    <span className="value">{value && value}</span>
  </S.TextField>
);
