import styled from 'styled-components';

import { InputPayloadPosition } from 'assets/styles/mixins';
import { media } from 'assets/styles/theme';

import S from '../styles';

const InputPassword = styled.div`
  position: relative;
  width: 100%;

  & + & {
    margin-top: 20px;
  }

  ${media.mobile} {
    &.with-restore {
      margin-bottom: 40px;
    }
  }

  ${S.Input} ~span {
    position: absolute;
    right: 17px;
    top: 15px;
    cursor: pointer;
    font-size: 15px;
    letter-spacing: 0.06px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.link};
  }
`;

const Icon = styled.div`
  cursor: pointer;
`;

const Payload = styled.div`
  ${InputPayloadPosition}
`;

const Restore = styled.a`
  display: block;
  position: absolute;
  right: 50px;
  top: 17px;
  font-size: 15px;
  line-height: 19px;
  text-decoration: none;
  cursor: pointer;

  ${media.mobile} {
    width: 100%;
    top: auto;
    bottom: -10px;
    right: auto;
    font-size: 14px;
    text-decoration: underline;
  }
`;

export default {
  InputPassword,
  Payload,
  Icon,
  Restore
};
