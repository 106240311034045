import { Styles } from 'components/shared';
import { useStore } from 'components/hooks/useStore';
import { downloadRequisites } from 'helpers/downloadFile';

import styled from 'styled-components';

const Link = styled(Styles.LinkText)`
  font-size: 14px;
`;

const DisclaimerStyle = styled.div`
  display: inline-flex;
  align-items: baseline;
    ${Styles.LinkText} {
      font-size: 14px;
      cursor: pointer;
    }
`;

export const Disclaimer = () => {
  const {
    purchase: {
      data: {
        requisites,
        userDocs,
        productRequisites
      }
    }
  } = useStore()
  return (
    requisites
      ? (
        <DisclaimerStyle>
          <Styles.ExtraText>
            Или использовать{' '}
            <Styles.LinkText
                onClick={() => downloadRequisites(userDocs && userDocs.transit.downloadUri)}
            >
                &nbsp;Реквизиты для банковского перевода
            </Styles.LinkText>{' '}
          </Styles.ExtraText>
        </DisclaimerStyle>)
      : (
        <DisclaimerStyle>
          <Styles.ExtraText>
            Или использовать{' '}
            <Link
                href={productRequisites}
                target="_blank"
                rel="noopener noreferer"
            >
              реквизиты для банковского перевода
            </Link>
          </Styles.ExtraText>
        </DisclaimerStyle>)
  );
}
