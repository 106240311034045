import { SmsType } from 'types/smsTypes';
import { IAction } from 'interfaces';

export const smsAction = (
  type: string,
  extraUrl: SmsType,
  code?: string
): IAction<{ extraUrl: SmsType; code?: string }> => ({
  type,
  payload: { extraUrl, code }
});
