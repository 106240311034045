import styled from 'styled-components';

import { Styles } from 'components/shared';

const BeforeFields = styled(Styles.ExtraText)`
  text-align: center;
  ${Styles.LinkText} {
    font-size: 14px;
  }
  margin-bottom: 32px;
`;

export default { BeforeFields };
