import { useState, useEffect } from 'react';

import { getCookie } from 'helpers/cookie';

export function useObserveCookie(key: string, fn?: (value: string) => void) {
  const [value, setValue] = useState<string>('');
  /* todo useEffect без зависимостей */
  useEffect(() => {
    if (!value) {
      setValue(getCookie(key) || '');
    }
  });

  useEffect(() => {
    if (value && fn) {
      fn(value);
    }
  }, [value]);
}
