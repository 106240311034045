import { useState } from 'react';

import { IWizardForm, IBeforeNext } from 'interfaces';
import { ScrollContainer, Styles } from 'components/shared';

import { SmsBeforeNext } from '../SmsBeforeNext';
import { agreement } from './docs';
import { UserRegistration } from '../userRegistration';

import S from './styles';

export const DocsManagementComponent = () => (
  <S.DocsManagement>
    <Styles.ExtraText>
      Документ необходим, чтобы Вам не пришлось посещать офис продаж лично.
    </Styles.ExtraText>
    <S.Agreement>
      <ScrollContainer renderView={() => <S.WrapperContent />}>
        <Styles.ExtraText>
          <Styles.DescriptionText>
            1. ПРЕДМЕТ СОГЛАШЕНИЯ
          </Styles.DescriptionText>
          {agreement}
        </Styles.ExtraText>
      </ScrollContainer>
    </S.Agreement>
  </S.DocsManagement>
);

const SmsEdo = (props: IBeforeNext) => <SmsBeforeNext {...props} isAutoSend={false} type="edo" />;

const finalStep = ({ onSubmit, ...rest }: IBeforeNext) => {
  const [smsVerification, setSmsVerification] = useState(false);

  return !smsVerification
    ? <SmsEdo onSubmit={() => setSmsVerification(true)} {...rest} />
    : <UserRegistration onSubmit={onSubmit} {...rest} />;
};

export const docsManagementRegistration: IWizardForm = {
  title: 'Соглашение об электронном документообороте',
  BeforeFields: DocsManagementComponent,
  fields: [],
  url: 'mfms-gateway/send-code/edo',
  submitTitle: 'Я принимаю соглашение',
  BeforeNext: finalStep
};

export const docsManagement: IWizardForm = {
  title: 'Соглашение об электронном документообороте',
  BeforeFields: DocsManagementComponent,
  fields: [],
  url: 'mfms-gateway/send-code/edo',
  submitTitle: 'Я принимаю соглашение',
  BeforeNext: SmsEdo
};
