import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from '@redux-devtools/extension';

import rootReducer from './reducers';
import { rootSaga } from './actions/sagas';

const sagaMiddleware = createSagaMiddleware();
export const middleware = [sagaMiddleware];

const createStoreWithMiddleWares = composeWithDevTools(
  applyMiddleware(...middleware)
)(createStore);
export const store = createStoreWithMiddleWares(rootReducer, {});
sagaMiddleware.run(rootSaga);

export const action = (type: string) => store.dispatch({ type });
