import styled from 'styled-components';

import { media } from 'assets/styles/theme';

const TrackElement = styled.div`
  width: 10px;
  padding: 10px;
  height: 100%;
  position: absolute;
  right: 0;
`;
const Thumb = styled.div`
  width: 4px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.border};
  ${media.desktop} {
    height: 50px;
  }
`;
export default { TrackElement, Thumb };
