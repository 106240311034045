import { actionTypes } from 'store/actionTypes';
import { smsAction } from 'store/actions/actionCreators/smsAction';
import { useLockBodyScroll } from 'components/hooks/useLockBodyScroll';

import { useStore } from 'components/hooks/useStore';
import { SmsTooltip } from '../../tooltips/sms';
import { LoaderTooltip } from '../../tooltips/loader';
import { Props } from './interfaces';

export const SmsBeforeNext = ({
  isShow,
  onSubmit = () => undefined,
  onCancel,
  description,
  type
}: Props) => {
  const { purchase: { sms: { check: { request } } } } = useStore()
  useLockBodyScroll(isShow);
  const onClose = () => undefined;
  const { loading } = request;

  return (
    loading
      ? (
        <LoaderTooltip
          isShow={loading}
          description="Пожалуйста, подождите..."
        />)
      : (
        <>
          {isShow && (
          <SmsTooltip
            tooltipProps={{
              isShow,
              onClose
            }}
            smsProps={{
              onSubmit,
              description,
              smsSended: false,
              showCancelButton: false,
              submitTitle: 'Подписать',
              onClose: onCancel || onClose,
              checkCodeAction: (code: string) => smsAction(
                actionTypes.PURCHASE.SMS.CHECK.REQUEST,
                type,
                code
              ),
              sendCodeAction: () => {
                if (type === 'edo') {
                  return smsAction(
                    actionTypes.PURCHASE.SMS.SEND.REQUEST,
                    type
                  );
                }
                return smsAction(
                  actionTypes.PURCHASE.SMS.SEND.REQUEST,
                  type
                );
              }
            }}
          />
          )}
        </>
      )
  );
};
