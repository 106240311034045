import styled from 'styled-components';

import { Styles } from 'components/shared';
import { media } from 'assets/styles/theme';

const SmsTooltip = styled.div`
  position: absolute;
  ${Styles.H1} {
    text-align: center;
  }
  ${Styles.Panel} {
    padding-top: 58px;
    ${media.mobile} {
      padding: 30px;
      ${Styles.H1} {
        padding: 0;
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
  form {
    justify-content: center;
  }
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.textPrimary};
`

export default { SmsTooltip, Title };
