import { useState, useEffect } from 'react';

import map from 'lodash/map';

// eslint-disable-next-line import/no-cycle
import { Styles, RadioButton } from '..';

import { IRadioButtonContainerProps } from './interfaces';

import S from './styles';

export const RadioButtonContainer = ({
  options,
  selectedOption,
  description,
  onChange
}: IRadioButtonContainerProps) => {
  const [activeOption, setActiveOption] = useState(selectedOption);
  const handleChange = (newValue: string) => {
    setActiveOption(newValue);
    onChange(newValue);
  };
  useEffect(() => {
    setActiveOption(selectedOption || '');
  }, [selectedOption]);

  const isChecked = (optionValue: string) => activeOption === optionValue;
  const descr = description
    ? <Styles.DescriptionText>{description}</Styles.DescriptionText>
    : null;

  return (
    selectedOption
      ?
        <>
          {descr}
          <S.RadioButtonContainer data-test="radio-button-container-component">
            {map(options, (option) => {
              const { value, label } = option;
              return (
                <RadioButton
                    key={value.toString()}
                    label={label}
                    value={value}
                    onChange={() => handleChange(value)}
                    checked={isChecked(value.toString())}
                    id={Math.random().toString()}
                />
              );
            })}
          </S.RadioButtonContainer>
        </>
      : null
  );
};
