import { getProperty, setProperty } from 'dot-prop';

import { FormField } from 'entities/formField';
import { IPurchaseData } from 'interfaces';

import { IFormValues } from './intefaces';

export const getInitialValues = (
  fields: FormField[],
  stateData: IPurchaseData
): IFormValues => {
  const initialValues = fields.reduce(
    (accumulator: IFormValues, field: FormField) => {
      let initialValue: unknown;
      const { accumulateStateFields } = field.get();
      if (accumulateStateFields) {
        accumulateStateFields.forEach((fieldName: string, index) => {
          if (index === 0) {
            initialValue = getProperty(stateData, fieldName) || '';
          } else if (fieldName === 'latinName') {
            initialValue += `\n${getProperty(stateData, fieldName) || ''}`;
          } else {
            initialValue += ` ${getProperty(stateData, fieldName) || ''}`;
          }
        });
      } else {
        initialValue = getProperty(stateData, field.name) || field.initialValue;
      }
      if (initialValue) {
        setProperty(accumulator, field.name, initialValue);
      }
      return accumulator;
    },
    {}
  );
  return initialValues;
};
