import { FormikErrors, FormikTouched } from 'formik';
import { getProperty } from 'dot-prop';

import { IPassportErrors } from 'interfaces/purchase';

export const getFieldError = (
  name: string,
  formikErrors: FormikErrors<{ [fieldName: string]: string }>,
  touched: FormikTouched<{ [fieldName: string]: string }>,
  propsErrors?: IPassportErrors
): string => {
  let error;
  const formikError = getProperty(formikErrors, name);
  let propsError = propsErrors
    ? getProperty(propsErrors, name)
    : '';
  if (name === 'passport.series_number') {
    propsError = getSeriesNumberError(propsErrors);
  }
  const isTouched = getProperty(touched, name);
  if (formikError && isTouched) {
    error = formikError;
  }
  if (propsError) {
    error = propsError;
  }

  return (error as string) || '';
};

function getSeriesNumberError(errors?: IPassportErrors) {
  let error = '';
  if (errors && errors.passport) {
    const { series } = errors.passport;
    error = series;
  }
  return error;
}
