import { useLockBodyScroll } from 'components/hooks/useLockBodyScroll';
import {
  Loader,
  Styles,
  CommonTooltip
} from 'components/shared';

import S from './styles';

export const LoaderTooltip = ({ isShow, description }: {
  isShow: boolean;
  description: string | JSX.Element;
}) => {
  useLockBodyScroll(isShow)
  return (
    <CommonTooltip
      isShow={isShow}
      onClose={() => undefined}
      showCloseButton={false}
    >
      <S.LoaderWrapper>
        <Loader />
        <Styles.ExtraText>{description}</Styles.ExtraText>
      </S.LoaderWrapper>
    </CommonTooltip>
  )
}
