import styled from 'styled-components';

import { media } from 'assets/styles/theme';

import StylesItem from '../../../header/item/styles';

const OrderDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;

  ${StylesItem.Item} {
    border-left: 0;
    padding-right: 6px;
    white-space: nowrap;
    .title {
      margin-bottom: 10px;
    }
    .value {
      font-size: 16px;
      line-height: 20px;
    }
    .content {
      ${media.mobile} {
        flex-direction: column;
      }
    }
    :first-child {
      padding-left: 0;
    }

    ${media.mobile} {
      min-width: auto;
    }
  }
`;

export default { OrderDetails };
