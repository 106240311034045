import {
  call,
  takeLatest,
  put
} from 'redux-saga/effects';

import { actionTypes } from 'store/actionTypes';
import { api } from 'api';
import { getPurchaseHeaders } from 'api/options';
import { Url } from 'api/url';
import { IUserDocs } from 'interfaces/userDocs';

import { IReducedDocs } from './interfaces';

const { USER_DOCS } = actionTypes.PURCHASE;

function* fetchDocs() {
  const { SUCCESS, ERROR } = USER_DOCS.GET;
  try {
    const response: IUserDocs[] = yield call(
      api.get,
      { url: Url.purchase.userDocs.get },
      getPurchaseHeaders()
    );
    const docs = response.reduce((accumulator: IReducedDocs, doc) => {
      // eslint-disable-next-line default-case
      switch (doc.title) {
        case 'Единый файл': {
          accumulator.unified = doc;
          break;
        }
        case 'Заявление об открытии лицевого счета': {
          accumulator.openLc = doc;
          break;
        }
        case 'Заявлении о присоединении к Договору': {
          accumulator.connectDog = doc;
          break;
        }
        case 'Анкета зарегистрированного лица': {
          accumulator.profileRegistred = doc;
          break;
        }
        case 'Заявка на приобретение инвестиционных паев': {
          accumulator.applicationAcquisition = doc;
          break;
        }
        case 'Cогласие на обработку персональных данных': {
          accumulator.agreementPersonal = doc;
          break;
        }
        case 'Анкета физического лица': {
          accumulator.profilePhysical = doc;
          break;
        }
        case 'Реквизиты': {
          accumulator.transit = doc;
          break;
        }
      }
      return accumulator;
    }, {});
    yield put({ type: SUCCESS, payload: docs });
  } catch (error) {
    yield put({ type: ERROR, payload: error });
  }
}

export function* userDocs() {
  yield takeLatest(USER_DOCS.GET.REQUEST, fetchDocs);
}
