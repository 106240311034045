import styled from 'styled-components';

import { Styles } from 'components/shared';
import { FormWidth } from 'assets/styles/mixins';

const ServiceError = styled.div`
  position: absolute;
  ${Styles.ExtraText} {
    text-align: center;
    ${Styles.LinkText} {
      font-size: 14px;
    }
  }
`;
const Phone = styled.a`
  font-size: 14px;
  line-height: 20px;
  color: #424249;
  text-decoration: none;
`;

const BackButton = styled(Styles.ButtonLarge)`
  margin-top: 44px;
`;
const Content = styled.div`
  /* todo что это? */
  ${FormWidth}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div`
  display: flex;
  max-width: 370px;
  flex-direction: column;
`;
export default {
  ServiceError,
  Phone,
  BackButton,
  Content,
  Text
};
