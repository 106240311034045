import { getAsyncTypes } from '../helpers/asyncTypes';

export const actionTypes = {
  RECOMMENDED_PRODUCTS: {
    GET: {
      ...getAsyncTypes('GET_RECOMMENDED_PRODUCTS')
    }
  },
  YIELD_INFO: getAsyncTypes('YIELD_INFO'),
  CHECK_USER_EXIST: getAsyncTypes('CHECK_USER_EXIST'),
  PURCHASE: {
    USER_DOCS: {
      GET: getAsyncTypes('USER_DOCS_GET')
    },
    PROMO_CODE: getAsyncTypes('PROMO_CODE'),
    USER_REGISTRATION: { ...getAsyncTypes('USER_REGISTRATION') },
    CHECK_TERRORIZM: { ...getAsyncTypes('PURCHASE_CHECK_TERRORIZM') },
    NOT_RESPOND: 'SERVICE_IS_NOT_RESPOND',
    RESET_NOT_RESPOND: 'RESET_NOT_RESPOND',
    /* сбрасываем state в initial, кроме названия продукта (нужно для disabled кнопки) */
    RESET_STATE: 'RESET_STATE',
    REDIRECT_ERROR: 'REDIRECT_ERROR',
    SMS: {
      SEND: { ...getAsyncTypes('PURCHASE_SMS_SEND') },
      CHECK: { ...getAsyncTypes('PURCHASE_SMS_CHECK') }
    },
    SET_ADDRESS: 'PURCHASE_SET_ADDRESS',
    OPIF: {
      ...getAsyncTypes('PURCHASE_OPIF')
    },
    PASSPORT_CHECK: {
      ...getAsyncTypes('PASSPORT_CHECK'),
      SEND: { ...getAsyncTypes('PASSPORT_CHECK_SEND') }
    },
    SET_PARAMS: 'QUESTIONNAIRE_SET_PARAMS',
    EDO: {
      CHECK_STATUS: { ...getAsyncTypes('EDO_CHECK_STATUS') },
      CHECK_SMS: { ...getAsyncTypes('EDO_SMS') },
      SEND_SMS: { ...getAsyncTypes('EDO_SEND_SMS') }
    },
    CHECK_DATA: {
      SMS: { ...getAsyncTypes('CHECK_DATA_SMS') }
    }
  },
  ESIA: {
    GET_USER: { ...getAsyncTypes('ESIA_GET_USER') }
  },

  VIEW: {
    EDIT_MODAL: {
      SHOW: 'VIEW_EDIT_MODAL_SHOW',
      HIDE: 'VIEW_EDIT_MODAL_HIDE'
    }
  },
  REBUY: {
    GET_INFO: getAsyncTypes('REBUY_GET_INFO'),
    NOT_REBUY: 'SERVICE_IS_NOT_REBUY'
  }
};
