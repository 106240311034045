import { IField } from 'interfaces';
import { AvailableMasks } from 'constants/masks/masks';

const seriesAndNumber: IField = {
  name: 'passport.series_number',
  type: 'text',
  placeholder: 'Серия и номер паспорта',
  initialValue: ''
};

const issuesDate: IField = {
  name: 'passport.issuesDate',
  placeholder: 'Дата выдачи паспорта',
  type: 'date',
  initialValue: '',
  mask: AvailableMasks.date,
  isRequired: true
};

const issuesPlace: IField = {
  name: 'passport.issuesPlace',
  placeholder: 'Кем выдан',
  type: 'textarea',
  initialValue: '',
  isRequired: true,
  withSuggestions: {
    type: 'fms_unit'
  }
};

const unitCode: IField = {
  name: 'passport.unitCode',
  placeholder: 'Код подразделения',
  type: 'text',
  initialValue: ''
};

export default {
  seriesAndNumber,
  issuesDate,
  issuesPlace,
  unitCode
};
