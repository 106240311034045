import styled from 'styled-components';

import { media } from 'assets/styles/theme';

import { Styles } from '../commonStyles';

export const Wrapper = styled.div`
  height: 320px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 5px;
  p {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 20px;
    ${Styles.DescriptionText} {
      color: ${({ theme }) => theme.colors.textPrimary};
    }
  }
  ul {
    padding-left: 15px;
    li {
      padding-bottom: 5px;
    }
  }
  margin-top: 20px;
  margin-bottom: 20px;
  
  ${media.mobile} {
  height: 250px;
}
`;

export const WrapperContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: -12px;
  margin-bottom: -12px;
  padding: 26px;
  overflow: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
`;

export default { WrapperContent, Wrapper }
