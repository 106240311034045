import { FormField } from 'entities/formField';
import { Url } from 'api/url';
import { formatDate } from 'helpers/date';
import {
  IWizardForm,
  IPurchaseData,
  IFormFields
} from 'interfaces';

import { OrderDetails } from './orderDetails';

const orderDetails = () => <OrderDetails />;

const header = new FormField(
  {
    name: 'orderDetails',
    type: 'content',
    placeholder: '',
    initialValue: ''
  },
  {
    content: orderDetails
  }
);

const investSum = new FormField(
  {
    name: 'purchase.price',
    placeholder: 'Укажите сумму инвестирования',
    type: 'money',
    initialValue: '0'
  },
  { isRequired: true }
);

export const additionalPurchase: IWizardForm = {
  title: '',
  fields: [
    header,
    investSum
  ],
  url: Url.rebuy,
  submitTitle: 'Оплатить',
  formSubmit: (fields: IFormFields, formData: IPurchaseData) => {
    const {
      productId,
      productName,
      lastName,
      patronymic,
      applicantId,
      firstName,
      order: { number, date } = { number: '', date: '' }
    } = formData;
    const rebuyFields = {
      firstName,
      lastName,
      patronymic,
      applicantId,
      purchase: {
        productId,
        productName,
        price: fields.purchase.price
      },
      fullNumber: number,
      reBuyDate: formatDate(date, 'YYYY-MM-DD', 'DD.MM.YYYY')
    };
    return rebuyFields;
  }
};
