import { Icon } from 'assets/img/svg';
import { Styles, Burger } from 'components/shared';
import { staticContent } from 'staticContent';
import { Sidebar } from 'components/widgets';

import { ILoginHeaderProps } from './interfaces';
import S from './styles';

const {
  email,
  Logo,
  hotLinePhone,
  mainSiteLink
} = staticContent;

export const LoginHeader = ({ setMenuActive, isMenuActive }: ILoginHeaderProps) => (
  <S.Header isMenuActive={isMenuActive} data-test="header">
    <S.Container>
      <Sidebar isActive={isMenuActive} isMobileCompact />
      <Burger onClick={() => setMenuActive && setMenuActive(!isMenuActive)} />
      <a
          className="logo"
          href="https://lk.veles-management.ru"
          aria-label="Перейти в личный кабинет Велес менеджмент"
      >
        <Logo />
      </a>
      <S.Phone href={`phoneto:${hotLinePhone}`}>{hotLinePhone}</S.Phone>
      <Styles.LinkText
          className="email"
          href={`mailto:${email}`}
          data-test="header-email"
      >
        {email}
      </Styles.LinkText>
      <S.Back>
        <Icon.ArrowBack />
        <Styles.LinkText href={mainSiteLink} data-test="header-go-back">
          Вернуться на сайт
        </Styles.LinkText>
      </S.Back>
    </S.Container>
  </S.Header>
);
