import { FormField } from 'entities/formField';
import { Change, Handle } from 'types/inputEvent';
import { getFakeEvent } from 'helpers/field/getFakeEvent';
import { getSuggestions, IGetSuggestionsData } from 'helpers/dadata';
import InputStyles from 'components/shared/input/styles';
import { AvailableMasks } from 'constants/masks/masks';

import { bankCardTitle } from './bankCardTitle';

const { Description } = InputStyles;

const changeBankData = (change: Handle<Change>, value: IGetSuggestionsData) => {
  getSuggestions('bank', { value: value.toString() }).then((response) => {
    if (response[0]) {
      const bankPlaceString = response[0].payload && response[0].payload.requisites
        ? response[0].payload.requisites.bankPlace
        : null;

      const bankPlaceTypeString = response[0].payload && response[0].payload.requisites
        ? response[0].payload.requisites.bankPlaceType
        : null;

      if (change && bankPlaceString) {
        change(getFakeEvent(bankPlaceString, 'requisites.bankPlace') as Change);
      }

      if (change && bankPlaceTypeString) {
        change(getFakeEvent(bankPlaceTypeString, 'requisites.bankPlaceType') as Change);
      }
    }
  });
};

const correspondentAccount: FormField = new FormField(
  {
    name: 'requisites.ks',
    placeholder: 'Корреспондентский счёт',
    initialValue: '',
    type: 'text'
  },
  {
    disabled: false,
    isRequired: true,
    mask: AvailableMasks.correspondentAccount
  }
);

const checkingAccount: FormField = new FormField(
  {
    name: 'requisites.rs',
    placeholder: 'Расчётный счёт',
    initialValue: '',
    type: 'text'
  },
  {
    disabled: false,
    isRequired: true,
    mask: AvailableMasks.checkingAccount
  }
);

const bankBik: FormField = new FormField(
  {
    name: 'requisites.bik',
    placeholder: 'БИК банка',
    initialValue: '',
    type: 'text'
  },
  {
    mask: AvailableMasks.bankBik,
    disabled: false,
    isRequired: true,
    withSuggestions: {
      type: 'bank',
      parts: 'bic',
      autocompleateFields: [
        'requisites.bankName',
        'requisites.bankPlace',
        'requisites.bankPlaceType',
        correspondentAccount.name
      ]
    },
    onChangeValue: (value, change) => {
      if (change) {
        changeBankData(change, value);
      }
    }
  }
);

const bankPlace = new FormField(
  {
    name: 'requisites.bankPlace',
    type: 'text',
    placeholder: '',
    initialValue: 'Поле не может быть пустым.'
  },
  {
    invisible: true
  }
);

const bankPlaceType = new FormField(
  {
    name: 'requisites.bankPlaceType',
    type: 'text',
    placeholder: '',
    initialValue: 'Поле не может быть пустым.'
  },
  {
    invisible: true
  }
);

const nameDescription = (
  <Description style={{ marginLeft: 0, fontSize: '0.8em' }}>
    Для поиска введите наименование банка или БИК.
  </Description>
);

const bankName: FormField = new FormField(
  {
    name: 'requisites.bankName',
    placeholder: 'Наименование банка',
    initialValue: '',
    type: 'text'
  },
  {
    description: nameDescription,
    disabled: false,
    isRequired: true,
    groupTitle: bankCardTitle,
    withSuggestions: {
      type: 'bank',
      autocompleateFields: [
        bankBik.name,
        correspondentAccount.name,
        bankPlace.name,
        bankPlaceType.name
      ]
    },
    onChangeValue: (value, change) => {
      if (change) {
        changeBankData(change, value);
      }
    }
  }
);

export const bankFields = {
  checkingAccount,
  correspondentAccount,
  bankBik,
  bankName,
  bankPlace,
  bankPlaceType
};
