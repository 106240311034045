import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { getAppeal } from 'helpers/form/getAppeal';
import { Form } from 'components/widgets';
import { actionCreator } from 'helpers/actions/actionCreator';
import { actionTypes } from 'store/actionTypes';
import { CommonTooltip } from 'components/shared';
import { useStore } from 'components/hooks/useStore';
import { IBeforeNext, IPurchaseData } from 'interfaces';

import { LoaderTooltip } from '../../tooltips/loader';
import { ServiceError } from '../error/serviceError';
import { editCheckedData } from '../editCheckedData';
import { myOwnCron } from './myOwnCron/myOwnCron';

import S from './styles';

const minute = 1000 * 60;
const response = {
  complete: {
    withError: 'complete_with_error',
    withoutError: 'complete'
  },
  wait: 'wait_response',

  error: 'error',
  notResponding: 'not responding'
};

export const blankLoaderDescription =
  <S.WrapperDescription>
    Не закрывайте окно. Мы проверяем Ваши данные, это может занять некоторое время.
  </S.WrapperDescription>
const { REQUEST, SEND } = actionTypes.PURCHASE.PASSPORT_CHECK;
let timerId = NaN;

export const PassportCheck = (props: IBeforeNext) => {
  const {
    purchase: {
      passportCheck: {
        state: passportCheckStatus
      },
      data: {
        firstName = '',
        lastName = '',
        passportCheckId,
        patronymic,
        inn,
        passport,
        gender,
      }
    }
  } = useStore()
  const userName = `${firstName} ${lastName}`
  const dispatch = useDispatch()

  const sendData = (fields:IPurchaseData) => {
    dispatch(actionCreator(SEND.REQUEST, fields));
  }
  const getStatus = (passportCheckId?: string) => dispatch(actionCreator(REQUEST, passportCheckId))
  const [status, setStatus] = useState('');

  const timerConfig = {
    ticksCount: 21,
    period: minute / 3
  };

  const render = () => {
    switch (status) {
      // case 'success':
      case response.complete.withoutError:
        return null;
      case response.complete.withError:
        return (
          <CommonTooltip isShow onClose={() => undefined}>
            <S.EditForm>
              <S.Title>{getAppeal(gender, userName)}</S.Title>
              <Form {...editCheckedData} onSubmit={startCheckingStatus} />
            </S.EditForm>
          </CommonTooltip>
        );
      case response.error:
      case response.notResponding:
        return <ServiceError />;
      default:
        return (
          <LoaderTooltip isShow={isShow} description={blankLoaderDescription} />
        );
    }
  };
  const {
    isShow,
    onSubmit = () => undefined
  } = props;
  const [editIsShown, setEditIsShown] = useState(false);

  useEffect(() => {
    if (isShow) {
      const { series, number } = passport || { series: '', number: '' };
      sendData({
        firstName,
        patronymic,
        lastName,
        inn,
        passport: {
          series,
          number
        }
      });
    }
  }, [isShow]);

  useEffect(() => {
    if (passportCheckId) startCheckingStatus();
  }, [passportCheckId]);

  useEffect(() => {
    setStatus(passportCheckStatus);
    // eslint-disable-next-line default-case
    switch (passportCheckStatus) {
      // case 'success':
      case response.complete.withoutError: {
        stopTimer();
        return handleSuccess();
      }
      case response.complete.withError: {
        stopTimer();
        return !editIsShown ? handleError() : handleNotRespond();
      }
      case response.error: {
        stopTimer();
        return handleNotRespond();
      }
    }
  }, [passportCheckStatus]);

  const startCheckingStatus = () => {
    setStatus(response.wait);
    checkStatus();
    myOwnCron({
      onTick,
      onFinish,
      onStart: (id) => (timerId = id),
      ticksCount: timerConfig.ticksCount,
      period: timerConfig.period
    });
  };

  const checkStatus = () => {
    getStatus(passportCheckId || '');
  };

  const onTick = (id: number) => {
    timerId = id;
    checkStatus();
  };

  const onFinish = () => {
    if (passportCheckStatus !== response.complete.withoutError) {
      switch (passportCheckStatus) {
        case response.complete.withError:
          return handleError();
        default:
          handleNotRespond();
      }
    }
  };
  const stopTimer = () => {
    clearInterval(timerId);
  };

  const handleSuccess = () => {
    onSubmit();
  };

  const handleError = () => {
    setEditIsShown(true);
  };
  const handleNotRespond = () => {
    setStatus(response.notResponding);
  };

  return render();
};
