import { all } from 'redux-saga/effects';

import { getRecommendedProducts } from './recommendedProducts';
import { purchase } from './purchase/sendPurshaseData';
import { checkPassport } from './purchase/checkPassport/checkPassport';
import { userData } from './purchase/getUserData';
import { sms } from './purchase/sms/sms';
import { terrorizm } from './purchase/terrorizm/terrorizm';
import { userDocs } from './purchase/userDocs/userDocs';
import { userRegisterAction } from './purchase/userRegistration';
import { userExist } from './purchase/checkUserExist';
import { getEdoExistStatus } from './purchase/checkEdo/checkEdo';
import { getOpifYield } from './getOpifYield';

export function* rootSaga() {
  yield all([
    getOpifYield(),
    sms(),
    getRecommendedProducts(),
    purchase(),
    checkPassport(),
    userData(),
    terrorizm(),
    userDocs(),
    userRegisterAction(),
    userExist(),
    getEdoExistStatus()
  ]);
}
