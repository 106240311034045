import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const formatDate = (
  date: Date | string | undefined | null,
  format: string = 'YYYY-MM-DD',
  parseFormat: string = 'DD.MM.YYYY'
): string => (date ? dayjs(date, parseFormat).format(format) : '');
