import { IField } from 'interfaces';

const addressString: IField = {
  name: 'addressRegistration.addressString',
  placeholder: 'Адрес регистрации',
  type: 'text',
  initialValue: ''
};
const country: IField = {
  name: 'addressRegistration.country',
  placeholder: 'Страна',
  type: 'text',
  initialValue: 'Россия'
};

const postCode: IField = {
  name: 'addressRegistration.postcode',
  type: 'text',
  placeholder: 'Индекс',
  initialValue: ''
};

const region: IField = {
  name: 'addressRegistration.region',
  placeholder: 'Область',
  type: 'text',
  initialValue: ''
};

const regionalDistrict: IField = {
  name: 'addressRegistration.regionalDistrict',
  placeholder: 'Областной район',
  type: 'text',
  initialValue: ''
};

const city: IField = {
  name: 'addressRegistration.city',
  placeholder: 'Город, поселение, поселок, деревня',
  type: 'text',
  initialValue: ''
};

const district: IField = {
  name: 'addressRegistration.district',
  placeholder: 'Городской район',
  type: 'text',
  initialValue: ''
};

const street: IField = {
  name: 'addressRegistration.street',
  placeholder: 'Улица',
  type: 'text',
  initialValue: ''
};

const houseNumber: IField = {
  name: 'addressRegistration.house.number',
  placeholder: 'Дом',
  type: 'text',
  initialValue: ''
};

const houseBuilding: IField = {
  name: 'addressRegistration.house.building',
  placeholder: 'Строение',
  type: 'text',
  initialValue: ''
};

const houseBlock: IField = {
  name: 'addressRegistration.house.block',
  placeholder: 'Корпус',
  type: 'text',
  initialValue: ''
};

const houseFlat: IField = {
  name: 'addressRegistration.house.flat',
  placeholder: 'Квартира',
  type: 'text',
  initialValue: ''
};

const address = {
  addressString,
  city,
  country,
  region,
  regionalDistrict,
  street,
  postCode,
  district,
  house: {
    number: houseNumber,
    building: houseBuilding,
    flat: houseFlat,
    block: houseBlock
  }
};

export default address;
