import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import size from 'lodash/size';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import find from 'lodash/find';

import { getRecommendedProducts } from 'store/actions/actionCreators';
import { getOpifYield } from 'store/actions/actionCreators/getOpifrYield';
import { duProductsIdNumber } from 'staticContent/duProductsIdAndName';
import { opifProductsIdNumber } from 'staticContent/opifProductsIdAndName';

import { ProductPanel } from '../productPanel';
import { IRecommendedProductProps } from './interfaces';
import { LoaderTooltip } from '../tooltips/loader';
import Styles from '../../pages/products/styles';
import { Notes } from '../notes';
import { useStore } from '../../hooks/useStore';

import S from './styles';

export const RecommendedProducts = ({
  type,
  className,
  exclude,
  title,
}: IRecommendedProductProps) => {
  const dispatch = useDispatch()
  const {
    recommendedProducts: { ids, byId },
    opifYield: { yields, isLoading }
  } = useStore()

  useEffect(() => {
    if (!size(ids)) dispatch(getRecommendedProducts());
    dispatch(getOpifYield());
  }, []);

  const productPanels: JSX.Element[] = [];
  forEach(ids, (id) => {
    const { name } = byId[id];
    const isDUProduct = includes(duProductsIdNumber, id) && type === 'du' && String(id) !== exclude;
    const isOPIFProduct = includes(opifProductsIdNumber, id) && type === 'opif' && String(id) !== exclude;
    /* todo почему работает проверка? */
    if (isDUProduct || isOPIFProduct) {
      const yieldProduct = find(yields, (itemYield) => itemYield.productId === `${id}`);
      productPanels.push(
        <ProductPanel
          key={id}
          yieldProduct={yieldProduct}
          id={id}
          title={name}
        />
      );
    }
  });

  if (!size(productPanels) && !size(ids) || isLoading) {
    return <LoaderTooltip isShow description="Пожалуйста, подождите..." />;
  }
  return size(productPanels)
    ? (
      <>
        {' '}
        {title || null}
        <S.Wrapper className={className}>{productPanels}</S.Wrapper>
        {type === 'opif' &&
        <Styles.TextWrapper>
          <Notes />
        </Styles.TextWrapper>}
      </>
    )
    : null;
};
