import { Icon } from 'assets/img/svg';

export const sites = {
  uk: 'uk',
  ik: 'ik'
};

const currentSite = sites.uk;

export const Logo = {
  uk: Icon.VelesLogo,
  lk: Icon.VelesLogo
};
const hotLinePhone = { uk: '+7 (495) 967-09-13', ik: '' };
const email = { uk: 'online@veles-management.ru', ik: '' };
const mainSiteLink = { uk: 'https://www.veles-management.ru/', ik: '' };
const lkLink = {
  uk: 'https://lk.veles-management.ru/'
};

export const staticContent = {
  Logo: Logo[currentSite],
  hotLinePhone: hotLinePhone[currentSite],
  email: email[currentSite],
  mainSiteLink: mainSiteLink[currentSite],
  lk: lkLink[currentSite]
};
