import styled from 'styled-components';

import { Styles } from 'components/shared';
import { NoScrollThumbs } from 'assets/styles/mixins';
import { media } from 'assets/styles/theme';

const RecommendedProducts = styled.div`

  justify-content: flex-start;
  align-items: stretch;
  overflow: scroll;
  ${NoScrollThumbs}
  ${media.tablet} {
    display: block;
    width: 100%;
    padding: 0 30px;
  }

  ${Styles.Panel} {
    margin-bottom: 30px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
      ${media.tablet} {
        margin-right: 0;
      }
    }
  }
`;

const Wrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  justify-content: center;

  &.du {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
  }
  &.opif {
    display: flex;
    flex-wrap: wrap;
  }
`;
export default { RecommendedProducts, Wrapper };
