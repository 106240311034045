import { useState } from 'react';

import { Row, Col } from 'styled-bootstrap-grid';

import { LoginHeader, Footer } from 'components/widgets';

import { IErrorGuest } from './interfaces';

import S from './styles';

/* todo разобраться с вертской, при открытии/закрытии sidebar прыгает блок с ошибкой */
export const ErrorGuest = ({ children }: IErrorGuest) => {
  const [isMenuActive, setMenuActive] = useState(false);
  return (
    <S.LoginPage data-test="login-page">
      <LoginHeader setMenuActive={setMenuActive} isMenuActive={isMenuActive} />
      <S.ErrorPanel>{children}</S.ErrorPanel>
      <Col>
        <Row>
          <Footer />
        </Row>
      </Col>
    </S.LoginPage>
  )
};
