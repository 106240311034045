import axios from 'axios';
import { saveAs } from 'file-saver';

import { getPurchaseHeaders } from 'api/options';
import { IUserDocs } from 'interfaces/userDocs';

import { translate } from './string';
import { host } from '../api/url';

export const downloadFile = (doc?: IUserDocs) => {
  if (doc) {
    const headers = getPurchaseHeaders();
    const responseType = 'blob';

    const downloadUri = `/api/api-sales/download-template?downloadUri=${doc.downloadUri}`;
    const titleDoc = translate(doc.title).trim();

    axios
      .get(`${window.location.origin}${downloadUri}`, {
        headers,
        responseType
      })
      .then((response) => {
        saveAs(response.data, `${titleDoc}.pdf`);
      });
  }
};

export const downloadRequisites = (doc?: string) => {
  if (doc) {
    const headers = getPurchaseHeaders();
    const responseType = 'blob';
    const downloadUri = `api-sales/download-template?downloadUri=${doc}`;

    axios
      .get(`${host}${downloadUri}`, {
        headers,
        responseType
      })
      .then((response) => {
        saveAs(response.data, 'rekvizity.pdf');
      });
  }
};
