import { useState } from 'react';

import size from 'lodash/size';

import { IInputProps } from 'interfaces';
import { Change } from 'types/inputEvent';
import { getFakeEvent } from 'helpers/field/getFakeEvent';

import { TextInput } from '../text';

import S from './styles';

export const MoneyInput = (props: IInputProps) => {
  const [changed, setChanged] = useState(false);
  const {
    name = '',
    value,
    onChange
  } = props;
  const inputProps: IInputProps = {
    ...props,
    type: 'text',
    onChange: handleChange
  };
  function handleChange(event: Change) {
    setChanged(true);

    const valueWithoutMask = event.target.value.replace(/\s/g, '');

    if (size(valueWithoutMask) > 10) {
      return;
    }

    if (isNumber(valueWithoutMask)) {
      if (onChange) {
        onChange(getFakeEvent(
          valueWithoutMask || null,
          name
        ) as Change);
      }
    }
  }

  const currentValue = changed && value ? value : '';

  return (
    <S.WrapperMoneyInput>
      <TextInput {...inputProps} value={currentValue} />
      <S.MoneySpan changed={changed}>{value && `${currentValue} ₽`}</S.MoneySpan>
    </S.WrapperMoneyInput>
  );
};

function isNumber(value: string): boolean {
  return !Number.isNaN(value);
}
