import { Link } from 'react-router-dom';

import { IProductPanelProps } from './interfaces';
import {
  getData,
  getProductLink,
  getProductTitle
} from './data';

import S from './styles';

export const ProductPanel = ({
  id,
  yieldProduct
}: IProductPanelProps) => {
  const {
    subTitle,
    rand,
    rand_prefix,
    rand_percent,
    rand3_percent,
    rand6_percent,
    rand12_percent,
    type
  } = getData(id);
  const getProductStat = (typeProduct: string): JSX.Element => {
    switch (typeProduct) {
      case 'zpif':
        return <S.SubTitle />;
      case 'opif':
        return (
          <>
            <S.Profit>Доходность</S.Profit>
            <S.ProfitPercent upOrDown={rand_percent && rand_percent > 0 ? 'up' : 'down'}>
              {yieldProduct && yieldProduct.Profitability || rand_percent}%
            </S.ProfitPercent>
            <S.ProfitDynamicWrapper>
              <S.PeriodInfoWrapper>
                <S.PeriodItem>за 3 мес.</S.PeriodItem>
                <S.PercentItem>
                  {yieldProduct && yieldProduct.ProfitabilityThreeMonths
                    || rand3_percent}%
                </S.PercentItem>
              </S.PeriodInfoWrapper>

              <S.PeriodInfoWrapper>
                <S.PeriodItem>за 6 мес.</S.PeriodItem>
                <S.PercentItem>
                  {yieldProduct && yieldProduct.ProfitabilitySixMonths
                    || rand6_percent}%
                </S.PercentItem>
              </S.PeriodInfoWrapper>

              <S.PeriodInfoWrapper>
                <S.PeriodItem>за 1год</S.PeriodItem>
                <S.PercentItem>
                  {yieldProduct && yieldProduct.ProfitabilityTwelveMonths
                    || rand12_percent}%
                </S.PercentItem>
              </S.PeriodInfoWrapper>
            </S.ProfitDynamicWrapper>
          </>
        );
      default:
        return (
          <>
            <S.Profit isRisk>Уровень риска *</S.Profit>
            <S.RiskIndicator className={`profit-progress-${rand}`}>
              <S.WrapperRisk>
                <S.RiskItem>&nbsp;</S.RiskItem>
                <S.RiskItem>&nbsp;</S.RiskItem>
                <S.RiskItem>&nbsp;</S.RiskItem>
                <S.RiskItem>&nbsp;</S.RiskItem>
                <S.RiskItem>&nbsp;</S.RiskItem>
                <S.RiskItem>&nbsp;</S.RiskItem>
              </S.WrapperRisk>
            </S.RiskIndicator>
            {id !== 34 &&
              <>
                <S.Profit>Ожидаемая доходность **</S.Profit>
                <S.ProfitPercent
                    upOrDown={rand_percent && rand_percent > 0 ? 'up' : 'down'}
                >
                  {`${rand_prefix} ${rand_percent}`}%
                </S.ProfitPercent>
              </>}
          </>
        );
    }
  };

  return (
    <S.ProductContainer>
      <S.ProductWrapper className={`thumbnail${id}`}>
        <S.SubTitle>{subTitle}</S.SubTitle>
        <S.Title>{getProductTitle(`${id}`)}</S.Title>
        <S.InfoWrapper>{getProductStat(type)}</S.InfoWrapper>
        <Link to={`purchase/invest/${id}/1/1`}>
          <S.BuyButton>
            {id === 17 ? 'Погашение' : 'Купить'}
          </S.BuyButton>
        </Link>
        <S.LinkMore href={getProductLink(id)} className="more">Подробнее</S.LinkMore>
      </S.ProductWrapper>

    </S.ProductContainer>
  );
};
